import { Accordion, AccordionSummary, Typography, AccordionDetails, Box, makeStyles, Divider } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as React from "react";

import { PrPieChart } from "../../../scripts/components/renewal_v1/piechart/piechart";
import { CashflowResultWithLatestResult } from "../../../view_models/balancesheet_selectors";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.primary.light,
    display: "flex",
  },
  // アコーディオン
  expanded: {
    position: "unset",
    boxShadow: "none",
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionSummary: {
    flexDirection: "column", // 垂直方向に並べる
    width: "100%",
  },
  accordionSummaryExpandIcon: {
    width: 24,
    height: 24,
    paddingBottom: 16,
  },
  accordionSummaryContent: {
    width: "100%",
    "&.Mui-expanded": {
      margin: "12px 0", // アコーディオンが開いた時のマージンを設定
    },
  },
  expandIcon: {
    alignSelf: "flex-end", // アイコンをボトムに配置
  },
  // 収支
  summaryRoot: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 48,
  },
  summaryItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    width: "100%",
    justifyContent: "space-between",
  },
  income: {
    color: theme.palette.info.main,
  },
  divider: {
    height: "100%",
    backgroundColor: theme.palette.divider,
    margin: "0 16px",
  },
  // Accordion Details
  detail: {
    width: "100%",
  },
  detailTextBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: 48,
  },
}));

interface MyPageAccordionProps {
  thisMonthResult: CashflowResultWithLatestResult;
}

export const MyPageAccordion: React.FC<MyPageAccordionProps> = ({ thisMonthResult }) => {
  const classes = useStyles();



  const formatIncome = (income: number) =>
    `${Math.round(income) > 0 ? "+ " : ""}${Math.round(income).toLocaleString()}円`;
  const formatCost = (cost: number) => `${Math.round(cost) > 0 ? "- " : ""}${Math.round(cost).toLocaleString()}円`;

  const cashflowMap = new Map<string, string>([
    ["家賃収入", formatIncome(thisMonthResult.rent_income)],
    ["管理費収入", formatIncome(thisMonthResult.management_fee_income)],
    [
      "ローン返済（利息含む）",
      formatCost(thisMonthResult.loan_principal + thisMonthResult.loan_interest + thisMonthResult.loan_prepayment),
    ],
    ["建物管理費", formatCost(thisMonthResult.building_management_cost)],
    ["修繕積立金", formatCost(thisMonthResult.repair_fund_cost)],
    ["賃貸管理費", formatCost(thisMonthResult.management_cost)],
    ["その他経費", formatCost(thisMonthResult.misc_cost)],
  ]);

  // 収入
  const income = Math.round(
    thisMonthResult.rent_income +
      thisMonthResult.management_fee_income
  );

  // 支出
  const expense = Math.round(
    thisMonthResult.loan_principal +
    thisMonthResult.loan_interest +
    thisMonthResult.loan_prepayment +
    thisMonthResult.building_management_cost +
    thisMonthResult.repair_fund_cost +
    thisMonthResult.management_cost +
    thisMonthResult.misc_cost
  );

  // 収支
  const totalCashFlow = Math.round(
    income - expense
  );
  const totalCashFlowStr =
    totalCashFlow >= 0 ? `+${totalCashFlow.toLocaleString()}` : `${totalCashFlow.toLocaleString()}`;

  return (
    <Box pt={4} bgcolor="white">
      <Box width="100%">
        <PrPieChart
          totalBalance={totalCashFlowStr}
          rentIncome={thisMonthResult.rent_income}
          managementFeeIncome={thisMonthResult.management_fee_income}
          buildingManagementFee={thisMonthResult.building_management_cost}
          repairFundCost={thisMonthResult.repair_fund_cost}
          managementCost={thisMonthResult.management_cost}
          miscCost={thisMonthResult.misc_cost}
          loanTotal={thisMonthResult.loan_principal + thisMonthResult.loan_interest + thisMonthResult.loan_prepayment}
        />
      </Box>
      <Accordion classes={{ root: classes.expanded }} square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          className={classes.accordionSummary}
          classes={{ expandIcon: classes.accordionSummaryExpandIcon, content: classes.accordionSummaryContent }}
        >
          <Box className={classes.summaryRoot}>
            <Box className={classes.summaryItem}>
              <Typography variant="h5" color="textPrimary">
                収入
              </Typography>
              <Typography variant="body1" className={classes.income}>
                {income.toLocaleString()}円
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem className={classes.divider} />
            <Box className={classes.summaryItem}>
              <Typography variant="h5" color="textPrimary">
                支出
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {expense.toLocaleString()}円
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.detail}>
            {cashflowMap.size &&
              Array.from(cashflowMap).map(([key, value]) => {
                return (
                  <Box key={key} className={classes.detailTextBox}>
                    <Typography variant="h4" color="textSecondary">
                      {key}
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {value}
                    </Typography>
                  </Box>
                );
              })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
