import { atom } from "recoil";


export const investmentUnreadCount = atom<{unread_count: number}>({
  key: "investmentUnreadCount",
  default: null,
});

export const assessmentUnreadCount = atom<{unread_count: number}>({
  key: "assessmentUnreadCount",
  default: null,
});
