import { Box, Grid } from "@material-ui/core";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useRecoilValue } from "recoil";

import { CustomButton } from "../../../scripts/components/renewal_v1/button";
import { CustomSelector } from "../../../scripts/components/renewal_v1/selecter";
import { RegistrationTemplate } from "../../../scripts/components/renewal_v1/template/registration_template";
import { UserRegistrationsAccoutFormState } from "../../../view_models/atoms";

interface RegisterBirthYearMonthPageProps {
  totalSteps: number;
  currentStep: number;
  onNext: () => void;
  validate: () => Promise<boolean>;
}

const RegisterBirthYearMonthPage: React.FC<RegisterBirthYearMonthPageProps> = ({
  totalSteps,
  currentStep,
  onNext,
  validate,
}) => {
  const {
    control,
    formState: { errors },
    setError,
  } = useFormContext();
  const formData = useRecoilValue(UserRegistrationsAccoutFormState);

  // formDataが存在する場合に初期値を設定
  React.useEffect(() => {
    control.setValue("birth_year", formData.birth_year);
    control.setValue("birth_month", formData.birth_month);
  }, []);

  const handleNext = async () => {
    const isValid = await validate();
    if (isValid) {
      const birthYear = control.getValues("birth_year");
      const birthMonth = control.getValues("birth_month");
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      if (birthYear > currentYear || (birthYear == currentYear && birthMonth > currentMonth)) {
        setError("birth_year", { type: "manual", message: "過去の年を入力してください。" });
        setError("birth_month", { type: "manual", message: "過去の月を入力してください。" });
      } else {
        onNext();
      }
    } else {
      console.log("Not valid");
    }
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(50), (val, index) => currentYear - index).reverse(); // Array of years from current year to 50 years ago
  const months = Array.from(new Array(12), (val, index) => index + 1); // Array of months from 1 to 12
  const yearOptions = years.map((year) => ({ value: year, label: year.toString() }));
  const monthOptions = months.map((month) => ({ value: month, label: month.toString() }));

  return (
    <RegistrationTemplate title="あなたの生年月を教えてください" totalSteps={totalSteps} currentStep={currentStep}>
      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="birth_year"
              control={control}
              defaultValue={formData.birth_year || ""}
              rules={{
                required: "年を入力してください。",
                validate: (value) => new Date().getFullYear() >= value || "過去の年を入力してください。",
              }}
              render={({ ...field }) => (
                <CustomSelector
                  {...field}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.birth_year}
                  errorText={errors.birth_year?.message}
                  options={yearOptions}
                  placeHolder="1996"
                  isPlaceHolderGray
                  suffix="年"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="birth_month"
              control={control}
              defaultValue={formData.birth_month || ""}
              rules={{
                required: "月を入力してください。",
              }}
              render={({ ...field }) => (
                <CustomSelector
                  {...field}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.birth_month}
                  errorText={errors.birth_month?.message}
                  options={monthOptions}
                  placeHolder="5"
                  isPlaceHolderGray
                  suffix="月"
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <CustomButton type="button" customVariant="filled" onClick={handleNext}>
          次へ
        </CustomButton>
      </Box>
    </RegistrationTemplate>
  );
};

export default RegisterBirthYearMonthPage;
