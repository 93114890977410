import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useRecoilState, useRecoilStateLoadable, useSetRecoilState } from "recoil";

import { RegisteredStatusEnum, UserUpdate, UserUpdateWayToProposeEnum } from "../../../api/generated";
import { PropallyApiFactory } from "../../../module/custom_api_factory";
import { Toast } from "../../../scripts/components/renewal_v1/toast";
import { sendEventToAppMeasurementTool, AppEventTokenEnum } from "../../../utilities/capacitor_logic";
import {
  UserDataFirstRegisteredStatusState,
  UserRegistrationsAccoutFormState,
  UserState,
} from "../../../view_models/atoms";

import RegisterBirthYearMonthPage from "./birth";
import { DEFAULT_ACCOUNT_REGISTER_FORM_SCHEMA } from "./const";
import RegisterFinancialAssetsPage from "./financial_assets";
import RegisterInterestPage from "./interest";
import RegisterJobPage from "./job";
import RegisterReasonPage from "./reason";
import RegisterResidencePage from "./residence";
import { AccountRegisterFormSchema } from "./types";
import WelcomePage from "./welcome";

const AccountRegisterBasicPage: React.FC = () => {
  const isSubmittingRef = React.useRef(false);
  const methods = useForm<AccountRegisterFormSchema>({
    defaultValues: DEFAULT_ACCOUNT_REGISTER_FORM_SCHEMA,
  });
  const [currentStep, setCurrentStep] = React.useState(0);
  const [loadable, setUser] = useRecoilStateLoadable(UserState);
  const setUserDataFirstRegisteredStatus = useSetRecoilState(UserDataFirstRegisteredStatusState);
  const [error, setError] = React.useState<string>("");
  const [accountFormData, setAccountFormData] = useRecoilState(UserRegistrationsAccoutFormState);
  const history = useHistory();

  const user = loadable.contents;

  const validate = async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      setAccountFormData({
        ...accountFormData,
        ...methods.getValues(),
      });
    }
    return isValid;
  };
  const steps = React.useMemo(
    () => [
      <WelcomePage key="step0" onNext={() => setCurrentStep((prev) => prev + 1)} />,
      <RegisterBirthYearMonthPage
        key="step1"
        totalSteps={6}
        currentStep={1}
        onNext={() => setCurrentStep((prev) => prev + 1)}
        validate={validate}
      />,
      <RegisterResidencePage
        key="step2"
        totalSteps={6}
        currentStep={2}
        onNext={() => setCurrentStep((prev) => prev + 1)}
        onBack={() => setCurrentStep((prev) => prev - 1)}
        validate={validate}
      />,
      <RegisterJobPage
        key="step3"
        totalSteps={6}
        currentStep={3}
        onNext={() => setCurrentStep((prev) => prev + 1)}
        onBack={() => setCurrentStep((prev) => prev - 1)}
        validate={validate}
      />,
      <RegisterFinancialAssetsPage
        key="step4"
        totalSteps={6}
        currentStep={4}
        onNext={() => setCurrentStep((prev) => prev + 1)}
        onBack={() => setCurrentStep((prev) => prev - 1)}
        validate={validate}
      />,
      <RegisterInterestPage
        key="step5"
        totalSteps={6}
        currentStep={5}
        onNext={() => setCurrentStep((prev) => prev + 1)}
        onBack={() => setCurrentStep((prev) => prev - 1)}
        validate={validate}
      />,
      // TODO: サーバーエラーを渡して表示
      <RegisterReasonPage
        key="step6"
        totalSteps={6}
        currentStep={6}
        onBack={() => setCurrentStep((prev) => prev - 1)}
        validate={validate}
      />,
    ],
    [validate]
  );

  // トーストのクローズ
  const handleClose = () => {
    setError("");
  };

  const onSubmit = React.useCallback(async () => {
    // 二重送信防止
    if (isSubmittingRef.current) return;
    isSubmittingRef.current = true;
    const validate_result = await validate();
    if (!validate_result) return;
    console.log("onSubmit data", accountFormData);
    // if (typeof formData.real_estate_loan_amount === "string") {
    //   formData.real_estate_loan_amount = parseInt(formData.real_estate_loan_amount.replace(/,/g, ""));
    // }
    const sendValues: UserUpdate = {
      ...accountFormData,
      birthday: `${accountFormData.birth_year}-${accountFormData.birth_month}-01`,
      current_residence_type_id: parseInt(accountFormData.current_residence_type_id),
      home_loan_amount: parseInt(accountFormData.home_loan_amount),
      real_estate_loan_amount: parseInt(accountFormData.real_estate_loan_amount.replace(/,/g, "")),
      monthly_rent_payment_amount: parseInt(accountFormData.monthly_rent_payment_amount.replace(/,/g, "")),
      // MEMO: 以下「way_to_propose」「request_the_proposal」は現状、非表示項目となるので、固定値を送信する
      way_to_propose: UserUpdateWayToProposeEnum.NUMBER_0,
      request_the_proposal: true,
    };
    console.log("sendValues", sendValues);
    const fp = PropallyApiFactory();
    try {
      const userPatch = await fp.v1UserPatch(sendValues, {
        withCredentials: true,
      });
      if (userPatch.status === 200) {
        console.log("user update success");
        setUser({ ...user, ...sendValues });
        // 初回登録済みステータスを更新
        await fp
          .v1UserDataFirstRegisteredStatusPatch(
            {
              status: RegisteredStatusEnum.Account,
            },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            setUserDataFirstRegisteredStatus(res.data.status);
          });

        // エージェントに新着通知
        await fp.v1NotificationsToAgentForNewUserPost({ withCredentials: true });

        // ユーザアカウント登録完了イベント発火
        sendEventToAppMeasurementTool(AppEventTokenEnum.UserAccountCompleted);

        history.push("/mypage");
      }
    } catch (e) {
      console.log(e);
      if (e.response?.status === 401) {
        setError("更新に失敗しました。画面をリロードし、再度ログインしてください");
        return;
      } else if (e.response?.data.code === "INVALID_PHONE_NUMBER_AUTH_CODE") {
        setError("電話番号認証コードが不正です");
        return;
      }
      setError("更新に失敗しました。画面をリロードし、再度ログインしてください");
    }
    isSubmittingRef.current = false; // 送信完了後、二重送信管理フラグをリセット

  }, [accountFormData]);

  // WithAuthの配下のため、認証のチェックはしない
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>{steps[currentStep]}</form>
      {/* エラーメッセージ表示用 */}
      <Toast open={Boolean(error)} variant="error" message={error} onClose={handleClose} />
    </FormProvider>
  );
};

export default AccountRegisterBasicPage;
