import { Box, Chip, makeStyles, Theme, ThemeProvider } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { useDevice } from "../../../../utilities/sp/use_device";
import { investmentUnreadCount, assessmentUnreadCount } from "../../../../view_models/proposal_unread_counts";
import { ManagementAppBar } from "../property/app_bar";
import theme from "../theme";

import type { FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const useStyles = makeStyles<Theme>((theme) => ({
  chipWrapper: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(8, 0, 2),
  },
  chip: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0, 3),
    "&:hover, &:active": {
      backgroundColor: theme.palette.info.dark,
    },
    "&:focus": {
      backgroundColor: theme.palette.info.main,
    },
  },
  chipIcon: {
    color: theme.palette.common.white,
  },
}));

/**
 * TOPに位置する画面に共通するテンプレートコンポーネント
 */
export const MainTemplate: FC<Props> = ({ children }) => {
  const { isMobile } = useDevice();

  const classes = useStyles();
  const history = useHistory();

  const investmentUnreadCountValue = useRecoilValue(investmentUnreadCount);
  const assessmentUnreadCountValue = useRecoilValue(assessmentUnreadCount);

  const infoChipVisible = React.useMemo(() => {
    return investmentUnreadCountValue?.unread_count > 0 || assessmentUnreadCountValue?.unread_count > 0;
  }, [investmentUnreadCountValue, assessmentUnreadCountValue]);

  return (
    <ThemeProvider theme={theme}>
      {!isMobile && <ManagementAppBar />}

      {infoChipVisible && (
        <Box className={classes.chipWrapper}>
          <Chip
            className={classes.chip}
            icon={<MailIcon className={classes.chipIcon} />}
            label="未読の新規オファーがあります"
            onClick={() => history.push("/offer")}
          />
        </Box>
      )}

      {children}
    </ThemeProvider>
  );
};
