import * as Sentry from "@sentry/browser";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React = require("react");
import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { RegisteredStatusEnum, User } from "../api/generated";
import { getMonthlyCashflowResults } from "../api/get_monthly_cashflow_results";
import { PROPERTY_TYPE_VALUES } from "../api/mappings/property";
import { PropallyApiFactory } from "../module/custom_api_factory";
import { Routes } from "../routes";
import { NotifyNetworkError } from "../utilities/notify_network_error";
import {
  FetchedPropertyHolders,
  PropertyIds,
  RequireLoadingFromBackendState,
  SelectedPropertyId,
  SelectedPropertyIds,
  UserDataFirstRegisteredStatusState,
  UserState,
} from "../view_models/atoms";
import { assessmentUnreadCount, investmentUnreadCount } from "../view_models/proposal_unread_counts";

export const PropallyRecoilRoot: React.FC<React.PropsWithChildren<any>> = (props) => {
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const setUser = useSetRecoilState(UserState);
  const [property_ids, setPropertyIds] = useRecoilState(PropertyIds);
  const setSelectedPropertyId = useSetRecoilState(SelectedPropertyId);
  const setSelectedPropertyIds = useSetRecoilState(SelectedPropertyIds);
  const setFetchedPropertyHolders = useSetRecoilState(FetchedPropertyHolders);
  const setInvestmentUnreadCount = useSetRecoilState(investmentUnreadCount);
  const setAssessmentUnreadCount = useSetRecoilState(assessmentUnreadCount);

  const require_loading = useRecoilValue(RequireLoadingFromBackendState);
  const user = useRecoilValue(UserState);
  const name = user ? `${user.family_name} ${user.first_name}` : "名前未設定";

  const [userDataFirstRegisteredStatus, setUserDataFirstRegisteredStatus] = useRecoilState(
    UserDataFirstRegisteredStatusState
  );
  const isRegisteredAccount = userDataFirstRegisteredStatus === RegisteredStatusEnum.Account;

  // 遷移先画面と現在のユーザ情報入力ステータスから初期STEP入力画面を表示するかどうか判定する
  const isDisplayFirstStep = () => {
    switch (Routes.find((route) => route.url_path === location.pathname)?.required_register) {
      case RegisteredStatusEnum.Registration:
        return false;

      case RegisteredStatusEnum.Account:
        return userDataFirstRegisteredStatus === RegisteredStatusEnum.Registration;

      case RegisteredStatusEnum.Property:
        return userDataFirstRegisteredStatus !== RegisteredStatusEnum.Property;

      default:
        return false;
    }
  };

  const InitialLoading = async () => {
    const api = PropallyApiFactory();

    try {
      /** User */
      {
        const user = await api.v1UserGet({
          withCredentials: true,
        });
        setUser(user.data as unknown as User);
        // Sentry送信時にユーザー情報も付与
        Sentry.configureScope((scope) => {
          scope.setUser({ id: String(user.data.id) });
        });

        api
          .v1UserDataFirstRegisteredStatusGet({
            withCredentials: true,
          })
          .then((res) => {
            setUserDataFirstRegisteredStatus(res.data.status);
          });
      }

      /**  Property  */
      {
        const properties = await api.v1PropertiesGet({
          withCredentials: true,
        });
        const property_holders = await Promise.all(
          properties.data.map(async (e) => {
            const lease_contracts = await api.v1PropertiesPropertyIdLeaseContractsGet(e.id, {
              withCredentials: true,
            });
            const simulation_config = await api.v1PropertiesPropertyIdSimulationConfigsGet(e.id, {
              withCredentials: true,
            });

            let image;
            if (e.images.length !== 0) {
              const image_url = await api.v1PropertiesPropertyIdImagesImageIdUrlGet(
                e.id,
                e.images[e.images.length - 1].id,
                {
                  withCredentials: true,
                }
              );
              image = {
                image: e.images[e.images.length - 1],
                url: image_url.data.url,
              };
            }

            const monthly_cashflow_results = await getMonthlyCashflowResults(e.id);
            if (!monthly_cashflow_results.success) throw monthly_cashflow_results.error;

            return {
              id: e.id,
              property: e,
              images: image ? [image] : [],
              lease_contracts: lease_contracts.data,
              simulation_configs: simulation_config.data,
              cover_image_url: image ? image.url : "/assets/image/NoImage.svg",
              user_input_monthly_cashflow_results: monthly_cashflow_results.monthlyCashflow,
            };
          })
        );

        /** Proposal Unread Count */
        {
          const investmentUnreadCount = await api.v1InvestmentProposalUnreadCountGet({
            withCredentials: true,
          });
          setInvestmentUnreadCount(investmentUnreadCount.data as { unread_count: number });

          const assessmentUnreadCount = await api.v1AssessmentProposalUnreadCountGet({
            withCredentials: true,
          });
          setAssessmentUnreadCount(assessmentUnreadCount.data as { unread_count: number });
        }

        setFetchedPropertyHolders(property_holders);
        const ids = properties.data.map((element) => {
          return element.id;
        });

        if (!_.isEqual(property_ids, ids)) {
          setPropertyIds(ids);
          const owned_property_ids = properties.data
            .filter((element) => {
              return element.property_type === PROPERTY_TYPE_VALUES.owned;
            })
            .map((element) => {
              return element.id;
            });
          setSelectedPropertyIds(owned_property_ids);
          setSelectedPropertyId(owned_property_ids[0]);
        }
      }

      /** 物件一時保存情報
       *  初期STEP入力画面の場合のみ、物件一覧を経由せず、直接物件登録画面に遷移するため、ここで物件一時保存情報を取得しローカルストレージにセットしておく
       * */
      // if(isDisplayFirstStep) {
      //   api.v1PropertyTemporaryGet({ withCredentials: true }).then(res => {
      //     const property_temporary = res.data;

      //     if(_.isEmpty(property_temporary)) {
      //       removePropertyTemporaryForm();
      //       removeStoragePropertyTemporaryStep();
      //     } else {
      //       setStoragePropertyTemporaryForm(res.data.property_form);
      //       setStoragePropertyTemporaryStep(res.data.active_step_number);
      //     }
      //   });
      // }

      // Success
      enqueueSnackbar("データの読み込みに成功しました", {
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    } catch (e) {
      console.error(e);
      NotifyNetworkError(enqueueSnackbar, closeSnackbar);
    }
  };

  React.useEffect(() => {
    InitialLoading();
  }, [require_loading]);

  // 2024/07/15: リニューアルに伴い不要となったためコメントアウト
  // 以下のコメントアウトを解除すると、初回情報登録必要な画面でアカウント未登録または物件情報未登録の場合に表示される
  // if (isDisplayFirstStep()) {
  //   // 初回情報登録必要な画面でアカウント未登録または物件情報未登録の場合に表示
  //   return (
  //     <Box bgcolor="primary.main" p={3} borderRadius={8} color="white">
  //       <Box mb={3}>
  //         <Typography variant="h1">{`ようこそ ${name} さん！`}</Typography>
  //       </Box>
  //       <Box mb={3}>
  //         <Typography variant="body1">
  //           Propally for Ownersでは、物件の収支管理・シミュレーションが可能です。<br />
  //           また一定の実績を持つ不動産エージェントより、カスタムメイドなオファーを受けることが出来ます。(全て無料でご利用頂けます)<br />
  //           <br />
  //           既に不動産投資をしている方は、お持ちの物件情報を登録して収支管理を見える化しましょう。<br />
  //           初めての方も、気になる物件を登録頂ければ精確なシミュレーションを行え、意思決定の判断材料となります。<br />
  //           <br />
  //           また、あなたの入力情報を元に『<strong>価値のあるオファーが可能</strong>』と判断したエージェントより、新規の投資オファーや保有物件の価格査定・買取オファーをお届けしております。<br />
  //           詳細を聞きたい場合はオファーを承認し、実際にエージェントと会話してみましょう。
  //         </Typography>
  //       </Box>
  //       <Box mt={6} justifyContent="center" display="flex">
  //         <KeyboardArrowDownIcon />
  //         <Typography variant="body2">STEP1 : プロフィールを登録</Typography>
  //       </Box>
  //       <Box justifyContent="center" display="flex">
  //         <Button
  //           type="submit"
  //           variant="contained"
  //           size="large"
  //           component={Link}
  //           to="/first_account"
  //           disabled={Boolean(isRegisteredAccount)}
  //         >
  //           アカウント情報を登録
  //         </Button>
  //       </Box>
  //       <Box mt={3} justifyContent="center" display="flex">
  //         <KeyboardArrowDownIcon />
  //         <Typography variant="body2">STEP2 : 物件を登録</Typography>
  //       </Box>
  //       <Box justifyContent="center" display="flex">
  //         <Button
  //           type="submit"
  //           variant="contained"
  //           size="large"
  //           component={Link}
  //           to="/first_add_new_property"
  //           disabled={!isRegisteredAccount}
  //         >
  //           保有物件・検討中物件を登録
  //         </Button>
  //       </Box>
  //     </Box>
  //   );
  // }
  return props.children;
};
