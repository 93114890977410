import * as React from "react";

interface props {
  width?: string;
  height?: string;
  fill?: string;
}

const HandCoinIcon: React.FC<props> = ({ width = "32", height = "31", fill = "#103894" }): React.JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.35 21V21.15H24.5H27.5C29.9072 21.15 31.85 23.0928 31.85 25.5V26.8897L19.9975 30.5936L9.54006 27.6954L9.35 27.6428V27.84V29.85H0.65V13.65H12.4279L21.6423 17.0905C21.6424 17.0905 21.6425 17.0905 21.6425 17.0905C23.2639 17.6986 24.35 19.2626 24.35 21ZM6.5 27.15H6.65V27V16.5V16.35H6.5H3.5H3.35V16.5V27V27.15H3.5H6.5ZM19.9154 27.7597L19.9576 27.7712L19.9995 27.7583L28.8945 24.9983L29.0611 24.9466L28.985 24.7896C28.7215 24.2461 28.146 23.85 27.5 23.85H19.475C18.6799 23.85 17.9011 23.7322 17.1524 23.4827L17.1523 23.4826L13.7246 22.3449L14.5752 19.7796L18.0026 20.9173C18.0739 20.9411 18.183 20.9601 18.3079 20.9765C18.4364 20.9933 18.5933 21.0088 18.7667 21.0229C19.1138 21.0513 19.533 21.0748 19.9368 21.0936C20.3408 21.1124 20.7306 21.1265 21.0193 21.1359C21.1637 21.1406 21.2829 21.1441 21.366 21.1464L21.4622 21.1491L21.4875 21.1497L21.494 21.1499L21.4957 21.1499L21.4961 21.15L21.4962 21.15L21.5 21L21.4963 21.15L21.65 21.1538V21C21.65 20.3826 21.2664 19.832 20.6986 19.6149L20.6974 19.6145L11.9674 16.3595L11.9421 16.35H11.915H9.5H9.35V16.5V24.75V24.8644L9.46036 24.8947L19.9154 27.7597ZM15.65 7.5C15.65 3.44284 18.9428 0.15 23 0.15C27.0572 0.15 30.35 3.44284 30.35 7.5C30.35 11.5572 27.0572 14.85 23 14.85C18.9428 14.85 15.65 11.5572 15.65 7.5ZM18.35 7.5C18.35 10.0728 20.4272 12.15 23 12.15C25.5728 12.15 27.65 10.0728 27.65 7.5C27.65 4.92716 25.5728 2.85 23 2.85C20.4272 2.85 18.35 4.92716 18.35 7.5Z"
      fill={fill}
      stroke="white"
      strokeWidth="0.3"
    />
  </svg>
);
export default HandCoinIcon;
