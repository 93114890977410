import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useHistory } from "react-router";

import OfferImage from "../../../assets/image/offer.png";
import { CustomButton } from "../../scripts/components/renewal_v1/button";
import { AccountIllustrationTemplate } from "../../scripts/components/renewal_v1/template/account_illustration_template";
import theme from "../../scripts/components/renewal_v1/theme";
import { initializeATT } from "../../utilities/capacitor_logic";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginBottom: 18,
    },
    maxWidth: "342px",
  },
  offerImage: {
    maxWidth: "100%",
    width: "390px",
    height: "auto",
    marginBottom: theme.spacing(4),
  },
}));

const TrackingApprovePage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const handleNext = async () => {
    initializeATT();
    history.push("/mypage");
  };

  return (
    <AccountIllustrationTemplate>
      <Box>
        <Typography variant="h3" className={classes.text} style={{ color: theme.palette.text.primary }}>
          次の画面でトラッキング許可をすると専門家から、より適切なオファーが届くようになります。
        </Typography>
        <img src={OfferImage} alt="offer" className={classes.offerImage} />
        <CustomButton customVariant="filled" onClick={handleNext}>
          次へ
        </CustomButton>
      </Box>
    </AccountIllustrationTemplate>
  );
};

export default TrackingApprovePage;
