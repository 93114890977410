import { makeStyles, Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";

import {
  LeaseContract,
  LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum,
} from "../../../../../../api/generated";
import { PropallyApiFactory } from "../../../../../../module/custom_api_factory";
import { CustomForm } from "../../../../../../scripts/components/renewal_v1/form";
import CustomFormLabel from "../../../../../../scripts/components/renewal_v1/form_label";
import { CustomSelector } from "../../../../../../scripts/components/renewal_v1/selecter";
import { DatePickerComponent } from "../../../../../../scripts/components/renewal_v1/year_month_picker";
import { useRequestInitialLoading, PropertyManagementDetailSnackbarState } from "../../../../../../view_models/atoms";
import { SelectedProperty } from "../../../../../../view_models/property_selectors";
import { LeaseContractTypeOfTheIncomeOfTheBeginningOfContractOptions } from "../../../const";

const useStyles = makeStyles(() => ({
  textLink: {
    width: 80,
    textAlign: "center",
  },
}));

interface props {
  leaseContract?: LeaseContract;
  onCancel: () => void;
  onSave?: () => void;
  onCreate?: () => void;
}

// 履歴編集用コンポーネント
export const LeaseContractHistoryEditor: React.FC<props> = ({ leaseContract, onCancel, onSave, onCreate }) => {
  const classes = useStyles();
  // const { control, errors, getValues, watch, trigger, setValue } = useForm<
  //   LeaseContract & {
  //     type_of_the_outcome_of_the_beginning_of_contract: string;
  //     starts_at_day: string;
  //     ends_at_day: string;
  //   }
  // >();
  const { control, errors, getValues, watch, trigger, setValue } = useForm<any>();
  const history = useHistory();
  const requestInitialLoading = useRequestInitialLoading();
  // 選択済み物件取得
  const selected_property = useRecoilValue(SelectedProperty);
  // 物件詳細でのスナックバー表示
  const setManagementDetailSnackBarState = useSetRecoilState(PropertyManagementDetailSnackbarState);

  const watchValues = watch();

  const onSubmit = async () => {
    const fp = PropallyApiFactory();
    const values = getValues();
    // Trigger validation
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    const {
      type_of_the_income_of_the_beginning_of_contract,
      ...restValues
    } = values;
    const sendData: LeaseContract = {
      ...restValues,
      starts_at: `${dayjs(values.starts_at).format("YYYY-MM")}-${values.starts_at_day}`,
      ends_at: `${dayjs(values.ends_at).format("YYYY-MM")}-${values.ends_at_day}`,
    };
    if (type_of_the_income_of_the_beginning_of_contract) {
      sendData.type_of_the_income_of_the_beginning_of_contract = type_of_the_income_of_the_beginning_of_contract;
    }

    try {
      // idがある場合は更新、ない場合は新規作成
      // 更新
      if (leaseContract?.id) {
        const result = await fp.v1PropertiesPropertyIdLeaseContractsLeaseContractIdPatch(
          selected_property.id,
          leaseContract?.id,

          sendData,

          {
            withCredentials: true,
          }
        );
        if (result.status === 200) {
          requestInitialLoading();
        }
      } else {
        // 新規作成
        const result = await fp.v1PropertiesPropertyIdLeaseContractsPost(selected_property.id, sendData, {
          withCredentials: true,
        });
        if (result.status === 200) {
          requestInitialLoading();
        }
      }
    } catch (e) {
      console.error(e);
      setManagementDetailSnackBarState({
        message: "物件情報を変更できませんでした",
        status: "error",
        isOpen: true,
      });
      history.push("/management/detail");
    }
  };

  const handleSave = async () => {
    if (onSave) {
      // エラーの場合エディターの非表示後、詳細ページに戻るためこの順序
      onSave();
      await onSubmit();
    } else if (onCreate) {
      await onSubmit();
      onCreate();
    }
  };

  return (
    <Box>
      <Box mb={2}>
        <Controller
          name="starts_at"
          control={control}
          rules={{ required: true }}
          defaultValue={leaseContract?.starts_at ? dayjs(leaseContract.starts_at).format("YYYY-MM") : new Date()}
          render={({ ...field }) => (
            <DatePickerComponent
              {...field}
              labelLight="年月"
              onChange={(event) => {
                // 2年後の日付を設定する
                setValue("ends_at", dayjs(event).add(2, "year").add(-1, "day").format("YYYY-MM"));
                field.onChange(event);
              }}
            />
          )}
        />

        <Controller
          name="starts_at_day"
          control={control}
          rules={{ required: "入力してください。" }}
          defaultValue={leaseContract?.starts_at ? leaseContract.starts_at.split("-")[2] : "1"}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              labelLight="日にち"
              helperText="不明の場合は値を「1日」のままお進みください。"
              error={!!errors.starts_at_day}
              errorText={errors.starts_at_day?.message}
              onChange={(e) => {
                field.onChange(e);
                setValue("ends_at_day", e.target.value);
              }}
              placeHolder="1"
              unitSuffix="日"
              isShort
            />
          )}
        />

        <Controller
          name="ends_at"
          control={control}
          rules={{ required: true }}
          defaultValue={leaseContract?.ends_at ? dayjs(leaseContract.ends_at).format("YYYY-MM") : new Date()}
          render={({ ...field }) => <DatePickerComponent labelLight="年月" {...field} />}
        />

        <Controller
          name="ends_at_day"
          control={control}
          rules={{ required: "入力してください。" }}
          defaultValue={leaseContract?.ends_at ? leaseContract.ends_at.split("-")[2] : "1"}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              labelLight="日にち"
              helperText="不明の場合は値を「1日」のままお進みください。"
              error={!!errors.ends_at_day}
              errorText={errors.ends_at_day?.message}
              onChange={field.onChange}
              placeHolder="1"
              unitSuffix="日"
              isShort
            />
          )}
        />

        <Controller
          name="monthly_fee"
          control={control}
          rules={{ required: "入力してください。" }}
          defaultValue={leaseContract?.monthly_fee || ""}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              label="家賃収入"
              required
              error={!!errors.monthly_fee}
              errorText={errors.monthly_fee?.message}
              onChange={field.onChange}
              placeHolder="100000000"
              unitSuffix="円"
            />
          )}
        />
        <Controller
          name="monthly_management_fee"
          control={control}
          rules={{ required: "入力してください。" }}
          defaultValue={leaseContract?.monthly_management_fee || ""}
          render={({ ...field }) => (
            <CustomForm
              {...field}
              label="管理費収入"
              required
              error={!!errors.monthly_management_fee}
              errorText={errors.monthly_management_fee?.message}
              onChange={field.onChange}
              placeHolder="100000000"
              unitSuffix="円"
            />
          )}
        />
        <Controller
          name="type_of_the_income_of_the_beginning_of_contract"
          control={control}
          defaultValue={leaseContract?.type_of_the_income_of_the_beginning_of_contract || ""}
          render={({ ...field }) => (
            <CustomSelector
              {...field}
              label="賃貸借契約開始時における収入"
              onChange={field.onChange}
              error={!!errors.type_of_the_income_of_the_beginning_of_contract}
              errorText={errors.type_of_the_income_of_the_beginning_of_contract?.message}
              options={LeaseContractTypeOfTheIncomeOfTheBeginningOfContractOptions}
            />
          )}
        />
        {/* security_deposit */}
        {watchValues.type_of_the_income_of_the_beginning_of_contract ===
          LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum.SecurityDeposit && (
          <Controller
            name="security_deposit"
            control={control}
            rules={{ required: "入力してください。" }}
            defaultValue={leaseContract?.security_deposit || ""}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="更新料"
                required
                error={!!errors.security_deposit}
                errorText={errors.security_deposit?.message}
                onChange={field.onChange}
                placeHolder="1"
                unitSuffix="ヶ月分"
              />
            )}
          />
        )}
        {/* 礼金 */}
        {watchValues.type_of_the_income_of_the_beginning_of_contract ===
          LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum.KeyMoney && (
          <Controller
            name="key_money"
            control={control}
            rules={{ required: "入力してください。" }}
            defaultValue={leaseContract?.key_money || ""}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="礼金"
                required
                error={!!errors.key_money}
                errorText={errors.key_money?.message}
                onChange={field.onChange}
                placeHolder="1"
                unitSuffix="ヶ月分"
              />
            )}
          />
        )}

        <Box mt={2}>
          <CustomFormLabel label="賃貸借契約開始時における支出" />
        </Box>
        {/* 新規賃貸契約手数料 */}
        {watchValues.type_of_the_income_of_the_beginning_of_contract ===
          LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum.KeyMoney && (
          <Controller
            name="outsourcing_fee_rate_of_new_lease_contract"
            control={control}
            rules={{ required: "入力してください。" }}
            defaultValue={leaseContract?.outsourcing_fee_rate_of_new_lease_contract || ""}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="新規賃貸契約手数料"
                required
                error={!!errors.outsourcing_fee_rate_of_new_lease_contract}
                errorText={errors.outsourcing_fee_rate_of_new_lease_contract?.message}
                onChange={field.onChange}
                placeHolder="0.5"
                unitSuffix="ヶ月分"
              />
            )}
          />
        )}
        {/* 賃貸契約更新時手数料 */}
        {watchValues.type_of_the_income_of_the_beginning_of_contract ===
          LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum.SecurityDeposit && (
          <Controller
            name="outsourcing_fee_rate_of_updating_lease_contract"
            control={control}
            rules={{ required: "入力してください。" }}
            defaultValue={leaseContract?.outsourcing_fee_rate_of_updating_lease_contract || ""}
            render={({ ...field }) => (
              <CustomForm
                {...field}
                label="賃貸契約更新時手数料"
                required
                error={!!errors.outsourcing_fee_rate_of_updating_lease_contract}
                errorText={errors.outsourcing_fee_rate_of_updating_lease_contract?.message}
                onChange={field.onChange}
                placeHolder="0.5"
                unitSuffix="ヶ月分"
              />
            )}
          />
        )}
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Typography variant="button" color="primary" className={classes.textLink} onClick={onCancel}>
          キャンセル
        </Typography>
        <Typography variant="button" color="primary" className={classes.textLink} onClick={handleSave}>
          保存
        </Typography>
      </Box>
    </Box>
  );
};
