/**
 * Propertyスキーマに関するEnum getter関数
 */
import { PropertyType, Property, PropertyManagementTypeEnum } from "../generated/api";

import { KeyValueObject } from "./key_value_object";

export const PROPERTY_TYPE_VALUES = {
  owned: PropertyType.NUMBER_0,
  consideration: PropertyType.NUMBER_1,
  proposal: PropertyType.NUMBER_2,
};

export const isOwnedProperty = (property: Property): boolean => {
  return property.property_type === PROPERTY_TYPE_VALUES.owned;
};
export const isConsiderationProperty = (property: Property): boolean => {
  return property.property_type === PROPERTY_TYPE_VALUES.consideration;
};
export const isProposalProperty = (property: Property): boolean => {
  return property.property_type === PROPERTY_TYPE_VALUES.proposal;
};

export const MANAGEMENT_TYPE_KEY_VALUES: KeyValueObject[] = [
  { key: PropertyManagementTypeEnum.NotSelected, value: "未選択" },
  { key: PropertyManagementTypeEnum.MoneyCollectionAgency, value: "集金代行" },
  { key: PropertyManagementTypeEnum.SelfManagement, value: "自主管理" },
  { key: PropertyManagementTypeEnum.Subleasing, value: "サブリース" },
];

// renewal_v1
export const NEW_MANAGEMENT_TYPE_KEY_VALUES: KeyValueObject[] = [
  { key: PropertyManagementTypeEnum.MoneyCollectionAgency, value: "管理委託" },
  { key: PropertyManagementTypeEnum.SelfManagement, value: "自主管理" },
  { key: PropertyManagementTypeEnum.Subleasing, value: "サブリース" },
];

