import { MenuItem, FormControl, Select, FormHelperText, Box, SelectProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { forwardRef } from "react";

import CustomFormLabel from "./form_label";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: 161,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  error: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  placeholder: {
    color: theme.palette.text.disabled,
  },
}));

interface SelectorProps extends SelectProps {
  label?: string;
  value: string;
  onChange: (_event: React.ChangeEvent<{ value: unknown }>) => void;
  options: { value: string | number; label: string }[];
  error?: boolean;
  errorText?: string;
  disabled?: boolean;
  required?: boolean;
  suffix?: string;
  placeHolder?: string;
  isPlaceHolderGray?: boolean;
}

const CustomSelectorRef: React.ForwardRefRenderFunction<HTMLSelectElement, SelectorProps> = (
  {
    label,
    value,
    onChange,
    options,
    error,
    errorText,
    disabled,
    required,
    suffix,
    placeHolder,
    isPlaceHolderGray = false,
  },
  ref
) => {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      error={!!error}
      disabled={disabled}
      required={required}
    >
      <Box display="flex" alignItems="center" mb={1}>
        {label && <CustomFormLabel label={label} required={required} />}
        {/* <CustomFormLabel label={label} required={required} /> */}
      </Box>
      <Select
        value={value}
        onChange={onChange}
        className={error ? classes.error : ""}
        displayEmpty
        placeholder={placeHolder}
        inputRef={ref}
        renderValue={(selected) => {
          // 選択されていない場合
          if (!selected) {
            return (
              <>
                {isPlaceHolderGray ? <span className={classes.placeholder}>{placeHolder}</span> : placeHolder}
                <span style={{ marginLeft: 8 }}>{suffix ? suffix : ""}</span>
              </>
            );
          }
          // suffixがある場合は、選択された項目とsuffixを表示
          return (
            <>
              {options.find((option) => option.value === selected)?.label}
              {suffix && <span style={{ marginLeft: 8 }}>{suffix}</span>}
            </>
          );
        }}
      >
        {placeHolder && <MenuItem value="">{placeHolder}</MenuItem>}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};

const CustomSelector = forwardRef(CustomSelectorRef);
export { CustomSelector };

// サンプルコンポーネントでセレクターを使用する
const SelecterExample: React.FC = () => {
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <CustomSelector
        label="ラベル"
        value={value}
        onChange={handleChange}
        options={[{ value: "1", label: "選択済の項目" }]}
        placeHolder="デフォルトテキスト"
      />
      <CustomSelector
        label="ラベル"
        value={value}
        onChange={handleChange}
        options={[{ value: "1", label: "選択済の項目" }]}
        placeHolder="デフォルトテキスト"
        disabled
      />
      <CustomSelector
        label="ラベル"
        value={value}
        onChange={handleChange}
        options={[{ value: "1", label: "選択済の項目" }]}
        placeHolder="デフォルトテキスト"
        error={true}
        errorText="エラーテキストが入ります。"
      />
      <CustomSelector
        label="ラベル"
        value={value}
        onChange={handleChange}
        options={[{ value: "1", label: "選択済の項目" }]}
        placeHolder="デフォルトテキスト"
        required
      />
      <CustomSelector
        label="ラベル"
        value={value}
        onChange={handleChange}
        options={[{ value: "1", label: "選択済の項目" }]}
        placeHolder="デフォルトテキスト"
        suffix="年"
      />
      <CustomSelector
        label="ラベル"
        value={value}
        onChange={handleChange}
        options={[{ value: "1", label: "選択済の項目" }]}
        placeHolder="デフォルトテキスト"
        suffix="%"
      />
    </div>
  );
};

export default SelecterExample;
