import * as React from "react";

interface props {
  width?: string;
  height?: string;
  fill?: string;
}

const AnalyticsIcon: React.FC<props> = ({ width = "37", height = "36", fill = "#103894" }): React.JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.64286 3H3.5V30.8571C3.5 31.4255 3.72576 31.9705 4.12763 32.3724C4.52949 32.7742 5.07454 33 5.64286 33H33.5V30.8571H5.64286V3Z"
      fill={fill}
    />
    <path
      d="M33.5 10H25.9167V12.1429H29.8058L21.5833 20.275L16.9358 15.6679C16.8351 15.5674 16.7153 15.4877 16.5833 15.4333C16.4513 15.3789 16.3097 15.3509 16.1667 15.3509C16.0237 15.3509 15.8821 15.3789 15.75 15.4333C15.618 15.4877 15.4982 15.5674 15.3975 15.6679L7.5 23.4893L9.0275 25L16.1667 17.9393L20.8142 22.5464C20.9149 22.6469 21.0347 22.7266 21.1667 22.781C21.2987 22.8354 21.4403 22.8634 21.5833 22.8634C21.7263 22.8634 21.8679 22.8354 22 22.781C22.132 22.7266 22.2518 22.6469 22.3525 22.5464L31.3333 13.6536V17.5H33.5V10Z"
      fill={fill}
    />
  </svg>
);
export default AnalyticsIcon;
