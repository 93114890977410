/* tslint:disable */
/* eslint-disable */
/**
 * Propally API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface APIError
 */
export interface APIError {
    /**
     * 
     * @type {number}
     * @memberof APIError
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof APIError
     */
    message: string;
}
/**
 * 
 * @export
 * @interface AdminUserMemo
 */
export interface AdminUserMemo {
    /**
     * 
     * @type {number}
     * @memberof AdminUserMemo
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminUserMemo
     */
    admin_user_id?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminUserMemo
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminUserMemo
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserMemo
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserMemo
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface Agent
 */
export interface Agent {
    /**
     * 
     * @type {number}
     * @memberof Agent
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Agent
     */
    company_id?: number;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    password_confirmation?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Agent
     */
    is_authenticated_by_organizer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Agent
     */
    is_admin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    family_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    first_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    phone_number?: string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    profile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    last_accessed_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    updated_at?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Agent
     */
    is_enabled_notification_new_user?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Agent
     */
    is_enabled_notification_new_property?: boolean;
    /**
     * 
     * @type {Company}
     * @memberof Agent
     */
    company?: Company;
    /**
     * 
     * @type {AgentProfileImage}
     * @memberof Agent
     */
    agent_profile_image?: AgentProfileImage | null;
    /**
     * 
     * @type {Array<AssessmentProposal>}
     * @memberof Agent
     */
    assessment_proposals?: Array<AssessmentProposal>;
    /**
     * 
     * @type {Array<InvestmentProposal>}
     * @memberof Agent
     */
    investment_proposals?: Array<InvestmentProposal>;
    /**
     * 
     * @type {Array<ProposalMatching>}
     * @memberof Agent
     */
    proposal_matchings?: Array<ProposalMatching>;
    /**
     * 
     * @type {Array<ContractApplication>}
     * @memberof Agent
     */
    contract_applications?: Array<ContractApplication>;
}
/**
 * 
 * @export
 * @interface AgentProfileImage
 */
export interface AgentProfileImage {
    /**
     * 
     * @type {number}
     * @memberof AgentProfileImage
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentProfileImage
     */
    agent_id?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentProfileImage
     */
    object_storage_path?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgentProfileImage
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentProfileImage
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentProfileImage
     */
    image_url?: string | null;
}
/**
 * 
 * @export
 * @interface AssessmentProposal
 */
export interface AssessmentProposal {
    /**
     * 
     * @type {number}
     * @memberof AssessmentProposal
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentProposal
     */
    agent_id?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentProposal
     */
    property_id?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentProposal
     */
    user_id?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentProposal
     */
    proposal_price?: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentProposal
     */
    proposal_content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentProposal
     */
    is_user_read?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentProposal
     */
    is_user_approved?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AssessmentProposal
     */
    trade_type?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentProposal
     */
    proposal_date?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentProposal
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentProposal
     */
    updated_at?: string;
    /**
     * 
     * @type {Agent}
     * @memberof AssessmentProposal
     */
    agent?: Agent;
    /**
     * 
     * @type {Property}
     * @memberof AssessmentProposal
     */
    property?: Property;
    /**
     * 
     * @type {Array<AssessmentProposalAttachmentFile>}
     * @memberof AssessmentProposal
     */
    assessment_proposal_attachment_files?: Array<AssessmentProposalAttachmentFile>;
    /**
     * 
     * @type {ProposalStatusEnum}
     * @memberof AssessmentProposal
     */
    proposal_status?: ProposalStatusEnum | null;
}
/**
 * 
 * @export
 * @interface AssessmentProposalAttachmentFile
 */
export interface AssessmentProposalAttachmentFile {
    /**
     * 
     * @type {number}
     * @memberof AssessmentProposalAttachmentFile
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentProposalAttachmentFile
     */
    assessment_proposal_id?: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentProposalAttachmentFile
     */
    object_storage_path?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentProposalAttachmentFile
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentProposalAttachmentFile
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentProposalAttachmentFile
     */
    file_url?: string | null;
}
/**
 * 
 * @export
 * @interface AssessmentProposalUnreadCountResponse
 */
export interface AssessmentProposalUnreadCountResponse {
    /**
     * 査定提案未読数
     * @type {number}
     * @memberof AssessmentProposalUnreadCountResponse
     */
    unread_count: number;
}
/**
 * 
 * @export
 * @interface Base64EncodedImage
 */
export interface Base64EncodedImage {
    /**
     * 
     * @type {string}
     * @memberof Base64EncodedImage
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Base64EncodedImage
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof Base64EncodedImage
     */
    data: string;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    home_page_url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    business_hours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    regular_holiday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    achievements?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    strengths_of_properties_for_sale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    strengths_of_properties_for_purchase?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    strengths_of_partner_financial_institutions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    strengths_of_properties_for_management?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    advice_except_real_estate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    partner?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    appeal_point?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    updated_at?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Company
     */
    company_speciality_tag_ids?: Array<number>;
    /**
     * 
     * @type {CompanyLogoImage}
     * @memberof Company
     */
    company_logo_image?: CompanyLogoImage | null;
    /**
     * 
     * @type {Array<IdName>}
     * @memberof Company
     */
    speciality_tags?: Array<IdName>;
}
/**
 * 
 * @export
 * @interface CompanyLogoImage
 */
export interface CompanyLogoImage {
    /**
     * 
     * @type {number}
     * @memberof CompanyLogoImage
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyLogoImage
     */
    company_id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyLogoImage
     */
    object_storage_path?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyLogoImage
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyLogoImage
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyLogoImage
     */
    image_url?: string | null;
}
/**
 * 
 * @export
 * @interface ContractApplication
 */
export interface ContractApplication {
    /**
     * 
     * @type {number}
     * @memberof ContractApplication
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ContractApplication
     */
    proposal_matching_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ContractApplication
     */
    agent_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ContractApplication
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractApplication
     */
    application_date?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContractApplication
     */
    contract_price?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContractApplication
     */
    contract_property_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractApplication
     */
    contract_date?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractApplication
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractApplication
     */
    updated_at?: string;
    /**
     * 
     * @type {Agent}
     * @memberof ContractApplication
     */
    agent?: Agent;
    /**
     * 
     * @type {User}
     * @memberof ContractApplication
     */
    user?: User;
    /**
     * 
     * @type {Array<ContractApplicationAttachmentFile>}
     * @memberof ContractApplication
     */
    contract_application_attachment_files?: Array<ContractApplicationAttachmentFile>;
}
/**
 * 
 * @export
 * @interface ContractApplicationAttachmentFile
 */
export interface ContractApplicationAttachmentFile {
    /**
     * 
     * @type {number}
     * @memberof ContractApplicationAttachmentFile
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ContractApplicationAttachmentFile
     */
    contract_application_id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractApplicationAttachmentFile
     */
    object_storage_path?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractApplicationAttachmentFile
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractApplicationAttachmentFile
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractApplicationAttachmentFile
     */
    attachment_file_url?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DegreeOfConsiderationEnum {
    Considering = 'Considering',
    NotConsidered = 'NotConsidered'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DeviceTypeEnum {
    Ios = 'ios',
    Android = 'android',
    Other = 'other',
    Unknown = 'unknown'
}

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    id: number;
    /**
     * 
     * @type {DocumentType}
     * @memberof Document
     */
    document_type: DocumentType;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    file_url?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentForm
 */
export interface DocumentForm {
    /**
     * 
     * @type {string}
     * @memberof DocumentForm
     */
    memo: string;
}
/**
 * ドキュメント種別 0:マイソク・物件提案資料 1:物件パンフレット 2:売買契約書 3:重要事項説明書 4:金銭消費貸借契約書 5:返済予定表 6:賃貸借契約書 7:賃貸管理委託契約書 8:保険証券 9:納税通知書（固定資産税・都市計画税） 10:納税通知書（不動産取得税） 11:その他 
 * @export
 * @enum {string}
 */
export enum DocumentType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11
}

/**
 * HowMa(外部API)_建物エリア情報
 * @export
 * @interface HowMaArea
 */
export interface HowMaArea {
    /**
     * 住所テキスト(丁目）
     * @type {string}
     * @memberof HowMaArea
     */
    name: string;
}
/**
 * HowMa(外部API)_建物情報
 * @export
 * @interface HowMaBuilding
 */
export interface HowMaBuilding {
    /**
     * マンションID(コラビット)
     * @type {number}
     * @memberof HowMaBuilding
     */
    id: number;
    /**
     * マンション名称
     * @type {string}
     * @memberof HowMaBuilding
     */
    name: string;
    /**
     * 竣工年
     * @type {number}
     * @memberof HowMaBuilding
     */
    built_at_year: number;
    /**
     * 総階数
     * @type {number}
     * @memberof HowMaBuilding
     */
    floor_number: number | null;
    /**
     * 
     * @type {HowMaStation}
     * @memberof HowMaBuilding
     */
    station: HowMaStation | null;
    /**
     * 
     * @type {HowMaArea}
     * @memberof HowMaBuilding
     */
    area: HowMaArea;
}
/**
 * HowMa(外部API)_建物詳細取得APIレスポンスの型
 * @export
 * @interface HowMaBuildingDetailResponse
 */
export interface HowMaBuildingDetailResponse {
    /**
     * 
     * @type {HowMaBuilding}
     * @memberof HowMaBuildingDetailResponse
     */
    building: HowMaBuilding;
    /**
     * 部屋情報一覧
     * @type {Array<HowMaRoom>}
     * @memberof HowMaBuildingDetailResponse
     */
    rooms: Array<HowMaRoom>;
    /**
     * 部屋の合計件数
     * @type {number}
     * @memberof HowMaBuildingDetailResponse
     */
    total_count: number;
}
/**
 * HowMa(外部API)_建物検索APIレスポンスの型
 * @export
 * @interface HowMaBuildingsResponse
 */
export interface HowMaBuildingsResponse {
    /**
     * 建物候補一覧
     * @type {Array<HowMaBuilding>}
     * @memberof HowMaBuildingsResponse
     */
    buildings: Array<HowMaBuilding>;
    /**
     * 検索結果の合計件数
     * @type {number}
     * @memberof HowMaBuildingsResponse
     */
    total_count: number;
}
/**
 * HowMa(外部API)_建物検索APIリクエストの型
 * @export
 * @interface HowMaBuildingsSearchRequest
 */
export interface HowMaBuildingsSearchRequest {
    /**
     * マンション名テキスト
     * @type {string}
     * @memberof HowMaBuildingsSearchRequest
     */
    text: string;
    /**
     * ソート設定(任意)
     * @type {string}
     * @memberof HowMaBuildingsSearchRequest
     */
    order?: string;
    /**
     * ソート設定(昇順フラグ)(任意)
     * @type {boolean}
     * @memberof HowMaBuildingsSearchRequest
     */
    ascending?: boolean;
    /**
     * 建物候補の件数(任意)
     * @type {number}
     * @memberof HowMaBuildingsSearchRequest
     */
    limit?: number;
    /**
     * 一覧のページ番号(任意)
     * @type {number}
     * @memberof HowMaBuildingsSearchRequest
     */
    page?: number;
}
/**
 * HowMa(外部API)_部屋情報
 * @export
 * @interface HowMaRoom
 */
export interface HowMaRoom {
    /**
     * 部屋番号
     * @type {string}
     * @memberof HowMaRoom
     */
    name: string;
    /**
     * 間取り(半角文字列)
     * @type {string}
     * @memberof HowMaRoom
     */
    floor_plan: string;
    /**
     * 専有面積(㎡、小数点2桁)
     * @type {number}
     * @memberof HowMaRoom
     */
    m2: number;
    /**
     * 所在階
     * @type {number}
     * @memberof HowMaRoom
     */
    floor_number: number;
}
/**
 * HowMa(外部API)_駅情報
 * @export
 * @interface HowMaStation
 */
export interface HowMaStation {
    /**
     * 最寄駅名称
     * @type {string}
     * @memberof HowMaStation
     */
    name: string;
    /**
     * 駅コード(コラビット) 整数7桁 ※新幹線は6桁
     * @type {number}
     * @memberof HowMaStation
     */
    cd: number;
    /**
     * 路線コード(コラビット) 整数5桁 ※新幹線は4桁
     * @type {number}
     * @memberof HowMaStation
     */
    line_cd: number;
}
/**
 * 
 * @export
 * @interface Id
 */
export interface Id {
    /**
     * 
     * @type {number}
     * @memberof Id
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface IdName
 */
export interface IdName {
    /**
     * 
     * @type {number}
     * @memberof IdName
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof IdName
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * 
     * @type {number}
     * @memberof Image
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Image
     */
    property_id?: number;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    object_storage_path?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    discarded_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    image_url?: string | null;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    email_confirmation_token?: string;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    phone_number?: string;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject3
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    password_reset_token?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    password_confirmation?: string;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 
     * @type {Array<ReserveFundPriceChange>}
     * @memberof InlineObject5
     */
    reserve_fund_price_changes?: Array<ReserveFundPriceChange>;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    status?: InlineResponse200StatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse200StatusEnum {
    Ok = 'ok'
}

/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2001
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse401
 */
export interface InlineResponse401 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse401
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse500
 */
export interface InlineResponse500 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse500
     */
    status?: InlineResponse500StatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse500StatusEnum {
    InternalServerError = 'internal_server_error'
}

/**
 * 
 * @export
 * @interface InvestmentProposal
 */
export interface InvestmentProposal {
    /**
     * 
     * @type {number}
     * @memberof InvestmentProposal
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentProposal
     */
    agent_id?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentProposal
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof InvestmentProposal
     */
    proposal_content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentProposal
     */
    is_user_read?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvestmentProposal
     */
    is_user_approved?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InvestmentProposal
     */
    proposal_date?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentProposal
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentProposal
     */
    updated_at?: string;
    /**
     * 
     * @type {Agent}
     * @memberof InvestmentProposal
     */
    agent?: Agent;
    /**
     * 
     * @type {User}
     * @memberof InvestmentProposal
     */
    user?: User;
    /**
     * 
     * @type {Array<InvestmentProposalAttachmentFile>}
     * @memberof InvestmentProposal
     */
    investment_proposal_attachment_files?: Array<InvestmentProposalAttachmentFile>;
    /**
     * 
     * @type {ProposalStatusEnum}
     * @memberof InvestmentProposal
     */
    proposal_status?: ProposalStatusEnum | null;
}
/**
 * 
 * @export
 * @interface InvestmentProposalAttachmentFile
 */
export interface InvestmentProposalAttachmentFile {
    /**
     * 
     * @type {number}
     * @memberof InvestmentProposalAttachmentFile
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentProposalAttachmentFile
     */
    investment_proposal_id?: number;
    /**
     * 
     * @type {string}
     * @memberof InvestmentProposalAttachmentFile
     */
    object_storage_path?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvestmentProposalAttachmentFile
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentProposalAttachmentFile
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestmentProposalAttachmentFile
     */
    file_url?: string | null;
}
/**
 * 
 * @export
 * @interface InvestmentProposalUnreadCountResponse
 */
export interface InvestmentProposalUnreadCountResponse {
    /**
     * 投資提案未読数
     * @type {number}
     * @memberof InvestmentProposalUnreadCountResponse
     */
    unread_count: number;
}
/**
 * 職業
 * @export
 * @enum {string}
 */
export enum JobCategory {
    会社員上場 = '会社員（上場）',
    会社員非上場 = '会社員（非上場）',
    会社員外資系企業 = '会社員（外資系企業）',
    会社役員 = '会社役員',
    公務員 = '公務員',
    士業 = '士業',
    医師歯科医師 = '医師・歯科医師',
    薬剤師看護師 = '薬剤師・看護師',
    自営業 = '自営業',
    その他 = 'その他',
    入力なし = '入力なし'
}

/**
 * 業界
 * @export
 * @enum {string}
 */
export enum JobIndustry {
    It通信系 = 'IT・通信系',
    サービス系 = 'サービス系',
    メーカー系電気機械 = 'メーカー系(電気・機械)',
    メーカー系素材医薬品他 = 'メーカー系(素材・医薬品他)',
    専門コンサル系 = '専門コンサル系',
    商社系電気機械系 = '商社系(電気・機械系)',
    商社系総合素材医薬品他 = '商社系(総合・素材・医薬品他)',
    金融保険系 = '金融・保険系',
    不動産系 = '不動産系',
    建設系 = '建設系',
    マスコミ系 = 'マスコミ系',
    流通小売系 = '流通・小売系'
}

/**
 * 
 * @export
 * @interface LeaseContract
 */
export interface LeaseContract {
    /**
     * 
     * @type {number}
     * @memberof LeaseContract
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LeaseContract
     */
    property_id?: number;
    /**
     * 
     * @type {number}
     * @memberof LeaseContract
     */
    monthly_fee: number;
    /**
     * 
     * @type {number}
     * @memberof LeaseContract
     */
    monthly_management_fee: number;
    /**
     * 
     * @type {string}
     * @memberof LeaseContract
     */
    starts_at: string;
    /**
     * 
     * @type {string}
     * @memberof LeaseContract
     */
    ends_at: string;
    /**
     * 新規賃貸契約手数料 (賃料のN倍)
     * @type {number}
     * @memberof LeaseContract
     */
    outsourcing_fee_rate_of_new_lease_contract?: number | null;
    /**
     * 賃貸契約更新手数料（賃料のN倍)
     * @type {number}
     * @memberof LeaseContract
     */
    outsourcing_fee_rate_of_updating_lease_contract?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LeaseContract
     */
    key_money?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LeaseContract
     */
    security_deposit?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LeaseContract
     */
    type_of_the_income_of_the_beginning_of_contract?: LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum;
    /**
     * 
     * @type {string}
     * @memberof LeaseContract
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaseContract
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaseContract
     */
    discarded_at?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum LeaseContractTypeOfTheIncomeOfTheBeginningOfContractEnum {
    KeyMoney = 'KeyMoney',
    SecurityDeposit = 'SecurityDeposit'
}

/**
 * マスタテーブル全般用の汎用schema型
 * @export
 * @interface Master
 */
export interface Master {
    /**
     * 
     * @type {number}
     * @memberof Master
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Master
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Master
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof Master
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Master
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface MasterTag
 */
export interface MasterTag {
    /**
     * 
     * @type {number}
     * @memberof MasterTag
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MasterTag
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof MasterTag
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof MasterTag
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MasterTag
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MatchingType {
    投資 = '投資',
    査定 = '査定',
    手動 = '手動'
}

/**
 * 金融資産
 * @export
 * @enum {string}
 */
export enum MoneyAmount {
    未選択 = '未選択',
    なし = 'なし',
    _500万円 = '〜500万円',
    _5001000万円 = '500〜1,000万円',
    _10002000万円 = '1,000〜2,000万円',
    _20003000万円 = '2,000〜3,000万円',
    _30005000万円 = '3,000〜5,000万円',
    _5000万円1億円 = '5,000万円〜1億円',
    _12億円 = '1〜2億円',
    _23億円 = '2〜3億円',
    _35億円 = '3〜5億円',
    _510億円 = '5〜10億円',
    _10億円 = '10億円〜'
}

/**
 * 
 * @export
 * @interface MonthlyCashflowResult
 */
export interface MonthlyCashflowResult {
    /**
     * 
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    property_id?: number;
    /**
     * 
     * @type {string}
     * @memberof MonthlyCashflowResult
     */
    month?: string;
    /**
     * 賃料収入
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    rent_income?: number | null;
    /**
     * 管理費収入
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    management_fee_income?: number | null;
    /**
     * 賃貸管理費
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    management_cost?: number | null;
    /**
     * 物件管理費
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    building_management_cost?: number | null;
    /**
     * 修繕積立金
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    repair_fund_cost?: number | null;
    /**
     * その他経費（毎月発生するもの)
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    misc_cost?: number | null;
    /**
     * ローン支払い（金利分）
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    loan_interest?: number | null;
    /**
     * ローン利率（当月からの）（％）
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    loan_interest_rate_percentage?: number | null;
    /**
     * ローン支払い（元金分）
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    loan_principal?: number | null;
    /**
     * ローン支払い（元金に対する繰上返済）
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    loan_prepayment?: number | null;
    /**
     * ローン支払い（元金に対する繰上返済方式） * ReduceLoanAmount: 返済額軽減型 * ReduceLoanPaymentPeriod: 期間短縮型 
     * @type {string}
     * @memberof MonthlyCashflowResult
     */
    loan_prepayment_method?: MonthlyCashflowResultLoanPrepaymentMethodEnum;
    /**
     * 礼金
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    key_money?: number | null;
    /**
     * 更新料
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    security_deposit?: number | null;
    /**
     * 固定資産税
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    property_tax?: number | null;
    /**
     * 火災保険料
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    fire_insurance_cost?: number | null;
    /**
     * 火災保険料保有期間
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    fire_insurance_holding_period?: number | null;
    /**
     * 地震保険料
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    earthquake_insurance_cost?: number | null;
    /**
     * 地震保険料保有期間
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    earthquake_insurance_holding_period?: number | null;
    /**
     * 大規模修繕費用
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    large_scale_repair_fund_cost?: number | null;
    /**
     * その他一過性の費用
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    misc_onetime_cost?: number | null;
    /**
     * その他一過性の費用(内容)
     * @type {string}
     * @memberof MonthlyCashflowResult
     */
    misc_onetime_cost_contents?: string | null;
    /**
     * 不動産所得にかかる税金
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    tax_on_real_estates?: number | null;
    /**
     * 新規賃貸契約手数料（金額）
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    outsourcing_fee_of_new_lease_contract?: number | null;
    /**
     * 賃貸契約更新手数料（金額）
     * @type {number}
     * @memberof MonthlyCashflowResult
     */
    outsourcing_fee_of_updating_lease_contract?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MonthlyCashflowResult
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MonthlyCashflowResult
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MonthlyCashflowResult
     */
    discarded_at?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum MonthlyCashflowResultLoanPrepaymentMethodEnum {
    ReduceLoanAmount = 'ReduceLoanAmount',
    ReduceLoanPaymentPeriod = 'ReduceLoanPaymentPeriod'
}

/**
 * 
 * @export
 * @interface MonthlyCashflowResultUpdate
 */
export interface MonthlyCashflowResultUpdate {
    /**
     * 
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    property_id?: number;
    /**
     * 
     * @type {string}
     * @memberof MonthlyCashflowResultUpdate
     */
    month: string;
    /**
     * 賃料収入
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    rent_income?: number | null;
    /**
     * 管理費収入
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    management_fee_income?: number | null;
    /**
     * 賃貸管理費
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    management_cost?: number | null;
    /**
     * 物件管理費
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    building_management_cost?: number | null;
    /**
     * 修繕積立金
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    repair_fund_cost?: number | null;
    /**
     * その他経費（毎月発生するもの)
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    misc_cost?: number | null;
    /**
     * ローン支払い（金利分）
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    loan_interest?: number | null;
    /**
     * ローン利率（当月からの）（％）
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    loan_interest_rate_percentage?: number | null;
    /**
     * ローン支払い（元金分）
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    loan_principal?: number | null;
    /**
     * ローン支払い（元金に対する繰上返済）
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    loan_prepayment?: number | null;
    /**
     * ローン支払い（元金に対する繰上返済方式） * ReduceLoanAmount: 返済額軽減型 * ReduceLoanPaymentPeriod: 期間短縮型 
     * @type {string}
     * @memberof MonthlyCashflowResultUpdate
     */
    loan_prepayment_method?: MonthlyCashflowResultUpdateLoanPrepaymentMethodEnum;
    /**
     * 礼金
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    key_money?: number | null;
    /**
     * 更新料
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    security_deposit?: number | null;
    /**
     * 固定資産税
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    property_tax?: number | null;
    /**
     * 火災保険料
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    fire_insurance_cost?: number | null;
    /**
     * 火災保険料保有期間
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    fire_insurance_holding_period?: number | null;
    /**
     * 地震保険料
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    earthquake_insurance_cost?: number | null;
    /**
     * 地震保険料保有期間
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    earthquake_insurance_holding_period?: number | null;
    /**
     * 大規模修繕費用
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    large_scale_repair_fund_cost?: number | null;
    /**
     * その他一過性の費用
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    misc_onetime_cost?: number | null;
    /**
     * その他一過性の費用(内容)
     * @type {string}
     * @memberof MonthlyCashflowResultUpdate
     */
    misc_onetime_cost_contents?: string | null;
    /**
     * 不動産所得にかかる税金
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    tax_on_real_estates?: number | null;
    /**
     * 新規賃貸契約手数料（金額）
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    outsourcing_fee_of_new_lease_contract?: number | null;
    /**
     * 賃貸契約更新手数料（金額）
     * @type {number}
     * @memberof MonthlyCashflowResultUpdate
     */
    outsourcing_fee_of_updating_lease_contract?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MonthlyCashflowResultUpdate
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MonthlyCashflowResultUpdate
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MonthlyCashflowResultUpdate
     */
    discarded_at?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum MonthlyCashflowResultUpdateLoanPrepaymentMethodEnum {
    ReduceLoanAmount = 'ReduceLoanAmount',
    ReduceLoanPaymentPeriod = 'ReduceLoanPaymentPeriod'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum Period {
    _6ヶ月未満 = '6ヶ月未満',
    _6ヶ月以上1年未満 = '6ヶ月以上1年未満',
    _1年以上3年未満 = '1年以上3年未満',
    _3年以上 = '3年以上'
}

/**
 * 
 * @export
 * @interface Property
 */
export interface Property {
    /**
     * 
     * @type {number}
     * @memberof Property
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Property
     */
    user_id?: number;
    /**
     * 
     * @type {PropertyType}
     * @memberof Property
     */
    property_type?: PropertyType;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    prefecture?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    town?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    block?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    room_number?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Property
     */
    floor?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    railway?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    station?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Property
     */
    walk?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    bought_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    built_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    structure?: PropertyStructureEnum;
    /**
     * 
     * @type {number}
     * @memberof Property
     */
    occupied_area_m2?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    layout?: PropertyLayoutEnum;
    /**
     * 購入価格 (下記priceof_* の合計)
     * @type {number}
     * @memberof Property
     */
    total_price_of_property?: number;
    /**
     * うち建物
     * @type {number}
     * @memberof Property
     */
    priceof_building?: number | null;
    /**
     * うち設備
     * @type {number}
     * @memberof Property
     */
    priceof_facilities?: number | null;
    /**
     * うち土地
     * @type {number}
     * @memberof Property
     */
    priceof_land?: number | null;
    /**
     * うち消費税
     * @type {number}
     * @memberof Property
     */
    priceof_tax?: number | null;
    /**
     * 金融機関
     * @type {string}
     * @memberof Property
     */
    financial_institution?: string | null;
    /**
     * ローン総額
     * @type {number}
     * @memberof Property
     */
    loan_amount?: number;
    /**
     * 支払い開始月
     * @type {string}
     * @memberof Property
     */
    payment_starts_at?: string;
    /**
     * 支払い期間（年）
     * @type {number}
     * @memberof Property
     */
    payment_period?: number;
    /**
     * 賃貸管理会社
     * @type {string}
     * @memberof Property
     */
    rental_management_company?: string | null;
    /**
     * 管理形態
     * @type {string}
     * @memberof Property
     */
    management_type?: PropertyManagementTypeEnum;
    /**
     * 売却状況
     * @type {string}
     * @memberof Property
     */
    sales_status?: PropertySalesStatusEnum;
    /**
     * 不動産取得税
     * @type {number}
     * @memberof Property
     */
    property_acquisition_tax?: number | null;
    /**
     * 不動産取得税の支払い月
     * @type {string}
     * @memberof Property
     */
    property_acquisition_tax_payment_month?: string | null;
    /**
     * 購入時諸費用
     * @type {number}
     * @memberof Property
     */
    misc_expences_at_bought?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    discarded_at?: string | null;
    /**
     * 
     * @type {Array<Image>}
     * @memberof Property
     */
    images?: Array<Image>;
    /**
     * 
     * @type {Array<LeaseContract>}
     * @memberof Property
     */
    lease_contracts?: Array<LeaseContract>;
    /**
     * 
     * @type {Array<MonthlyCashflowResult>}
     * @memberof Property
     */
    monthly_cashflow_results?: Array<MonthlyCashflowResult>;
    /**
     * 
     * @type {Image}
     * @memberof Property
     */
    image?: Image;
    /**
     * 
     * @type {LeaseContract}
     * @memberof Property
     */
    lease_contract?: LeaseContract;
    /**
     * 
     * @type {MonthlyCashflowResult}
     * @memberof Property
     */
    monthly_cashflow_result?: MonthlyCashflowResult;
    /**
     * 新着物件かどうか
     * @type {boolean}
     * @memberof Property
     */
    is_new_label?: boolean;
    /**
     * お気に入り登録済みかどうか
     * @type {boolean}
     * @memberof Property
     */
    is_favorite?: boolean;
    /**
     * ローン残債
     * @type {number}
     * @memberof Property
     */
    remain_loan_amount?: number | null;
    /**
     * 月額家賃収入 + 月額管理費収入
     * @type {number}
     * @memberof Property
     */
    monthly_total_fee?: number | null;
    /**
     * 月額建物管理費 + 月額修繕積立金
     * @type {number}
     * @memberof Property
     */
    monthly_building_total_cost?: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum PropertyStructureEnum {
    未選択 = '未選択',
    Src造鉄骨鉄筋コンクリート造 = 'SRC造(鉄骨鉄筋コンクリート造)',
    Rc造鉄筋コンクリート造 = 'RC造(鉄筋コンクリート造)',
    S造重量鉄骨造 = 'S造(重量鉄骨造)',
    木造 = '木造'
}
/**
    * @export
    * @enum {string}
    */
export enum PropertyLayoutEnum {
    未選択 = '未選択',
    _1R = '1R',
    _1K = '1K',
    _1Lk1Ldk = '1LK/1LDK',
    _2L2Dk = '2L/2DK',
    _2Lk2Ldk = '2LK/2LDK',
    _3K3Dk = '3K/3DK',
    _3Lk3Ldk = '3LK/3LDK',
    _4K4Dk = '4K/4DK',
    _4Lk4Ldk = '4LK/4LDK',
    _5K5Dk = '5K/5DK',
    _5Lk5Ldk = '5LK/5LDK',
    _6K6Dk = '6K/6DK'
}
/**
    * @export
    * @enum {string}
    */
export enum PropertyManagementTypeEnum {
    NotSelected = 'NotSelected',
    Outsourcing = 'Outsourcing',
    MoneyCollectionAgency = 'MoneyCollectionAgency',
    SelfManagement = 'SelfManagement',
    Subleasing = 'Subleasing'
}
/**
    * @export
    * @enum {string}
    */
export enum PropertySalesStatusEnum {
    未選択 = '未選択',
    売りに出していない = '売りに出していない',
    一般媒介契約締結済み = '一般媒介契約締結済み',
    専任媒介契約締結済み = '専任媒介契約締結済み',
    専属専任媒介締結済み = '専属専任媒介締結済み'
}

/**
 * 
 * @export
 * @interface PropertyBuilder
 */
export interface PropertyBuilder {
    /**
     * 
     * @type {number}
     * @memberof PropertyBuilder
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBuilder
     */
    user_id?: number;
    /**
     * 
     * @type {PropertyType}
     * @memberof PropertyBuilder
     */
    property_type?: PropertyType;
    /**
     * 
     * @type {string}
     * @memberof PropertyBuilder
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyBuilder
     */
    prefecture?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyBuilder
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyBuilder
     */
    town?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyBuilder
     */
    block?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyBuilder
     */
    room_number?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PropertyBuilder
     */
    floor?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyBuilder
     */
    railway?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyBuilder
     */
    station?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PropertyBuilder
     */
    walk?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyBuilder
     */
    bought_at?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyBuilder
     */
    built_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyBuilder
     */
    structure?: PropertyBuilderStructureEnum;
    /**
     * 
     * @type {number}
     * @memberof PropertyBuilder
     */
    occupied_area_m2?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyBuilder
     */
    layout?: PropertyBuilderLayoutEnum;
    /**
     * 購入価格 (下記priceof_* の合計)
     * @type {number}
     * @memberof PropertyBuilder
     */
    total_price_of_property?: number;
    /**
     * うち建物
     * @type {number}
     * @memberof PropertyBuilder
     */
    priceof_building?: number | null;
    /**
     * うち設備
     * @type {number}
     * @memberof PropertyBuilder
     */
    priceof_facilities?: number | null;
    /**
     * うち土地
     * @type {number}
     * @memberof PropertyBuilder
     */
    priceof_land?: number | null;
    /**
     * うち消費税
     * @type {number}
     * @memberof PropertyBuilder
     */
    priceof_tax?: number | null;
    /**
     * 金融機関
     * @type {string}
     * @memberof PropertyBuilder
     */
    financial_institution?: string | null;
    /**
     * ローン総額
     * @type {number}
     * @memberof PropertyBuilder
     */
    loan_amount?: number;
    /**
     * 支払い開始月
     * @type {string}
     * @memberof PropertyBuilder
     */
    payment_starts_at?: string;
    /**
     * 支払い期間（年）
     * @type {number}
     * @memberof PropertyBuilder
     */
    payment_period?: number;
    /**
     * 賃貸管理会社
     * @type {string}
     * @memberof PropertyBuilder
     */
    rental_management_company?: string | null;
    /**
     * 管理形態
     * @type {string}
     * @memberof PropertyBuilder
     */
    management_type?: PropertyBuilderManagementTypeEnum;
    /**
     * 売却状況
     * @type {string}
     * @memberof PropertyBuilder
     */
    sales_status?: PropertyBuilderSalesStatusEnum;
    /**
     * 不動産取得税
     * @type {number}
     * @memberof PropertyBuilder
     */
    property_acquisition_tax?: number | null;
    /**
     * 不動産取得税の支払い月
     * @type {string}
     * @memberof PropertyBuilder
     */
    property_acquisition_tax_payment_month?: string | null;
    /**
     * 購入時諸費用
     * @type {number}
     * @memberof PropertyBuilder
     */
    misc_expences_at_bought?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyBuilder
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyBuilder
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyBuilder
     */
    discarded_at?: string | null;
    /**
     * 
     * @type {Array<Image>}
     * @memberof PropertyBuilder
     */
    images?: Array<Image>;
    /**
     * 
     * @type {Array<LeaseContract>}
     * @memberof PropertyBuilder
     */
    lease_contracts?: Array<LeaseContract>;
    /**
     * 
     * @type {Array<MonthlyCashflowResult>}
     * @memberof PropertyBuilder
     */
    monthly_cashflow_results?: Array<MonthlyCashflowResult>;
    /**
     * 
     * @type {Image}
     * @memberof PropertyBuilder
     */
    image?: Image;
    /**
     * 
     * @type {LeaseContract}
     * @memberof PropertyBuilder
     */
    lease_contract?: LeaseContract;
    /**
     * 
     * @type {MonthlyCashflowResult}
     * @memberof PropertyBuilder
     */
    monthly_cashflow_result?: MonthlyCashflowResult;
    /**
     * 新着物件かどうか
     * @type {boolean}
     * @memberof PropertyBuilder
     */
    is_new_label?: boolean;
    /**
     * お気に入り登録済みかどうか
     * @type {boolean}
     * @memberof PropertyBuilder
     */
    is_favorite?: boolean;
    /**
     * ローン残債
     * @type {number}
     * @memberof PropertyBuilder
     */
    remain_loan_amount?: number | null;
    /**
     * 月額家賃収入 + 月額管理費収入
     * @type {number}
     * @memberof PropertyBuilder
     */
    monthly_total_fee?: number | null;
    /**
     * 月額建物管理費 + 月額修繕積立金
     * @type {number}
     * @memberof PropertyBuilder
     */
    monthly_building_total_cost?: number | null;
    /**
     * 
     * @type {MonthlyCashflowResult}
     * @memberof PropertyBuilder
     */
    cashflow_result_of_the_first_month?: MonthlyCashflowResult;
}

/**
    * @export
    * @enum {string}
    */
export enum PropertyBuilderStructureEnum {
    未選択 = '未選択',
    Src造鉄骨鉄筋コンクリート造 = 'SRC造(鉄骨鉄筋コンクリート造)',
    Rc造鉄筋コンクリート造 = 'RC造(鉄筋コンクリート造)',
    S造重量鉄骨造 = 'S造(重量鉄骨造)',
    木造 = '木造'
}
/**
    * @export
    * @enum {string}
    */
export enum PropertyBuilderLayoutEnum {
    未選択 = '未選択',
    _1R = '1R',
    _1K = '1K',
    _1Lk1Ldk = '1LK/1LDK',
    _2L2Dk = '2L/2DK',
    _2Lk2Ldk = '2LK/2LDK',
    _3K3Dk = '3K/3DK',
    _3Lk3Ldk = '3LK/3LDK',
    _4K4Dk = '4K/4DK',
    _4Lk4Ldk = '4LK/4LDK',
    _5K5Dk = '5K/5DK',
    _5Lk5Ldk = '5LK/5LDK',
    _6K6Dk = '6K/6DK'
}
/**
    * @export
    * @enum {string}
    */
export enum PropertyBuilderManagementTypeEnum {
    NotSelected = 'NotSelected',
    Outsourcing = 'Outsourcing',
    MoneyCollectionAgency = 'MoneyCollectionAgency',
    SelfManagement = 'SelfManagement',
    Subleasing = 'Subleasing'
}
/**
    * @export
    * @enum {string}
    */
export enum PropertyBuilderSalesStatusEnum {
    未選択 = '未選択',
    売りに出していない = '売りに出していない',
    一般媒介契約締結済み = '一般媒介契約締結済み',
    専任媒介契約締結済み = '専任媒介契約締結済み',
    専属専任媒介締結済み = '専属専任媒介締結済み'
}

/**
 * 
 * @export
 * @interface PropertyBuilderAllOf
 */
export interface PropertyBuilderAllOf {
    /**
     * 
     * @type {LeaseContract}
     * @memberof PropertyBuilderAllOf
     */
    lease_contract?: LeaseContract;
    /**
     * 
     * @type {MonthlyCashflowResult}
     * @memberof PropertyBuilderAllOf
     */
    cashflow_result_of_the_first_month?: MonthlyCashflowResult;
}
/**
 * 
 * @export
 * @interface PropertyTemporary
 */
export interface PropertyTemporary {
    /**
     * 
     * @type {number}
     * @memberof PropertyTemporary
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyTemporary
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyTemporary
     */
    updated_at?: string;
}
/**
 * 物件種別 0: 保有物件 1: 検討中物件 2: 提案物件 
 * @export
 * @enum {string}
 */
export enum PropertyType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 * 
 * @export
 * @interface ProposalApprovedRequest
 */
export interface ProposalApprovedRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ProposalApprovedRequest
     */
    is_approved: boolean;
}
/**
 * 
 * @export
 * @interface ProposalMatching
 */
export interface ProposalMatching {
    /**
     * 
     * @type {number}
     * @memberof ProposalMatching
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProposalMatching
     */
    agent_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProposalMatching
     */
    user_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProposalMatching
     */
    assessment_proposal_id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProposalMatching
     */
    investment_proposal_id?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProposalMatching
     */
    is_agent_read?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProposalMatching
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalMatching
     */
    updated_at?: string;
    /**
     * 
     * @type {Agent}
     * @memberof ProposalMatching
     */
    agent?: Agent;
    /**
     * 
     * @type {User}
     * @memberof ProposalMatching
     */
    user?: User;
    /**
     * 
     * @type {InvestmentProposal}
     * @memberof ProposalMatching
     */
    investment_proposal?: InvestmentProposal;
    /**
     * 
     * @type {AssessmentProposal}
     * @memberof ProposalMatching
     */
    assessment_proposal?: AssessmentProposal;
    /**
     * 対象ユーザの成約申請済みが1件以上あるかどうか
     * @type {boolean}
     * @memberof ProposalMatching
     */
    is_contract_applied?: boolean;
    /**
     * 
     * @type {MatchingType}
     * @memberof ProposalMatching
     */
    matching_type?: MatchingType;
}
/**
 * 
 * @export
 * @interface ProposalMatchingRequest
 */
export interface ProposalMatchingRequest {
    /**
     * 
     * @type {number}
     * @memberof ProposalMatchingRequest
     */
    agent_id?: number;
    /**
     * マッチした提案IDがどちらかに入る
     * @type {number}
     * @memberof ProposalMatchingRequest
     */
    assessment_proposal_id?: number;
    /**
     * マッチした提案IDがどちらかに入る
     * @type {number}
     * @memberof ProposalMatchingRequest
     */
    investment_proposal_id?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProposalStatusEnum {
    提案済み = '提案済み',
    マッチ = 'マッチ',
    成約済み = '成約済み'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum RegisteredStatusEnum {
    Registration = 'Registration',
    Account = 'Account',
    Property = 'Property'
}

/**
 * reinfolib(外部API)_価格情報APIレスポンスの型
 * @export
 * @interface ReinfolibPrice
 */
export interface ReinfolibPrice {
    /**
     * 価格情報種類
     * @type {string}
     * @memberof ReinfolibPrice
     */
    PriceCategory?: string;
    /**
     * 取引の種類
     * @type {string}
     * @memberof ReinfolibPrice
     */
    Type?: string;
    /**
     * 地区
     * @type {string}
     * @memberof ReinfolibPrice
     */
    Region?: string;
    /**
     * 市区町村コード
     * @type {string}
     * @memberof ReinfolibPrice
     */
    MunicipalityCode?: string;
    /**
     * 都道府県名
     * @type {string}
     * @memberof ReinfolibPrice
     */
    Prefecture?: string;
    /**
     * 市区町村名
     * @type {string}
     * @memberof ReinfolibPrice
     */
    Municipality?: string;
    /**
     * 地区名
     * @type {string}
     * @memberof ReinfolibPrice
     */
    DistrictName?: string;
    /**
     * 取引価格
     * @type {string}
     * @memberof ReinfolibPrice
     */
    TradePrice?: string;
    /**
     * 坪単価
     * @type {string}
     * @memberof ReinfolibPrice
     */
    PricePerUnit?: string;
    /**
     * 間取り
     * @type {string}
     * @memberof ReinfolibPrice
     */
    FloorPlan?: string;
    /**
     * 面積（平方メートル）
     * @type {string}
     * @memberof ReinfolibPrice
     */
    Area?: string;
    /**
     * 取引価格（平方メートル単価）
     * @type {string}
     * @memberof ReinfolibPrice
     */
    UnitPrice?: string;
    /**
     * 土地の形状
     * @type {string}
     * @memberof ReinfolibPrice
     */
    LandShape?: string;
    /**
     * 間口
     * @type {string}
     * @memberof ReinfolibPrice
     */
    Frontage?: string;
    /**
     * 延床面積（平方メートル）
     * @type {string}
     * @memberof ReinfolibPrice
     */
    TotalFloorArea?: string;
    /**
     * 建築年
     * @type {string}
     * @memberof ReinfolibPrice
     */
    BuildingYear?: string;
    /**
     * 建物の構造
     * @type {string}
     * @memberof ReinfolibPrice
     */
    Structure?: string;
    /**
     * 用途
     * @type {string}
     * @memberof ReinfolibPrice
     */
    Use?: string;
    /**
     * 今後の利用目的
     * @type {string}
     * @memberof ReinfolibPrice
     */
    Purpose?: string;
    /**
     * 前面道路：方位
     * @type {string}
     * @memberof ReinfolibPrice
     */
    Direction?: string;
    /**
     * 前面道路：種類
     * @type {string}
     * @memberof ReinfolibPrice
     */
    Classification?: string;
    /**
     * 前面道路：幅員
     * @type {string}
     * @memberof ReinfolibPrice
     */
    Breadth?: string;
    /**
     * 都市計画
     * @type {string}
     * @memberof ReinfolibPrice
     */
    CityPlanning?: string;
    /**
     * 建蔽率（%）
     * @type {string}
     * @memberof ReinfolibPrice
     */
    CoverageRatio?: string;
    /**
     * 容積率（%）
     * @type {string}
     * @memberof ReinfolibPrice
     */
    FloorAreaRatio?: string;
    /**
     * 取引時点
     * @type {string}
     * @memberof ReinfolibPrice
     */
    Period?: string;
    /**
     * 改装
     * @type {string}
     * @memberof ReinfolibPrice
     */
    Renovation?: string;
    /**
     * 取引の事情等
     * @type {string}
     * @memberof ReinfolibPrice
     */
    Remarks?: string;
}
/**
 * 
 * @export
 * @interface ReserveFundPriceChange
 */
export interface ReserveFundPriceChange {
    /**
     * 修繕積立金変動の発生月
     * @type {string}
     * @memberof ReserveFundPriceChange
     */
    month?: string;
    /**
     * 修繕積立金変動の金額
     * @type {number}
     * @memberof ReserveFundPriceChange
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface ResponseMessage
 */
export interface ResponseMessage {
    /**
     * 
     * @type {string}
     * @memberof ResponseMessage
     */
    message?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ScreenNameEnum {
    Top = 'TOP'
}

/**
 * 
 * @export
 * @interface SimulationConfig
 */
export interface SimulationConfig {
    /**
     * 
     * @type {string}
     * @memberof SimulationConfig
     */
    proposed_by?: SimulationConfigProposedByEnum;
    /**
     * 
     * @type {SimulationPatternEnum}
     * @memberof SimulationConfig
     */
    simulation_pattern?: SimulationPatternEnum | null;
    /**
     * 
     * @type {string}
     * @memberof SimulationConfig
     */
    market_price_mode: SimulationConfigMarketPriceModeEnum;
    /**
     * 
     * @type {number}
     * @memberof SimulationConfig
     */
    market_price_change_ends_at: number;
    /**
     * 
     * @type {number}
     * @memberof SimulationConfig
     */
    market_price_change_down_value: number;
    /**
     * 
     * @type {string}
     * @memberof SimulationConfig
     */
    market_price_change_at_bought_mode: SimulationConfigMarketPriceChangeAtBoughtModeEnum;
    /**
     * 
     * @type {number}
     * @memberof SimulationConfig
     */
    market_price_change_at_bought_value: number;
    /**
     * 
     * @type {string}
     * @memberof SimulationConfig
     */
    house_rent_mode: SimulationConfigHouseRentModeEnum;
    /**
     * 
     * @type {number}
     * @memberof SimulationConfig
     */
    house_rent_price_change_ends_at: number;
    /**
     * 
     * @type {number}
     * @memberof SimulationConfig
     */
    house_rent_price_change_down_value: number;
    /**
     * 
     * @type {number}
     * @memberof SimulationConfig
     */
    house_rent_spare_months: number;
    /**
     * 
     * @type {number}
     * @memberof SimulationConfig
     */
    house_rent_spare_months_for_N_contracts: number;
    /**
     * 
     * @type {Array<ReserveFundPriceChange>}
     * @memberof SimulationConfig
     */
    reserve_fund_price_changes?: Array<ReserveFundPriceChange>;
}

/**
    * @export
    * @enum {string}
    */
export enum SimulationConfigProposedByEnum {
    Realtor = 'Realtor'
}
/**
    * @export
    * @enum {string}
    */
export enum SimulationConfigMarketPriceModeEnum {
    Real = 'Real',
    Percentage = 'Percentage'
}
/**
    * @export
    * @enum {string}
    */
export enum SimulationConfigMarketPriceChangeAtBoughtModeEnum {
    Real = 'Real',
    Percentage = 'Percentage'
}
/**
    * @export
    * @enum {string}
    */
export enum SimulationConfigHouseRentModeEnum {
    Real = 'Real',
    Percentage = 'Percentage'
}

/**
 * 
 * @export
 * @interface SimulationConfigWithId
 */
export interface SimulationConfigWithId {
    /**
     * 
     * @type {number}
     * @memberof SimulationConfigWithId
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SimulationConfigWithId
     */
    proposed_by?: SimulationConfigWithIdProposedByEnum;
    /**
     * 
     * @type {SimulationPatternEnum}
     * @memberof SimulationConfigWithId
     */
    simulation_pattern?: SimulationPatternEnum | null;
    /**
     * 
     * @type {string}
     * @memberof SimulationConfigWithId
     */
    market_price_mode: SimulationConfigWithIdMarketPriceModeEnum;
    /**
     * 
     * @type {number}
     * @memberof SimulationConfigWithId
     */
    market_price_change_ends_at: number;
    /**
     * 
     * @type {number}
     * @memberof SimulationConfigWithId
     */
    market_price_change_down_value: number;
    /**
     * 
     * @type {string}
     * @memberof SimulationConfigWithId
     */
    market_price_change_at_bought_mode: SimulationConfigWithIdMarketPriceChangeAtBoughtModeEnum;
    /**
     * 
     * @type {number}
     * @memberof SimulationConfigWithId
     */
    market_price_change_at_bought_value: number;
    /**
     * 
     * @type {string}
     * @memberof SimulationConfigWithId
     */
    house_rent_mode: SimulationConfigWithIdHouseRentModeEnum;
    /**
     * 
     * @type {number}
     * @memberof SimulationConfigWithId
     */
    house_rent_price_change_ends_at: number;
    /**
     * 
     * @type {number}
     * @memberof SimulationConfigWithId
     */
    house_rent_price_change_down_value: number;
    /**
     * 
     * @type {number}
     * @memberof SimulationConfigWithId
     */
    house_rent_spare_months: number;
    /**
     * 
     * @type {number}
     * @memberof SimulationConfigWithId
     */
    house_rent_spare_months_for_N_contracts: number;
    /**
     * 
     * @type {Array<ReserveFundPriceChange>}
     * @memberof SimulationConfigWithId
     */
    reserve_fund_price_changes?: Array<ReserveFundPriceChange>;
}

/**
    * @export
    * @enum {string}
    */
export enum SimulationConfigWithIdProposedByEnum {
    Realtor = 'Realtor'
}
/**
    * @export
    * @enum {string}
    */
export enum SimulationConfigWithIdMarketPriceModeEnum {
    Real = 'Real',
    Percentage = 'Percentage'
}
/**
    * @export
    * @enum {string}
    */
export enum SimulationConfigWithIdMarketPriceChangeAtBoughtModeEnum {
    Real = 'Real',
    Percentage = 'Percentage'
}
/**
    * @export
    * @enum {string}
    */
export enum SimulationConfigWithIdHouseRentModeEnum {
    Real = 'Real',
    Percentage = 'Percentage'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum SimulationPatternEnum {
    楽観的 = '楽観的',
    中立 = '中立',
    悲観的 = '悲観的',
    カスタム = 'カスタム'
}

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    identifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    family_name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    family_name_kana?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    first_name_kana?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phone_number?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    birthday?: string | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    income_tax_rate?: number | null;
    /**
     * 
     * @type {JobCategory}
     * @memberof User
     */
    job_category?: JobCategory | null;
    /**
     * 住所（都道府県）
     * @type {string}
     * @memberof User
     */
    address_prefecture?: string | null;
    /**
     * 
     * @type {JobIndustry}
     * @memberof User
     */
    job_industry?: JobIndustry | null;
    /**
     * 
     * @type {Period}
     * @memberof User
     */
    job_continuous_period?: Period | null;
    /**
     * 
     * @type {MoneyAmount}
     * @memberof User
     */
    financial_asset?: MoneyAmount | null;
    /**
     * 年収
     * @type {number}
     * @memberof User
     */
    annual_income?: number | null;
    /**
     * 保有物件数
     * @type {number}
     * @memberof User
     */
    holding_property_count?: number | null;
    /**
     * 不動産投資ローン残高合計
     * @type {number}
     * @memberof User
     */
    real_estate_loan_amount?: number | null;
    /**
     * 現在のお住まいマスタデータID
     * @type {number}
     * @memberof User
     */
    current_residence_type_id?: number | null;
    /**
     * 自宅毎月家賃額
     * @type {number}
     * @memberof User
     */
    monthly_rent_payment_amount?: number | null;
    /**
     * 住宅ローン残高合計
     * @type {number}
     * @memberof User
     */
    home_loan_amount?: number | null;
    /**
     * その他ご要望
     * @type {string}
     * @memberof User
     */
    other_request_text?: string | null;
    /**
     * 
     * @type {RegisteredStatusEnum}
     * @memberof User
     */
    data_first_registered_status?: RegisteredStatusEnum;
    /**
     * 不動産会社からの提案希望 false:希望しない true:希望する 
     * @type {boolean}
     * @memberof User
     */
    request_the_proposal?: boolean;
    /**
     * 提案の連絡方法 0:メール 1:LINE 
     * @type {number}
     * @memberof User
     */
    way_to_propose?: UserWayToProposeEnum;
    /**
     * 
     * @type {DegreeOfConsiderationEnum}
     * @memberof User
     */
    degree_of_consideration_for_investment?: DegreeOfConsiderationEnum | null;
    /**
     * 
     * @type {DegreeOfConsiderationEnum}
     * @memberof User
     */
    degree_of_consideration_for_sale?: DegreeOfConsiderationEnum | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    purpose_of_use_free_text?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    email_confirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password_confirmation?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    last_accessed_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    account_updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    discarded_at?: string | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    phone_number_auth_code?: number;
    /**
     * 
     * @type {Array<AssessmentProposal>}
     * @memberof User
     */
    assessment_proposals?: Array<AssessmentProposal>;
    /**
     * 
     * @type {Array<InvestmentProposal>}
     * @memberof User
     */
    investment_proposals?: Array<InvestmentProposal>;
    /**
     * 
     * @type {Array<ProposalMatching>}
     * @memberof User
     */
    proposal_matchings?: Array<ProposalMatching>;
    /**
     * 
     * @type {Array<ContractApplication>}
     * @memberof User
     */
    contract_applications?: Array<ContractApplication>;
    /**
     * 
     * @type {AdminUserMemo}
     * @memberof User
     */
    admin_user_memo?: AdminUserMemo;
    /**
     * 
     * @type {Array<MasterTag>}
     * @memberof User
     */
    master_tags?: Array<MasterTag>;
    /**
     * 関心のあるタグIDリスト
     * @type {Array<number>}
     * @memberof User
     */
    interest_tag_ids?: Array<number>;
    /**
     * 
     * @type {Master}
     * @memberof User
     */
    current_residence_type?: Master | null;
    /**
     * 利用目的IDリスト
     * @type {Array<number>}
     * @memberof User
     */
    purpose_of_use_ids?: Array<number>;
    /**
     * 
     * @type {UserDeviceToken}
     * @memberof User
     */
    user_device_token?: UserDeviceToken;
    /**
     * 
     * @type {Array<UserAccessScreenLogMonthlySummary>}
     * @memberof User
     */
    user_access_screen_log_monthly_summaries?: Array<UserAccessScreenLogMonthlySummary>;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    age?: number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    is_new_label?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    is_favorite?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UserWayToProposeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @interface UserAccessScreenLog
 */
export interface UserAccessScreenLog {
    /**
     * 
     * @type {number}
     * @memberof UserAccessScreenLog
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAccessScreenLog
     */
    user_id?: number;
    /**
     * 
     * @type {ScreenNameEnum}
     * @memberof UserAccessScreenLog
     */
    screen_name?: ScreenNameEnum;
    /**
     * 
     * @type {string}
     * @memberof UserAccessScreenLog
     */
    accessed_at?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccessScreenLog
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccessScreenLog
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface UserAccessScreenLogMonthlySummary
 */
export interface UserAccessScreenLogMonthlySummary {
    /**
     * 
     * @type {number}
     * @memberof UserAccessScreenLogMonthlySummary
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAccessScreenLogMonthlySummary
     */
    user_id?: number;
    /**
     * 
     * @type {ScreenNameEnum}
     * @memberof UserAccessScreenLogMonthlySummary
     */
    screen_name?: ScreenNameEnum;
    /**
     * 
     * @type {number}
     * @memberof UserAccessScreenLogMonthlySummary
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAccessScreenLogMonthlySummary
     */
    month?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAccessScreenLogMonthlySummary
     */
    total_access_count?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAccessScreenLogMonthlySummary
     */
    daily_deduplication_access_count?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAccessScreenLogMonthlySummary
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccessScreenLogMonthlySummary
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface UserDataFirstRegisteredStatus
 */
export interface UserDataFirstRegisteredStatus {
    /**
     * 
     * @type {RegisteredStatusEnum}
     * @memberof UserDataFirstRegisteredStatus
     */
    status?: RegisteredStatusEnum;
}
/**
 * 
 * @export
 * @interface UserDeviceToken
 */
export interface UserDeviceToken {
    /**
     * 
     * @type {number}
     * @memberof UserDeviceToken
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDeviceToken
     */
    device_token: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserDeviceToken
     */
    user_id?: number;
    /**
     * 
     * @type {DeviceTypeEnum}
     * @memberof UserDeviceToken
     */
    device_type?: DeviceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDeviceToken
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDeviceToken
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface UserRegistrations
 */
export interface UserRegistrations {
    /**
     * 
     * @type {number}
     * @memberof UserRegistrations
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrations
     */
    identifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrations
     */
    family_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrations
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrations
     */
    family_name_kana: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrations
     */
    first_name_kana: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrations
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrations
     */
    phone_number: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrations
     */
    birthday?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserRegistrations
     */
    income_tax_rate?: number | null;
    /**
     * 
     * @type {JobCategory}
     * @memberof UserRegistrations
     */
    job_category?: JobCategory | null;
    /**
     * 住所（都道府県）
     * @type {string}
     * @memberof UserRegistrations
     */
    address_prefecture?: string | null;
    /**
     * 
     * @type {JobIndustry}
     * @memberof UserRegistrations
     */
    job_industry?: JobIndustry | null;
    /**
     * 
     * @type {Period}
     * @memberof UserRegistrations
     */
    job_continuous_period?: Period | null;
    /**
     * 
     * @type {MoneyAmount}
     * @memberof UserRegistrations
     */
    financial_asset?: MoneyAmount | null;
    /**
     * 年収
     * @type {number}
     * @memberof UserRegistrations
     */
    annual_income?: number | null;
    /**
     * 保有物件数
     * @type {number}
     * @memberof UserRegistrations
     */
    holding_property_count?: number | null;
    /**
     * 不動産投資ローン残高合計
     * @type {number}
     * @memberof UserRegistrations
     */
    real_estate_loan_amount?: number | null;
    /**
     * 現在のお住まいマスタデータID
     * @type {number}
     * @memberof UserRegistrations
     */
    current_residence_type_id?: number | null;
    /**
     * 自宅毎月家賃額
     * @type {number}
     * @memberof UserRegistrations
     */
    monthly_rent_payment_amount?: number | null;
    /**
     * 住宅ローン残高合計
     * @type {number}
     * @memberof UserRegistrations
     */
    home_loan_amount?: number | null;
    /**
     * その他ご要望
     * @type {string}
     * @memberof UserRegistrations
     */
    other_request_text?: string | null;
    /**
     * 
     * @type {RegisteredStatusEnum}
     * @memberof UserRegistrations
     */
    data_first_registered_status?: RegisteredStatusEnum;
    /**
     * 不動産会社からの提案希望 false:希望しない true:希望する 
     * @type {boolean}
     * @memberof UserRegistrations
     */
    request_the_proposal: boolean;
    /**
     * 提案の連絡方法 0:メール 1:LINE 
     * @type {number}
     * @memberof UserRegistrations
     */
    way_to_propose?: UserRegistrationsWayToProposeEnum;
    /**
     * 
     * @type {DegreeOfConsiderationEnum}
     * @memberof UserRegistrations
     */
    degree_of_consideration_for_investment?: DegreeOfConsiderationEnum | null;
    /**
     * 
     * @type {DegreeOfConsiderationEnum}
     * @memberof UserRegistrations
     */
    degree_of_consideration_for_sale?: DegreeOfConsiderationEnum | null;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrations
     */
    purpose_of_use_free_text?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserRegistrations
     */
    email_confirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrations
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrations
     */
    password_confirmation: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrations
     */
    last_accessed_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrations
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrations
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrations
     */
    account_updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrations
     */
    discarded_at?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserRegistrations
     */
    phone_number_auth_code: number;
    /**
     * 
     * @type {Array<AssessmentProposal>}
     * @memberof UserRegistrations
     */
    assessment_proposals?: Array<AssessmentProposal>;
    /**
     * 
     * @type {Array<InvestmentProposal>}
     * @memberof UserRegistrations
     */
    investment_proposals?: Array<InvestmentProposal>;
    /**
     * 
     * @type {Array<ProposalMatching>}
     * @memberof UserRegistrations
     */
    proposal_matchings?: Array<ProposalMatching>;
    /**
     * 
     * @type {Array<ContractApplication>}
     * @memberof UserRegistrations
     */
    contract_applications?: Array<ContractApplication>;
    /**
     * 
     * @type {AdminUserMemo}
     * @memberof UserRegistrations
     */
    admin_user_memo?: AdminUserMemo;
    /**
     * 
     * @type {Array<MasterTag>}
     * @memberof UserRegistrations
     */
    master_tags?: Array<MasterTag>;
    /**
     * 関心のあるタグIDリスト
     * @type {Array<number>}
     * @memberof UserRegistrations
     */
    interest_tag_ids?: Array<number>;
    /**
     * 
     * @type {Master}
     * @memberof UserRegistrations
     */
    current_residence_type?: Master | null;
    /**
     * 利用目的IDリスト
     * @type {Array<number>}
     * @memberof UserRegistrations
     */
    purpose_of_use_ids?: Array<number>;
    /**
     * 
     * @type {UserDeviceToken}
     * @memberof UserRegistrations
     */
    user_device_token?: UserDeviceToken;
    /**
     * 
     * @type {Array<UserAccessScreenLogMonthlySummary>}
     * @memberof UserRegistrations
     */
    user_access_screen_log_monthly_summaries?: Array<UserAccessScreenLogMonthlySummary>;
    /**
     * 
     * @type {number}
     * @memberof UserRegistrations
     */
    age?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserRegistrations
     */
    is_new_label?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRegistrations
     */
    is_favorite?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UserRegistrationsWayToProposeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * 
     * @type {number}
     * @memberof UserUpdate
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    identifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    family_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    family_name_kana?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    first_name_kana?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    phone_number?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    birthday?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserUpdate
     */
    income_tax_rate?: number | null;
    /**
     * 
     * @type {JobCategory}
     * @memberof UserUpdate
     */
    job_category?: JobCategory | null;
    /**
     * 住所（都道府県）
     * @type {string}
     * @memberof UserUpdate
     */
    address_prefecture?: string | null;
    /**
     * 
     * @type {JobIndustry}
     * @memberof UserUpdate
     */
    job_industry?: JobIndustry | null;
    /**
     * 
     * @type {Period}
     * @memberof UserUpdate
     */
    job_continuous_period?: Period | null;
    /**
     * 
     * @type {MoneyAmount}
     * @memberof UserUpdate
     */
    financial_asset?: MoneyAmount | null;
    /**
     * 年収
     * @type {number}
     * @memberof UserUpdate
     */
    annual_income?: number | null;
    /**
     * 保有物件数
     * @type {number}
     * @memberof UserUpdate
     */
    holding_property_count?: number | null;
    /**
     * 不動産投資ローン残高合計
     * @type {number}
     * @memberof UserUpdate
     */
    real_estate_loan_amount?: number | null;
    /**
     * 現在のお住まいマスタデータID
     * @type {number}
     * @memberof UserUpdate
     */
    current_residence_type_id?: number | null;
    /**
     * 自宅毎月家賃額
     * @type {number}
     * @memberof UserUpdate
     */
    monthly_rent_payment_amount?: number | null;
    /**
     * 住宅ローン残高合計
     * @type {number}
     * @memberof UserUpdate
     */
    home_loan_amount?: number | null;
    /**
     * その他ご要望
     * @type {string}
     * @memberof UserUpdate
     */
    other_request_text?: string | null;
    /**
     * 
     * @type {RegisteredStatusEnum}
     * @memberof UserUpdate
     */
    data_first_registered_status?: RegisteredStatusEnum;
    /**
     * 不動産会社からの提案希望 false:希望しない true:希望する 
     * @type {boolean}
     * @memberof UserUpdate
     */
    request_the_proposal?: boolean;
    /**
     * 提案の連絡方法 0:メール 1:LINE 
     * @type {number}
     * @memberof UserUpdate
     */
    way_to_propose?: UserUpdateWayToProposeEnum;
    /**
     * 
     * @type {DegreeOfConsiderationEnum}
     * @memberof UserUpdate
     */
    degree_of_consideration_for_investment?: DegreeOfConsiderationEnum | null;
    /**
     * 
     * @type {DegreeOfConsiderationEnum}
     * @memberof UserUpdate
     */
    degree_of_consideration_for_sale?: DegreeOfConsiderationEnum | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    purpose_of_use_free_text?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdate
     */
    email_confirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    password_confirmation?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    last_accessed_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    account_updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    discarded_at?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserUpdate
     */
    phone_number_auth_code?: number;
    /**
     * 
     * @type {Array<AssessmentProposal>}
     * @memberof UserUpdate
     */
    assessment_proposals?: Array<AssessmentProposal>;
    /**
     * 
     * @type {Array<InvestmentProposal>}
     * @memberof UserUpdate
     */
    investment_proposals?: Array<InvestmentProposal>;
    /**
     * 
     * @type {Array<ProposalMatching>}
     * @memberof UserUpdate
     */
    proposal_matchings?: Array<ProposalMatching>;
    /**
     * 
     * @type {Array<ContractApplication>}
     * @memberof UserUpdate
     */
    contract_applications?: Array<ContractApplication>;
    /**
     * 
     * @type {AdminUserMemo}
     * @memberof UserUpdate
     */
    admin_user_memo?: AdminUserMemo;
    /**
     * 
     * @type {Array<MasterTag>}
     * @memberof UserUpdate
     */
    master_tags?: Array<MasterTag>;
    /**
     * 関心のあるタグIDリスト
     * @type {Array<number>}
     * @memberof UserUpdate
     */
    interest_tag_ids?: Array<number>;
    /**
     * 
     * @type {Master}
     * @memberof UserUpdate
     */
    current_residence_type?: Master | null;
    /**
     * 利用目的IDリスト
     * @type {Array<number>}
     * @memberof UserUpdate
     */
    purpose_of_use_ids?: Array<number>;
    /**
     * 
     * @type {UserDeviceToken}
     * @memberof UserUpdate
     */
    user_device_token?: UserDeviceToken;
    /**
     * 
     * @type {Array<UserAccessScreenLogMonthlySummary>}
     * @memberof UserUpdate
     */
    user_access_screen_log_monthly_summaries?: Array<UserAccessScreenLogMonthlySummary>;
    /**
     * 
     * @type {number}
     * @memberof UserUpdate
     */
    age?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdate
     */
    is_new_label?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdate
     */
    is_favorite?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UserUpdateWayToProposeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}


/**
 * PropallyV1Api - axios parameter creator
 * @export
 */
export const PropallyV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ヘルスチェックエンドポイント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthzGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthz`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 査定提案未読数取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AssessmentProposalUnreadCountGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/assessment_proposal_unread_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 査定提案承認or否承認実行
         * @param {number} assessmentProposalId 査定提案 ID
         * @param {ProposalApprovedRequest} proposalApprovedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AssessmentProposalsAssessmentProposalIdApprovedPatch: async (assessmentProposalId: number, proposalApprovedRequest: ProposalApprovedRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assessmentProposalId' is not null or undefined
            if (assessmentProposalId === null || assessmentProposalId === undefined) {
                throw new RequiredError('assessmentProposalId','Required parameter assessmentProposalId was null or undefined when calling v1AssessmentProposalsAssessmentProposalIdApprovedPatch.');
            }
            // verify required parameter 'proposalApprovedRequest' is not null or undefined
            if (proposalApprovedRequest === null || proposalApprovedRequest === undefined) {
                throw new RequiredError('proposalApprovedRequest','Required parameter proposalApprovedRequest was null or undefined when calling v1AssessmentProposalsAssessmentProposalIdApprovedPatch.');
            }
            const localVarPath = `/v1/assessment_proposals/{assessment_proposal_id}/approved`
                .replace(`{${"assessment_proposal_id"}}`, encodeURIComponent(String(assessmentProposalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof proposalApprovedRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(proposalApprovedRequest !== undefined ? proposalApprovedRequest : {})
                : (proposalApprovedRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary エージェント・企業情報を含む査定提案詳細1件取得
         * @param {number} assessmentProposalId 査定提案 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AssessmentProposalsAssessmentProposalIdGet: async (assessmentProposalId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assessmentProposalId' is not null or undefined
            if (assessmentProposalId === null || assessmentProposalId === undefined) {
                throw new RequiredError('assessmentProposalId','Required parameter assessmentProposalId was null or undefined when calling v1AssessmentProposalsAssessmentProposalIdGet.');
            }
            const localVarPath = `/v1/assessment_proposals/{assessment_proposal_id}`
                .replace(`{${"assessment_proposal_id"}}`, encodeURIComponent(String(assessmentProposalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 査定提案既読更新
         * @param {number} assessmentProposalId 査定提案 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AssessmentProposalsAssessmentProposalIdReadPatch: async (assessmentProposalId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assessmentProposalId' is not null or undefined
            if (assessmentProposalId === null || assessmentProposalId === undefined) {
                throw new RequiredError('assessmentProposalId','Required parameter assessmentProposalId was null or undefined when calling v1AssessmentProposalsAssessmentProposalIdReadPatch.');
            }
            const localVarPath = `/v1/assessment_proposals/{assessment_proposal_id}/read`
                .replace(`{${"assessment_proposal_id"}}`, encodeURIComponent(String(assessmentProposalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 査定提案一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AssessmentProposalsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/assessment_proposals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メールアドレス確認
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EmailConfirmationsPost: async (inlineObject1: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject1' is not null or undefined
            if (inlineObject1 === null || inlineObject1 === undefined) {
                throw new RequiredError('inlineObject1','Required parameter inlineObject1 was null or undefined when calling v1EmailConfirmationsPost.');
            }
            const localVarPath = `/v1/email_confirmations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject1 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject1 !== undefined ? inlineObject1 : {})
                : (inlineObject1 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary HowMa(外部API)物件詳細(部屋情報一覧)API
         * @param {number} buildingId (HowMa管理の)マンションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1HowmaBuildingsBuildingIdGet: async (buildingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            if (buildingId === null || buildingId === undefined) {
                throw new RequiredError('buildingId','Required parameter buildingId was null or undefined when calling v1HowmaBuildingsBuildingIdGet.');
            }
            const localVarPath = `/v1/howma/buildings/{building_id}`
                .replace(`{${"building_id"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary HowMa(外部API)物件検索API
         * @param {HowMaBuildingsSearchRequest} howMaBuildingsSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1HowmaBuildingsPost: async (howMaBuildingsSearchRequest: HowMaBuildingsSearchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'howMaBuildingsSearchRequest' is not null or undefined
            if (howMaBuildingsSearchRequest === null || howMaBuildingsSearchRequest === undefined) {
                throw new RequiredError('howMaBuildingsSearchRequest','Required parameter howMaBuildingsSearchRequest was null or undefined when calling v1HowmaBuildingsPost.');
            }
            const localVarPath = `/v1/howma/buildings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof howMaBuildingsSearchRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(howMaBuildingsSearchRequest !== undefined ? howMaBuildingsSearchRequest : {})
                : (howMaBuildingsSearchRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 投資提案未読数取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvestmentProposalUnreadCountGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/investment_proposal_unread_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 投資提案一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvestmentProposalsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/investment_proposals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 投資提案承認or否承認実行
         * @param {number} investmentProposalId 投資提案 ID
         * @param {ProposalApprovedRequest} proposalApprovedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvestmentProposalsInvestmentProposalIdApprovedPatch: async (investmentProposalId: number, proposalApprovedRequest: ProposalApprovedRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentProposalId' is not null or undefined
            if (investmentProposalId === null || investmentProposalId === undefined) {
                throw new RequiredError('investmentProposalId','Required parameter investmentProposalId was null or undefined when calling v1InvestmentProposalsInvestmentProposalIdApprovedPatch.');
            }
            // verify required parameter 'proposalApprovedRequest' is not null or undefined
            if (proposalApprovedRequest === null || proposalApprovedRequest === undefined) {
                throw new RequiredError('proposalApprovedRequest','Required parameter proposalApprovedRequest was null or undefined when calling v1InvestmentProposalsInvestmentProposalIdApprovedPatch.');
            }
            const localVarPath = `/v1/investment_proposals/{investment_proposal_id}/approved`
                .replace(`{${"investment_proposal_id"}}`, encodeURIComponent(String(investmentProposalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof proposalApprovedRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(proposalApprovedRequest !== undefined ? proposalApprovedRequest : {})
                : (proposalApprovedRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 投資提案詳細1件取得
         * @param {number} investmentProposalId 投資提案 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvestmentProposalsInvestmentProposalIdGet: async (investmentProposalId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentProposalId' is not null or undefined
            if (investmentProposalId === null || investmentProposalId === undefined) {
                throw new RequiredError('investmentProposalId','Required parameter investmentProposalId was null or undefined when calling v1InvestmentProposalsInvestmentProposalIdGet.');
            }
            const localVarPath = `/v1/investment_proposals/{investment_proposal_id}`
                .replace(`{${"investment_proposal_id"}}`, encodeURIComponent(String(investmentProposalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 投資提案既読更新
         * @param {number} investmentProposalId 投資提案 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvestmentProposalsInvestmentProposalIdReadPatch: async (investmentProposalId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'investmentProposalId' is not null or undefined
            if (investmentProposalId === null || investmentProposalId === undefined) {
                throw new RequiredError('investmentProposalId','Required parameter investmentProposalId was null or undefined when calling v1InvestmentProposalsInvestmentProposalIdReadPatch.');
            }
            const localVarPath = `/v1/investment_proposals/{investment_proposal_id}/read`
                .replace(`{${"investment_proposal_id"}}`, encodeURIComponent(String(investmentProposalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ログイン
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LoginPost: async (inlineObject: InlineObject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject' is not null or undefined
            if (inlineObject === null || inlineObject === undefined) {
                throw new RequiredError('inlineObject','Required parameter inlineObject was null or undefined when calling v1LoginPost.');
            }
            const localVarPath = `/v1/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject !== undefined ? inlineObject : {})
                : (inlineObject || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LogoutPost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 都道府県一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MasterDataPrefecturesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/master_data/prefectures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 町丁目一覧
         * @param {string} prefectureName 都道府県名
         * @param {string} cityName 市区町村名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MasterDataPrefecturesPrefectureNameCitiesCityNameTownsGet: async (prefectureName: string, cityName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prefectureName' is not null or undefined
            if (prefectureName === null || prefectureName === undefined) {
                throw new RequiredError('prefectureName','Required parameter prefectureName was null or undefined when calling v1MasterDataPrefecturesPrefectureNameCitiesCityNameTownsGet.');
            }
            // verify required parameter 'cityName' is not null or undefined
            if (cityName === null || cityName === undefined) {
                throw new RequiredError('cityName','Required parameter cityName was null or undefined when calling v1MasterDataPrefecturesPrefectureNameCitiesCityNameTownsGet.');
            }
            const localVarPath = `/v1/master_data/prefectures/{prefecture_name}/cities/{city_name}/towns`
                .replace(`{${"prefecture_name"}}`, encodeURIComponent(String(prefectureName)))
                .replace(`{${"city_name"}}`, encodeURIComponent(String(cityName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 市区町村一覧
         * @param {string} prefectureName 都道府県名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MasterDataPrefecturesPrefectureNameCitiesGet: async (prefectureName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prefectureName' is not null or undefined
            if (prefectureName === null || prefectureName === undefined) {
                throw new RequiredError('prefectureName','Required parameter prefectureName was null or undefined when calling v1MasterDataPrefecturesPrefectureNameCitiesGet.');
            }
            const localVarPath = `/v1/master_data/prefectures/{prefecture_name}/cities`
                .replace(`{${"prefecture_name"}}`, encodeURIComponent(String(prefectureName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 利用目的マスタ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MasterDataPurposeOfUsesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/master_data/purpose_of_uses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 鉄道一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MasterDataRailwaysGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/master_data/railways`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 駅名一覧
         * @param {string} railwayName 鉄道名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MasterDataRailwaysRailwayNameStationsGet: async (railwayName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'railwayName' is not null or undefined
            if (railwayName === null || railwayName === undefined) {
                throw new RequiredError('railwayName','Required parameter railwayName was null or undefined when calling v1MasterDataRailwaysRailwayNameStationsGet.');
            }
            const localVarPath = `/v1/master_data/railways/{railway_name}/stations`
                .replace(`{${"railway_name"}}`, encodeURIComponent(String(railwayName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 居住タイプマスタ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MasterDataResidenceTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/master_data/residence_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary タグマスタ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MasterDataTagsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/master_data/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary リセット後、新しいパスワードの登録
         * @param {InlineObject4} inlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NewPasswordsPost: async (inlineObject4: InlineObject4, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject4' is not null or undefined
            if (inlineObject4 === null || inlineObject4 === undefined) {
                throw new RequiredError('inlineObject4','Required parameter inlineObject4 was null or undefined when calling v1NewPasswordsPost.');
            }
            const localVarPath = `/v1/new_passwords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject4 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject4 !== undefined ? inlineObject4 : {})
                : (inlineObject4 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 初回物件登録時エージェント通知
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsToAgentForNewPropertyPost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/notifications/to_agent/for_new_property`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザSTEP1登録時エージェント通知
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsToAgentForNewUserPost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/notifications/to_agent/for_new_user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary パスワードリセット
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PasswordResetsPost: async (inlineObject3: InlineObject3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject3' is not null or undefined
            if (inlineObject3 === null || inlineObject3 === undefined) {
                throw new RequiredError('inlineObject3','Required parameter inlineObject3 was null or undefined when calling v1PasswordResetsPost.');
            }
            const localVarPath = `/v1/password_resets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject3 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject3 !== undefined ? inlineObject3 : {})
                : (inlineObject3 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件登録
         * @param {PropertyBuilder} propertyBuilder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPost: async (propertyBuilder: PropertyBuilder, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyBuilder' is not null or undefined
            if (propertyBuilder === null || propertyBuilder === undefined) {
                throw new RequiredError('propertyBuilder','Required parameter propertyBuilder was null or undefined when calling v1PropertiesPost.');
            }
            const localVarPath = `/v1/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof propertyBuilder !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(propertyBuilder !== undefined ? propertyBuilder : {})
                : (propertyBuilder || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件削除
         * @param {number} propertyId 物件 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdDelete: async (propertyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdDelete.');
            }
            const localVarPath = `/v1/properties/{property_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件に紐づくドキュメントの削除
         * @param {number} propertyId 物件 ID
         * @param {DocumentType} documentType 
         * @param {number} fileId ファイル ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdDelete: async (propertyId: number, documentType: DocumentType, fileId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdDelete.');
            }
            // verify required parameter 'documentType' is not null or undefined
            if (documentType === null || documentType === undefined) {
                throw new RequiredError('documentType','Required parameter documentType was null or undefined when calling v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdDelete.');
            }
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdDelete.');
            }
            const localVarPath = `/v1/properties/{property_id}/documents/{document_type}/files/{file_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"document_type"}}`, encodeURIComponent(String(documentType)))
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件に紐づくドキュメントのファイルバイナリ取得
         * @param {number} propertyId 物件 ID
         * @param {DocumentType} documentType 
         * @param {number} fileId ファイル ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdGet: async (propertyId: number, documentType: DocumentType, fileId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdGet.');
            }
            // verify required parameter 'documentType' is not null or undefined
            if (documentType === null || documentType === undefined) {
                throw new RequiredError('documentType','Required parameter documentType was null or undefined when calling v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdGet.');
            }
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdGet.');
            }
            const localVarPath = `/v1/properties/{property_id}/documents/{document_type}/files/{file_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"document_type"}}`, encodeURIComponent(String(documentType)))
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ドキュメント更新
         * @param {number} propertyId 物件 ID
         * @param {DocumentType} documentType 
         * @param {number} fileId ファイル ID
         * @param {DocumentForm} documentForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdPatch: async (propertyId: number, documentType: DocumentType, fileId: number, documentForm: DocumentForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdPatch.');
            }
            // verify required parameter 'documentType' is not null or undefined
            if (documentType === null || documentType === undefined) {
                throw new RequiredError('documentType','Required parameter documentType was null or undefined when calling v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdPatch.');
            }
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdPatch.');
            }
            // verify required parameter 'documentForm' is not null or undefined
            if (documentForm === null || documentForm === undefined) {
                throw new RequiredError('documentForm','Required parameter documentForm was null or undefined when calling v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdPatch.');
            }
            const localVarPath = `/v1/properties/{property_id}/documents/{document_type}/files/{file_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"document_type"}}`, encodeURIComponent(String(documentType)))
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof documentForm !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(documentForm !== undefined ? documentForm : {})
                : (documentForm || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件に紐づくドキュメントの一覧取得
         * @param {number} propertyId 物件 ID
         * @param {DocumentType} documentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdDocumentsDocumentTypeFilesGet: async (propertyId: number, documentType: DocumentType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdDocumentsDocumentTypeFilesGet.');
            }
            // verify required parameter 'documentType' is not null or undefined
            if (documentType === null || documentType === undefined) {
                throw new RequiredError('documentType','Required parameter documentType was null or undefined when calling v1PropertiesPropertyIdDocumentsDocumentTypeFilesGet.');
            }
            const localVarPath = `/v1/properties/{property_id}/documents/{document_type}/files`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"document_type"}}`, encodeURIComponent(String(documentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件に紐づくドキュメントの登録
         * @param {number} propertyId 物件 ID
         * @param {DocumentType} documentType 
         * @param {Base64EncodedImage} base64EncodedImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdDocumentsDocumentTypeFilesPost: async (propertyId: number, documentType: DocumentType, base64EncodedImage: Base64EncodedImage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdDocumentsDocumentTypeFilesPost.');
            }
            // verify required parameter 'documentType' is not null or undefined
            if (documentType === null || documentType === undefined) {
                throw new RequiredError('documentType','Required parameter documentType was null or undefined when calling v1PropertiesPropertyIdDocumentsDocumentTypeFilesPost.');
            }
            // verify required parameter 'base64EncodedImage' is not null or undefined
            if (base64EncodedImage === null || base64EncodedImage === undefined) {
                throw new RequiredError('base64EncodedImage','Required parameter base64EncodedImage was null or undefined when calling v1PropertiesPropertyIdDocumentsDocumentTypeFilesPost.');
            }
            const localVarPath = `/v1/properties/{property_id}/documents/{document_type}/files`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"document_type"}}`, encodeURIComponent(String(documentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof base64EncodedImage !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(base64EncodedImage !== undefined ? base64EncodedImage : {})
                : (base64EncodedImage || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件に紐づく画像の削除
         * @param {number} propertyId 物件 ID
         * @param {number} imageId 画像 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdImagesImageIdDelete: async (propertyId: number, imageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdImagesImageIdDelete.');
            }
            // verify required parameter 'imageId' is not null or undefined
            if (imageId === null || imageId === undefined) {
                throw new RequiredError('imageId','Required parameter imageId was null or undefined when calling v1PropertiesPropertyIdImagesImageIdDelete.');
            }
            const localVarPath = `/v1/properties/{property_id}/images/{image_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"image_id"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件に紐づく画像ごとの URL 取得
         * @param {number} propertyId 物件 ID
         * @param {number} imageId 画像 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdImagesImageIdUrlGet: async (propertyId: number, imageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdImagesImageIdUrlGet.');
            }
            // verify required parameter 'imageId' is not null or undefined
            if (imageId === null || imageId === undefined) {
                throw new RequiredError('imageId','Required parameter imageId was null or undefined when calling v1PropertiesPropertyIdImagesImageIdUrlGet.');
            }
            const localVarPath = `/v1/properties/{property_id}/images/{image_id}/url`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"image_id"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件ごとの画像登録
         * @param {number} propertyId 物件 ID
         * @param {Base64EncodedImage} base64EncodedImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdImagesPost: async (propertyId: number, base64EncodedImage: Base64EncodedImage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdImagesPost.');
            }
            // verify required parameter 'base64EncodedImage' is not null or undefined
            if (base64EncodedImage === null || base64EncodedImage === undefined) {
                throw new RequiredError('base64EncodedImage','Required parameter base64EncodedImage was null or undefined when calling v1PropertiesPropertyIdImagesPost.');
            }
            const localVarPath = `/v1/properties/{property_id}/images`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof base64EncodedImage !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(base64EncodedImage !== undefined ? base64EncodedImage : {})
                : (base64EncodedImage || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件ごとの賃貸契約取得
         * @param {number} propertyId 物件 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdLeaseContractsGet: async (propertyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdLeaseContractsGet.');
            }
            const localVarPath = `/v1/properties/{property_id}/lease_contracts`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件ごとの賃貸契約削除
         * @param {number} propertyId 物件 ID
         * @param {number} leaseContractId 賃貸契約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdLeaseContractsLeaseContractIdDelete: async (propertyId: number, leaseContractId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdLeaseContractsLeaseContractIdDelete.');
            }
            // verify required parameter 'leaseContractId' is not null or undefined
            if (leaseContractId === null || leaseContractId === undefined) {
                throw new RequiredError('leaseContractId','Required parameter leaseContractId was null or undefined when calling v1PropertiesPropertyIdLeaseContractsLeaseContractIdDelete.');
            }
            const localVarPath = `/v1/properties/{property_id}/lease_contracts/{lease_contract_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"lease_contract_id"}}`, encodeURIComponent(String(leaseContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件ごとの賃貸契約更新
         * @param {number} propertyId 物件 ID
         * @param {number} leaseContractId 賃貸契約ID
         * @param {LeaseContract} leaseContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdLeaseContractsLeaseContractIdPatch: async (propertyId: number, leaseContractId: number, leaseContract: LeaseContract, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdLeaseContractsLeaseContractIdPatch.');
            }
            // verify required parameter 'leaseContractId' is not null or undefined
            if (leaseContractId === null || leaseContractId === undefined) {
                throw new RequiredError('leaseContractId','Required parameter leaseContractId was null or undefined when calling v1PropertiesPropertyIdLeaseContractsLeaseContractIdPatch.');
            }
            // verify required parameter 'leaseContract' is not null or undefined
            if (leaseContract === null || leaseContract === undefined) {
                throw new RequiredError('leaseContract','Required parameter leaseContract was null or undefined when calling v1PropertiesPropertyIdLeaseContractsLeaseContractIdPatch.');
            }
            const localVarPath = `/v1/properties/{property_id}/lease_contracts/{lease_contract_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"lease_contract_id"}}`, encodeURIComponent(String(leaseContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof leaseContract !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(leaseContract !== undefined ? leaseContract : {})
                : (leaseContract || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件ごとの賃貸契約登録
         * @param {number} propertyId 物件 ID
         * @param {LeaseContract} leaseContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdLeaseContractsPost: async (propertyId: number, leaseContract: LeaseContract, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdLeaseContractsPost.');
            }
            // verify required parameter 'leaseContract' is not null or undefined
            if (leaseContract === null || leaseContract === undefined) {
                throw new RequiredError('leaseContract','Required parameter leaseContract was null or undefined when calling v1PropertiesPropertyIdLeaseContractsPost.');
            }
            const localVarPath = `/v1/properties/{property_id}/lease_contracts`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof leaseContract !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(leaseContract !== undefined ? leaseContract : {})
                : (leaseContract || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 月毎の（ユーザ定義による）実績一覧
         * @param {number} propertyId 物件 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdMonthlyCashflowResultsGet: async (propertyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdMonthlyCashflowResultsGet.');
            }
            const localVarPath = `/v1/properties/{property_id}/monthly_cashflow_results`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 月毎の（ユーザ定義による）実績登録
         * @param {number} propertyId 物件 ID
         * @param {MonthlyCashflowResultUpdate} monthlyCashflowResultUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdMonthlyCashflowResultsPatch: async (propertyId: number, monthlyCashflowResultUpdate: MonthlyCashflowResultUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdMonthlyCashflowResultsPatch.');
            }
            // verify required parameter 'monthlyCashflowResultUpdate' is not null or undefined
            if (monthlyCashflowResultUpdate === null || monthlyCashflowResultUpdate === undefined) {
                throw new RequiredError('monthlyCashflowResultUpdate','Required parameter monthlyCashflowResultUpdate was null or undefined when calling v1PropertiesPropertyIdMonthlyCashflowResultsPatch.');
            }
            const localVarPath = `/v1/properties/{property_id}/monthly_cashflow_results`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof monthlyCashflowResultUpdate !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(monthlyCashflowResultUpdate !== undefined ? monthlyCashflowResultUpdate : {})
                : (monthlyCashflowResultUpdate || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件に紐づく周辺情報(不動価格)取得
         * @param {number} propertyId 物件 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdNearbyPricesGet: async (propertyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdNearbyPricesGet.');
            }
            const localVarPath = `/v1/properties/{property_id}/nearby/prices`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件更新
         * @param {number} propertyId 物件 ID
         * @param {Property} property 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdPatch: async (propertyId: number, property: Property, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdPatch.');
            }
            // verify required parameter 'property' is not null or undefined
            if (property === null || property === undefined) {
                throw new RequiredError('property','Required parameter property was null or undefined when calling v1PropertiesPropertyIdPatch.');
            }
            const localVarPath = `/v1/properties/{property_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof property !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(property !== undefined ? property : {})
                : (property || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件ごとのシミュレーション設定
         * @param {number} propertyId 物件 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdSimulationConfigsGet: async (propertyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdSimulationConfigsGet.');
            }
            const localVarPath = `/v1/properties/{property_id}/simulation_configs`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件ごとのシミュレーション設定登録
         * @param {number} propertyId 物件 ID
         * @param {SimulationConfig} simulationConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdSimulationConfigsPost: async (propertyId: number, simulationConfig: SimulationConfig, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdSimulationConfigsPost.');
            }
            // verify required parameter 'simulationConfig' is not null or undefined
            if (simulationConfig === null || simulationConfig === undefined) {
                throw new RequiredError('simulationConfig','Required parameter simulationConfig was null or undefined when calling v1PropertiesPropertyIdSimulationConfigsPost.');
            }
            const localVarPath = `/v1/properties/{property_id}/simulation_configs`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof simulationConfig !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(simulationConfig !== undefined ? simulationConfig : {})
                : (simulationConfig || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件ごとのシミュレーション設定の削除
         * @param {number} propertyId 物件 ID
         * @param {number} simulationConfigId シミュレーション設定 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdDelete: async (propertyId: number, simulationConfigId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdDelete.');
            }
            // verify required parameter 'simulationConfigId' is not null or undefined
            if (simulationConfigId === null || simulationConfigId === undefined) {
                throw new RequiredError('simulationConfigId','Required parameter simulationConfigId was null or undefined when calling v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdDelete.');
            }
            const localVarPath = `/v1/properties/{property_id}/simulation_configs/{simulation_config_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"simulation_config_id"}}`, encodeURIComponent(String(simulationConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件ごとのシミュレーション設定の更新
         * @param {number} propertyId 物件 ID
         * @param {number} simulationConfigId シミュレーション設定 ID
         * @param {SimulationConfig} simulationConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdPatch: async (propertyId: number, simulationConfigId: number, simulationConfig: SimulationConfig, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdPatch.');
            }
            // verify required parameter 'simulationConfigId' is not null or undefined
            if (simulationConfigId === null || simulationConfigId === undefined) {
                throw new RequiredError('simulationConfigId','Required parameter simulationConfigId was null or undefined when calling v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdPatch.');
            }
            // verify required parameter 'simulationConfig' is not null or undefined
            if (simulationConfig === null || simulationConfig === undefined) {
                throw new RequiredError('simulationConfig','Required parameter simulationConfig was null or undefined when calling v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdPatch.');
            }
            const localVarPath = `/v1/properties/{property_id}/simulation_configs/{simulation_config_id}`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"simulation_config_id"}}`, encodeURIComponent(String(simulationConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof simulationConfig !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(simulationConfig !== undefined ? simulationConfig : {})
                : (simulationConfig || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件ごとのシミュレーション設定における修繕積立金の金額変動の更新
         * @param {number} propertyId 物件 ID
         * @param {number} simulationConfigId シミュレーション設定 ID
         * @param {InlineObject5} inlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdReserveFundPriceChangesPost: async (propertyId: number, simulationConfigId: number, inlineObject5: InlineObject5, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            if (propertyId === null || propertyId === undefined) {
                throw new RequiredError('propertyId','Required parameter propertyId was null or undefined when calling v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdReserveFundPriceChangesPost.');
            }
            // verify required parameter 'simulationConfigId' is not null or undefined
            if (simulationConfigId === null || simulationConfigId === undefined) {
                throw new RequiredError('simulationConfigId','Required parameter simulationConfigId was null or undefined when calling v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdReserveFundPriceChangesPost.');
            }
            // verify required parameter 'inlineObject5' is not null or undefined
            if (inlineObject5 === null || inlineObject5 === undefined) {
                throw new RequiredError('inlineObject5','Required parameter inlineObject5 was null or undefined when calling v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdReserveFundPriceChangesPost.');
            }
            const localVarPath = `/v1/properties/{property_id}/simulation_configs/{simulation_config_id}/reserve_fund_price_changes`
                .replace(`{${"property_id"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"simulation_config_id"}}`, encodeURIComponent(String(simulationConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject5 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject5 !== undefined ? inlineObject5 : {})
                : (inlineObject5 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件一時保存状態削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertyTemporaryDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/property_temporary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件一時保存状態取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertyTemporaryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/property_temporary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 物件一時保存状態登録・更新
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertyTemporaryPost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/property_temporary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary マッチング登録
         * @param {ProposalMatchingRequest} proposalMatchingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProposalMatchingsPost: async (proposalMatchingRequest: ProposalMatchingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'proposalMatchingRequest' is not null or undefined
            if (proposalMatchingRequest === null || proposalMatchingRequest === undefined) {
                throw new RequiredError('proposalMatchingRequest','Required parameter proposalMatchingRequest was null or undefined when calling v1ProposalMatchingsPost.');
            }
            const localVarPath = `/v1/proposal_matchings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof proposalMatchingRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(proposalMatchingRequest !== undefined ? proposalMatchingRequest : {})
                : (proposalMatchingRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー登録
         * @param {UserRegistrations} userRegistrations 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RegistrationsPost: async (userRegistrations: UserRegistrations, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRegistrations' is not null or undefined
            if (userRegistrations === null || userRegistrations === undefined) {
                throw new RequiredError('userRegistrations','Required parameter userRegistrations was null or undefined when calling v1RegistrationsPost.');
            }
            const localVarPath = `/v1/registrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userRegistrations !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userRegistrations !== undefined ? userRegistrations : {})
                : (userRegistrations || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 電話番号認証コード送信
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SendPhoneNumberAuthCodesPost: async (inlineObject2: InlineObject2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject2' is not null or undefined
            if (inlineObject2 === null || inlineObject2 === undefined) {
                throw new RequiredError('inlineObject2','Required parameter inlineObject2 was null or undefined when calling v1SendPhoneNumberAuthCodesPost.');
            }
            const localVarPath = `/v1/send_phone_number_auth_codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject2 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject2 !== undefined ? inlineObject2 : {})
                : (inlineObject2 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザ画面アクセス登録
         * @param {UserAccessScreenLog} userAccessScreenLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserAccessScreenLogsPost: async (userAccessScreenLog: UserAccessScreenLog, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAccessScreenLog' is not null or undefined
            if (userAccessScreenLog === null || userAccessScreenLog === undefined) {
                throw new RequiredError('userAccessScreenLog','Required parameter userAccessScreenLog was null or undefined when calling v1UserAccessScreenLogsPost.');
            }
            const localVarPath = `/v1/user_access_screen_logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userAccessScreenLog !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userAccessScreenLog !== undefined ? userAccessScreenLog : {})
                : (userAccessScreenLog || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザデータ初回登録ステータス取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserDataFirstRegisteredStatusGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/data_first_registered_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザデータ初回登録ステータス更新
         * @param {UserDataFirstRegisteredStatus} userDataFirstRegisteredStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserDataFirstRegisteredStatusPatch: async (userDataFirstRegisteredStatus: UserDataFirstRegisteredStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDataFirstRegisteredStatus' is not null or undefined
            if (userDataFirstRegisteredStatus === null || userDataFirstRegisteredStatus === undefined) {
                throw new RequiredError('userDataFirstRegisteredStatus','Required parameter userDataFirstRegisteredStatus was null or undefined when calling v1UserDataFirstRegisteredStatusPatch.');
            }
            const localVarPath = `/v1/user/data_first_registered_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userDataFirstRegisteredStatus !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userDataFirstRegisteredStatus !== undefined ? userDataFirstRegisteredStatus : {})
                : (userDataFirstRegisteredStatus || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーデバイストークン登録削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserDeviceTokenDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user_device_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーデバイストークン登録
         * @param {UserDeviceToken} userDeviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserDeviceTokenPost: async (userDeviceToken: UserDeviceToken, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDeviceToken' is not null or undefined
            if (userDeviceToken === null || userDeviceToken === undefined) {
                throw new RequiredError('userDeviceToken','Required parameter userDeviceToken was null or undefined when calling v1UserDeviceTokenPost.');
            }
            const localVarPath = `/v1/user_device_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userDeviceToken !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userDeviceToken !== undefined ? userDeviceToken : {})
                : (userDeviceToken || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー情報更新
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserPatch: async (userUpdate: UserUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUpdate' is not null or undefined
            if (userUpdate === null || userUpdate === undefined) {
                throw new RequiredError('userUpdate','Required parameter userUpdate was null or undefined when calling v1UserPatch.');
            }
            const localVarPath = `/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userUpdate !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userUpdate !== undefined ? userUpdate : {})
                : (userUpdate || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropallyV1Api - functional programming interface
 * @export
 */
export const PropallyV1ApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ヘルスチェックエンドポイント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthzGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).healthzGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 査定提案未読数取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AssessmentProposalUnreadCountGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentProposalUnreadCountResponse>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1AssessmentProposalUnreadCountGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 査定提案承認or否承認実行
         * @param {number} assessmentProposalId 査定提案 ID
         * @param {ProposalApprovedRequest} proposalApprovedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AssessmentProposalsAssessmentProposalIdApprovedPatch(assessmentProposalId: number, proposalApprovedRequest: ProposalApprovedRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1AssessmentProposalsAssessmentProposalIdApprovedPatch(assessmentProposalId, proposalApprovedRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary エージェント・企業情報を含む査定提案詳細1件取得
         * @param {number} assessmentProposalId 査定提案 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AssessmentProposalsAssessmentProposalIdGet(assessmentProposalId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentProposal>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1AssessmentProposalsAssessmentProposalIdGet(assessmentProposalId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 査定提案既読更新
         * @param {number} assessmentProposalId 査定提案 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AssessmentProposalsAssessmentProposalIdReadPatch(assessmentProposalId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1AssessmentProposalsAssessmentProposalIdReadPatch(assessmentProposalId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 査定提案一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AssessmentProposalsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssessmentProposal>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1AssessmentProposalsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary メールアドレス確認
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1EmailConfirmationsPost(inlineObject1: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1EmailConfirmationsPost(inlineObject1, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary HowMa(外部API)物件詳細(部屋情報一覧)API
         * @param {number} buildingId (HowMa管理の)マンションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1HowmaBuildingsBuildingIdGet(buildingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HowMaBuildingDetailResponse>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1HowmaBuildingsBuildingIdGet(buildingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary HowMa(外部API)物件検索API
         * @param {HowMaBuildingsSearchRequest} howMaBuildingsSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1HowmaBuildingsPost(howMaBuildingsSearchRequest: HowMaBuildingsSearchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HowMaBuildingsResponse>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1HowmaBuildingsPost(howMaBuildingsSearchRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 投資提案未読数取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvestmentProposalUnreadCountGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentProposalUnreadCountResponse>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1InvestmentProposalUnreadCountGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 投資提案一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvestmentProposalsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvestmentProposal>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1InvestmentProposalsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 投資提案承認or否承認実行
         * @param {number} investmentProposalId 投資提案 ID
         * @param {ProposalApprovedRequest} proposalApprovedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvestmentProposalsInvestmentProposalIdApprovedPatch(investmentProposalId: number, proposalApprovedRequest: ProposalApprovedRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1InvestmentProposalsInvestmentProposalIdApprovedPatch(investmentProposalId, proposalApprovedRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 投資提案詳細1件取得
         * @param {number} investmentProposalId 投資提案 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvestmentProposalsInvestmentProposalIdGet(investmentProposalId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvestmentProposal>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1InvestmentProposalsInvestmentProposalIdGet(investmentProposalId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 投資提案既読更新
         * @param {number} investmentProposalId 投資提案 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvestmentProposalsInvestmentProposalIdReadPatch(investmentProposalId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1InvestmentProposalsInvestmentProposalIdReadPatch(investmentProposalId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ログイン
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LoginPost(inlineObject: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1LoginPost(inlineObject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LogoutPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1LogoutPost(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 都道府県一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MasterDataPrefecturesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1MasterDataPrefecturesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 町丁目一覧
         * @param {string} prefectureName 都道府県名
         * @param {string} cityName 市区町村名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MasterDataPrefecturesPrefectureNameCitiesCityNameTownsGet(prefectureName: string, cityName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1MasterDataPrefecturesPrefectureNameCitiesCityNameTownsGet(prefectureName, cityName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 市区町村一覧
         * @param {string} prefectureName 都道府県名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MasterDataPrefecturesPrefectureNameCitiesGet(prefectureName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1MasterDataPrefecturesPrefectureNameCitiesGet(prefectureName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 利用目的マスタ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MasterDataPurposeOfUsesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdName>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1MasterDataPurposeOfUsesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 鉄道一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MasterDataRailwaysGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdName>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1MasterDataRailwaysGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 駅名一覧
         * @param {string} railwayName 鉄道名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MasterDataRailwaysRailwayNameStationsGet(railwayName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1MasterDataRailwaysRailwayNameStationsGet(railwayName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 居住タイプマスタ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MasterDataResidenceTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdName>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1MasterDataResidenceTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary タグマスタ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1MasterDataTagsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdName>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1MasterDataTagsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary リセット後、新しいパスワードの登録
         * @param {InlineObject4} inlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NewPasswordsPost(inlineObject4: InlineObject4, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1NewPasswordsPost(inlineObject4, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 初回物件登録時エージェント通知
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NotificationsToAgentForNewPropertyPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1NotificationsToAgentForNewPropertyPost(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ユーザSTEP1登録時エージェント通知
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NotificationsToAgentForNewUserPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1NotificationsToAgentForNewUserPost(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary パスワードリセット
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PasswordResetsPost(inlineObject3: InlineObject3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PasswordResetsPost(inlineObject3, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Property>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件登録
         * @param {PropertyBuilder} propertyBuilder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPost(propertyBuilder: PropertyBuilder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Property>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPost(propertyBuilder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件削除
         * @param {number} propertyId 物件 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdDelete(propertyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdDelete(propertyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件に紐づくドキュメントの削除
         * @param {number} propertyId 物件 ID
         * @param {DocumentType} documentType 
         * @param {number} fileId ファイル ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdDelete(propertyId: number, documentType: DocumentType, fileId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdDelete(propertyId, documentType, fileId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件に紐づくドキュメントのファイルバイナリ取得
         * @param {number} propertyId 物件 ID
         * @param {DocumentType} documentType 
         * @param {number} fileId ファイル ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdGet(propertyId: number, documentType: DocumentType, fileId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdGet(propertyId, documentType, fileId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ドキュメント更新
         * @param {number} propertyId 物件 ID
         * @param {DocumentType} documentType 
         * @param {number} fileId ファイル ID
         * @param {DocumentForm} documentForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdPatch(propertyId: number, documentType: DocumentType, fileId: number, documentForm: DocumentForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdPatch(propertyId, documentType, fileId, documentForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件に紐づくドキュメントの一覧取得
         * @param {number} propertyId 物件 ID
         * @param {DocumentType} documentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdDocumentsDocumentTypeFilesGet(propertyId: number, documentType: DocumentType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Document>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdDocumentsDocumentTypeFilesGet(propertyId, documentType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件に紐づくドキュメントの登録
         * @param {number} propertyId 物件 ID
         * @param {DocumentType} documentType 
         * @param {Base64EncodedImage} base64EncodedImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdDocumentsDocumentTypeFilesPost(propertyId: number, documentType: DocumentType, base64EncodedImage: Base64EncodedImage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdDocumentsDocumentTypeFilesPost(propertyId, documentType, base64EncodedImage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件に紐づく画像の削除
         * @param {number} propertyId 物件 ID
         * @param {number} imageId 画像 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdImagesImageIdDelete(propertyId: number, imageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdImagesImageIdDelete(propertyId, imageId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件に紐づく画像ごとの URL 取得
         * @param {number} propertyId 物件 ID
         * @param {number} imageId 画像 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdImagesImageIdUrlGet(propertyId: number, imageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdImagesImageIdUrlGet(propertyId, imageId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件ごとの画像登録
         * @param {number} propertyId 物件 ID
         * @param {Base64EncodedImage} base64EncodedImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdImagesPost(propertyId: number, base64EncodedImage: Base64EncodedImage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Id>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdImagesPost(propertyId, base64EncodedImage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件ごとの賃貸契約取得
         * @param {number} propertyId 物件 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdLeaseContractsGet(propertyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeaseContract>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdLeaseContractsGet(propertyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件ごとの賃貸契約削除
         * @param {number} propertyId 物件 ID
         * @param {number} leaseContractId 賃貸契約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdLeaseContractsLeaseContractIdDelete(propertyId: number, leaseContractId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdLeaseContractsLeaseContractIdDelete(propertyId, leaseContractId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件ごとの賃貸契約更新
         * @param {number} propertyId 物件 ID
         * @param {number} leaseContractId 賃貸契約ID
         * @param {LeaseContract} leaseContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdLeaseContractsLeaseContractIdPatch(propertyId: number, leaseContractId: number, leaseContract: LeaseContract, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeaseContract>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdLeaseContractsLeaseContractIdPatch(propertyId, leaseContractId, leaseContract, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件ごとの賃貸契約登録
         * @param {number} propertyId 物件 ID
         * @param {LeaseContract} leaseContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdLeaseContractsPost(propertyId: number, leaseContract: LeaseContract, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeaseContract>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdLeaseContractsPost(propertyId, leaseContract, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 月毎の（ユーザ定義による）実績一覧
         * @param {number} propertyId 物件 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdMonthlyCashflowResultsGet(propertyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonthlyCashflowResult>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdMonthlyCashflowResultsGet(propertyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 月毎の（ユーザ定義による）実績登録
         * @param {number} propertyId 物件 ID
         * @param {MonthlyCashflowResultUpdate} monthlyCashflowResultUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdMonthlyCashflowResultsPatch(propertyId: number, monthlyCashflowResultUpdate: MonthlyCashflowResultUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdMonthlyCashflowResultsPatch(propertyId, monthlyCashflowResultUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件に紐づく周辺情報(不動価格)取得
         * @param {number} propertyId 物件 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdNearbyPricesGet(propertyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReinfolibPrice>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdNearbyPricesGet(propertyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件更新
         * @param {number} propertyId 物件 ID
         * @param {Property} property 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdPatch(propertyId: number, property: Property, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Property>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdPatch(propertyId, property, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件ごとのシミュレーション設定
         * @param {number} propertyId 物件 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdSimulationConfigsGet(propertyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimulationConfigWithId>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdSimulationConfigsGet(propertyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件ごとのシミュレーション設定登録
         * @param {number} propertyId 物件 ID
         * @param {SimulationConfig} simulationConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdSimulationConfigsPost(propertyId: number, simulationConfig: SimulationConfig, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimulationConfigWithId>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdSimulationConfigsPost(propertyId, simulationConfig, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件ごとのシミュレーション設定の削除
         * @param {number} propertyId 物件 ID
         * @param {number} simulationConfigId シミュレーション設定 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdDelete(propertyId: number, simulationConfigId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdDelete(propertyId, simulationConfigId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件ごとのシミュレーション設定の更新
         * @param {number} propertyId 物件 ID
         * @param {number} simulationConfigId シミュレーション設定 ID
         * @param {SimulationConfig} simulationConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdPatch(propertyId: number, simulationConfigId: number, simulationConfig: SimulationConfig, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimulationConfigWithId>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdPatch(propertyId, simulationConfigId, simulationConfig, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件ごとのシミュレーション設定における修繕積立金の金額変動の更新
         * @param {number} propertyId 物件 ID
         * @param {number} simulationConfigId シミュレーション設定 ID
         * @param {InlineObject5} inlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdReserveFundPriceChangesPost(propertyId: number, simulationConfigId: number, inlineObject5: InlineObject5, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReserveFundPriceChange>>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdReserveFundPriceChangesPost(propertyId, simulationConfigId, inlineObject5, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件一時保存状態削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertyTemporaryDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertyTemporaryDelete(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件一時保存状態取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertyTemporaryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyTemporary>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertyTemporaryGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 物件一時保存状態登録・更新
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PropertyTemporaryPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1PropertyTemporaryPost(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary マッチング登録
         * @param {ProposalMatchingRequest} proposalMatchingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ProposalMatchingsPost(proposalMatchingRequest: ProposalMatchingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1ProposalMatchingsPost(proposalMatchingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ユーザー登録
         * @param {UserRegistrations} userRegistrations 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RegistrationsPost(userRegistrations: UserRegistrations, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1RegistrationsPost(userRegistrations, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 電話番号認証コード送信
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SendPhoneNumberAuthCodesPost(inlineObject2: InlineObject2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1SendPhoneNumberAuthCodesPost(inlineObject2, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ユーザ画面アクセス登録
         * @param {UserAccessScreenLog} userAccessScreenLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserAccessScreenLogsPost(userAccessScreenLog: UserAccessScreenLog, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1UserAccessScreenLogsPost(userAccessScreenLog, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ユーザデータ初回登録ステータス取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserDataFirstRegisteredStatusGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataFirstRegisteredStatus>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1UserDataFirstRegisteredStatusGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ユーザデータ初回登録ステータス更新
         * @param {UserDataFirstRegisteredStatus} userDataFirstRegisteredStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserDataFirstRegisteredStatusPatch(userDataFirstRegisteredStatus: UserDataFirstRegisteredStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataFirstRegisteredStatus>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1UserDataFirstRegisteredStatusPatch(userDataFirstRegisteredStatus, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ユーザーデバイストークン登録削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserDeviceTokenDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1UserDeviceTokenDelete(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ユーザーデバイストークン登録
         * @param {UserDeviceToken} userDeviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserDeviceTokenPost(userDeviceToken: UserDeviceToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse401>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1UserDeviceTokenPost(userDeviceToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ユーザー情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1UserGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ユーザー情報更新
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UserPatch(userUpdate: UserUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await PropallyV1ApiAxiosParamCreator(configuration).v1UserPatch(userUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PropallyV1Api - factory interface
 * @export
 */
export const PropallyV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary ヘルスチェックエンドポイント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthzGet(options?: any): AxiosPromise<InlineResponse200> {
            return PropallyV1ApiFp(configuration).healthzGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 査定提案未読数取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AssessmentProposalUnreadCountGet(options?: any): AxiosPromise<AssessmentProposalUnreadCountResponse> {
            return PropallyV1ApiFp(configuration).v1AssessmentProposalUnreadCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 査定提案承認or否承認実行
         * @param {number} assessmentProposalId 査定提案 ID
         * @param {ProposalApprovedRequest} proposalApprovedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AssessmentProposalsAssessmentProposalIdApprovedPatch(assessmentProposalId: number, proposalApprovedRequest: ProposalApprovedRequest, options?: any): AxiosPromise<ResponseMessage> {
            return PropallyV1ApiFp(configuration).v1AssessmentProposalsAssessmentProposalIdApprovedPatch(assessmentProposalId, proposalApprovedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary エージェント・企業情報を含む査定提案詳細1件取得
         * @param {number} assessmentProposalId 査定提案 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AssessmentProposalsAssessmentProposalIdGet(assessmentProposalId: number, options?: any): AxiosPromise<AssessmentProposal> {
            return PropallyV1ApiFp(configuration).v1AssessmentProposalsAssessmentProposalIdGet(assessmentProposalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 査定提案既読更新
         * @param {number} assessmentProposalId 査定提案 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AssessmentProposalsAssessmentProposalIdReadPatch(assessmentProposalId: number, options?: any): AxiosPromise<ResponseMessage> {
            return PropallyV1ApiFp(configuration).v1AssessmentProposalsAssessmentProposalIdReadPatch(assessmentProposalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 査定提案一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AssessmentProposalsGet(options?: any): AxiosPromise<Array<AssessmentProposal>> {
            return PropallyV1ApiFp(configuration).v1AssessmentProposalsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メールアドレス確認
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EmailConfirmationsPost(inlineObject1: InlineObject1, options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1EmailConfirmationsPost(inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary HowMa(外部API)物件詳細(部屋情報一覧)API
         * @param {number} buildingId (HowMa管理の)マンションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1HowmaBuildingsBuildingIdGet(buildingId: number, options?: any): AxiosPromise<HowMaBuildingDetailResponse> {
            return PropallyV1ApiFp(configuration).v1HowmaBuildingsBuildingIdGet(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary HowMa(外部API)物件検索API
         * @param {HowMaBuildingsSearchRequest} howMaBuildingsSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1HowmaBuildingsPost(howMaBuildingsSearchRequest: HowMaBuildingsSearchRequest, options?: any): AxiosPromise<HowMaBuildingsResponse> {
            return PropallyV1ApiFp(configuration).v1HowmaBuildingsPost(howMaBuildingsSearchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 投資提案未読数取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvestmentProposalUnreadCountGet(options?: any): AxiosPromise<InvestmentProposalUnreadCountResponse> {
            return PropallyV1ApiFp(configuration).v1InvestmentProposalUnreadCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 投資提案一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvestmentProposalsGet(options?: any): AxiosPromise<Array<InvestmentProposal>> {
            return PropallyV1ApiFp(configuration).v1InvestmentProposalsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 投資提案承認or否承認実行
         * @param {number} investmentProposalId 投資提案 ID
         * @param {ProposalApprovedRequest} proposalApprovedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvestmentProposalsInvestmentProposalIdApprovedPatch(investmentProposalId: number, proposalApprovedRequest: ProposalApprovedRequest, options?: any): AxiosPromise<ResponseMessage> {
            return PropallyV1ApiFp(configuration).v1InvestmentProposalsInvestmentProposalIdApprovedPatch(investmentProposalId, proposalApprovedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 投資提案詳細1件取得
         * @param {number} investmentProposalId 投資提案 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvestmentProposalsInvestmentProposalIdGet(investmentProposalId: number, options?: any): AxiosPromise<InvestmentProposal> {
            return PropallyV1ApiFp(configuration).v1InvestmentProposalsInvestmentProposalIdGet(investmentProposalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 投資提案既読更新
         * @param {number} investmentProposalId 投資提案 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvestmentProposalsInvestmentProposalIdReadPatch(investmentProposalId: number, options?: any): AxiosPromise<ResponseMessage> {
            return PropallyV1ApiFp(configuration).v1InvestmentProposalsInvestmentProposalIdReadPatch(investmentProposalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ログイン
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LoginPost(inlineObject: InlineObject, options?: any): AxiosPromise<User> {
            return PropallyV1ApiFp(configuration).v1LoginPost(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LogoutPost(options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1LogoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 都道府県一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MasterDataPrefecturesGet(options?: any): AxiosPromise<Array<string>> {
            return PropallyV1ApiFp(configuration).v1MasterDataPrefecturesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 町丁目一覧
         * @param {string} prefectureName 都道府県名
         * @param {string} cityName 市区町村名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MasterDataPrefecturesPrefectureNameCitiesCityNameTownsGet(prefectureName: string, cityName: string, options?: any): AxiosPromise<Array<string>> {
            return PropallyV1ApiFp(configuration).v1MasterDataPrefecturesPrefectureNameCitiesCityNameTownsGet(prefectureName, cityName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 市区町村一覧
         * @param {string} prefectureName 都道府県名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MasterDataPrefecturesPrefectureNameCitiesGet(prefectureName: string, options?: any): AxiosPromise<Array<string>> {
            return PropallyV1ApiFp(configuration).v1MasterDataPrefecturesPrefectureNameCitiesGet(prefectureName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 利用目的マスタ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MasterDataPurposeOfUsesGet(options?: any): AxiosPromise<Array<IdName>> {
            return PropallyV1ApiFp(configuration).v1MasterDataPurposeOfUsesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 鉄道一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MasterDataRailwaysGet(options?: any): AxiosPromise<Array<IdName>> {
            return PropallyV1ApiFp(configuration).v1MasterDataRailwaysGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 駅名一覧
         * @param {string} railwayName 鉄道名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MasterDataRailwaysRailwayNameStationsGet(railwayName: string, options?: any): AxiosPromise<Array<string>> {
            return PropallyV1ApiFp(configuration).v1MasterDataRailwaysRailwayNameStationsGet(railwayName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 居住タイプマスタ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MasterDataResidenceTypesGet(options?: any): AxiosPromise<Array<IdName>> {
            return PropallyV1ApiFp(configuration).v1MasterDataResidenceTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary タグマスタ取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1MasterDataTagsGet(options?: any): AxiosPromise<Array<IdName>> {
            return PropallyV1ApiFp(configuration).v1MasterDataTagsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary リセット後、新しいパスワードの登録
         * @param {InlineObject4} inlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NewPasswordsPost(inlineObject4: InlineObject4, options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1NewPasswordsPost(inlineObject4, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 初回物件登録時エージェント通知
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsToAgentForNewPropertyPost(options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1NotificationsToAgentForNewPropertyPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザSTEP1登録時エージェント通知
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsToAgentForNewUserPost(options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1NotificationsToAgentForNewUserPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary パスワードリセット
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PasswordResetsPost(inlineObject3: InlineObject3, options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1PasswordResetsPost(inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesGet(options?: any): AxiosPromise<Array<Property>> {
            return PropallyV1ApiFp(configuration).v1PropertiesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件登録
         * @param {PropertyBuilder} propertyBuilder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPost(propertyBuilder: PropertyBuilder, options?: any): AxiosPromise<Property> {
            return PropallyV1ApiFp(configuration).v1PropertiesPost(propertyBuilder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件削除
         * @param {number} propertyId 物件 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdDelete(propertyId: number, options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdDelete(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件に紐づくドキュメントの削除
         * @param {number} propertyId 物件 ID
         * @param {DocumentType} documentType 
         * @param {number} fileId ファイル ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdDelete(propertyId: number, documentType: DocumentType, fileId: number, options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdDelete(propertyId, documentType, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件に紐づくドキュメントのファイルバイナリ取得
         * @param {number} propertyId 物件 ID
         * @param {DocumentType} documentType 
         * @param {number} fileId ファイル ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdGet(propertyId: number, documentType: DocumentType, fileId: number, options?: any): AxiosPromise<any> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdGet(propertyId, documentType, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ドキュメント更新
         * @param {number} propertyId 物件 ID
         * @param {DocumentType} documentType 
         * @param {number} fileId ファイル ID
         * @param {DocumentForm} documentForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdPatch(propertyId: number, documentType: DocumentType, fileId: number, documentForm: DocumentForm, options?: any): AxiosPromise<Document> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdPatch(propertyId, documentType, fileId, documentForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件に紐づくドキュメントの一覧取得
         * @param {number} propertyId 物件 ID
         * @param {DocumentType} documentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdDocumentsDocumentTypeFilesGet(propertyId: number, documentType: DocumentType, options?: any): AxiosPromise<Array<Document>> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdDocumentsDocumentTypeFilesGet(propertyId, documentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件に紐づくドキュメントの登録
         * @param {number} propertyId 物件 ID
         * @param {DocumentType} documentType 
         * @param {Base64EncodedImage} base64EncodedImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdDocumentsDocumentTypeFilesPost(propertyId: number, documentType: DocumentType, base64EncodedImage: Base64EncodedImage, options?: any): AxiosPromise<Document> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdDocumentsDocumentTypeFilesPost(propertyId, documentType, base64EncodedImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件に紐づく画像の削除
         * @param {number} propertyId 物件 ID
         * @param {number} imageId 画像 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdImagesImageIdDelete(propertyId: number, imageId: number, options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdImagesImageIdDelete(propertyId, imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件に紐づく画像ごとの URL 取得
         * @param {number} propertyId 物件 ID
         * @param {number} imageId 画像 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdImagesImageIdUrlGet(propertyId: number, imageId: number, options?: any): AxiosPromise<InlineResponse2001> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdImagesImageIdUrlGet(propertyId, imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件ごとの画像登録
         * @param {number} propertyId 物件 ID
         * @param {Base64EncodedImage} base64EncodedImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdImagesPost(propertyId: number, base64EncodedImage: Base64EncodedImage, options?: any): AxiosPromise<Id> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdImagesPost(propertyId, base64EncodedImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件ごとの賃貸契約取得
         * @param {number} propertyId 物件 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdLeaseContractsGet(propertyId: number, options?: any): AxiosPromise<Array<LeaseContract>> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdLeaseContractsGet(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件ごとの賃貸契約削除
         * @param {number} propertyId 物件 ID
         * @param {number} leaseContractId 賃貸契約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdLeaseContractsLeaseContractIdDelete(propertyId: number, leaseContractId: number, options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdLeaseContractsLeaseContractIdDelete(propertyId, leaseContractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件ごとの賃貸契約更新
         * @param {number} propertyId 物件 ID
         * @param {number} leaseContractId 賃貸契約ID
         * @param {LeaseContract} leaseContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdLeaseContractsLeaseContractIdPatch(propertyId: number, leaseContractId: number, leaseContract: LeaseContract, options?: any): AxiosPromise<LeaseContract> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdLeaseContractsLeaseContractIdPatch(propertyId, leaseContractId, leaseContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件ごとの賃貸契約登録
         * @param {number} propertyId 物件 ID
         * @param {LeaseContract} leaseContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdLeaseContractsPost(propertyId: number, leaseContract: LeaseContract, options?: any): AxiosPromise<LeaseContract> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdLeaseContractsPost(propertyId, leaseContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 月毎の（ユーザ定義による）実績一覧
         * @param {number} propertyId 物件 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdMonthlyCashflowResultsGet(propertyId: number, options?: any): AxiosPromise<Array<MonthlyCashflowResult>> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdMonthlyCashflowResultsGet(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 月毎の（ユーザ定義による）実績登録
         * @param {number} propertyId 物件 ID
         * @param {MonthlyCashflowResultUpdate} monthlyCashflowResultUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdMonthlyCashflowResultsPatch(propertyId: number, monthlyCashflowResultUpdate: MonthlyCashflowResultUpdate, options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdMonthlyCashflowResultsPatch(propertyId, monthlyCashflowResultUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件に紐づく周辺情報(不動価格)取得
         * @param {number} propertyId 物件 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdNearbyPricesGet(propertyId: number, options?: any): AxiosPromise<Array<ReinfolibPrice>> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdNearbyPricesGet(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件更新
         * @param {number} propertyId 物件 ID
         * @param {Property} property 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdPatch(propertyId: number, property: Property, options?: any): AxiosPromise<Property> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdPatch(propertyId, property, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件ごとのシミュレーション設定
         * @param {number} propertyId 物件 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdSimulationConfigsGet(propertyId: number, options?: any): AxiosPromise<Array<SimulationConfigWithId>> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdSimulationConfigsGet(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件ごとのシミュレーション設定登録
         * @param {number} propertyId 物件 ID
         * @param {SimulationConfig} simulationConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdSimulationConfigsPost(propertyId: number, simulationConfig: SimulationConfig, options?: any): AxiosPromise<SimulationConfigWithId> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdSimulationConfigsPost(propertyId, simulationConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件ごとのシミュレーション設定の削除
         * @param {number} propertyId 物件 ID
         * @param {number} simulationConfigId シミュレーション設定 ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdDelete(propertyId: number, simulationConfigId: number, options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdDelete(propertyId, simulationConfigId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件ごとのシミュレーション設定の更新
         * @param {number} propertyId 物件 ID
         * @param {number} simulationConfigId シミュレーション設定 ID
         * @param {SimulationConfig} simulationConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdPatch(propertyId: number, simulationConfigId: number, simulationConfig: SimulationConfig, options?: any): AxiosPromise<SimulationConfigWithId> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdPatch(propertyId, simulationConfigId, simulationConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件ごとのシミュレーション設定における修繕積立金の金額変動の更新
         * @param {number} propertyId 物件 ID
         * @param {number} simulationConfigId シミュレーション設定 ID
         * @param {InlineObject5} inlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdReserveFundPriceChangesPost(propertyId: number, simulationConfigId: number, inlineObject5: InlineObject5, options?: any): AxiosPromise<Array<ReserveFundPriceChange>> {
            return PropallyV1ApiFp(configuration).v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdReserveFundPriceChangesPost(propertyId, simulationConfigId, inlineObject5, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件一時保存状態削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertyTemporaryDelete(options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1PropertyTemporaryDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件一時保存状態取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertyTemporaryGet(options?: any): AxiosPromise<PropertyTemporary> {
            return PropallyV1ApiFp(configuration).v1PropertyTemporaryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 物件一時保存状態登録・更新
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PropertyTemporaryPost(options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1PropertyTemporaryPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary マッチング登録
         * @param {ProposalMatchingRequest} proposalMatchingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ProposalMatchingsPost(proposalMatchingRequest: ProposalMatchingRequest, options?: any): AxiosPromise<ResponseMessage> {
            return PropallyV1ApiFp(configuration).v1ProposalMatchingsPost(proposalMatchingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー登録
         * @param {UserRegistrations} userRegistrations 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RegistrationsPost(userRegistrations: UserRegistrations, options?: any): AxiosPromise<User> {
            return PropallyV1ApiFp(configuration).v1RegistrationsPost(userRegistrations, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 電話番号認証コード送信
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SendPhoneNumberAuthCodesPost(inlineObject2: InlineObject2, options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1SendPhoneNumberAuthCodesPost(inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザ画面アクセス登録
         * @param {UserAccessScreenLog} userAccessScreenLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserAccessScreenLogsPost(userAccessScreenLog: UserAccessScreenLog, options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1UserAccessScreenLogsPost(userAccessScreenLog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザデータ初回登録ステータス取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserDataFirstRegisteredStatusGet(options?: any): AxiosPromise<UserDataFirstRegisteredStatus> {
            return PropallyV1ApiFp(configuration).v1UserDataFirstRegisteredStatusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザデータ初回登録ステータス更新
         * @param {UserDataFirstRegisteredStatus} userDataFirstRegisteredStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserDataFirstRegisteredStatusPatch(userDataFirstRegisteredStatus: UserDataFirstRegisteredStatus, options?: any): AxiosPromise<UserDataFirstRegisteredStatus> {
            return PropallyV1ApiFp(configuration).v1UserDataFirstRegisteredStatusPatch(userDataFirstRegisteredStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーデバイストークン登録削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserDeviceTokenDelete(options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1UserDeviceTokenDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーデバイストークン登録
         * @param {UserDeviceToken} userDeviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserDeviceTokenPost(userDeviceToken: UserDeviceToken, options?: any): AxiosPromise<InlineResponse401> {
            return PropallyV1ApiFp(configuration).v1UserDeviceTokenPost(userDeviceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserGet(options?: any): AxiosPromise<User> {
            return PropallyV1ApiFp(configuration).v1UserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー情報更新
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UserPatch(userUpdate: UserUpdate, options?: any): AxiosPromise<User> {
            return PropallyV1ApiFp(configuration).v1UserPatch(userUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropallyV1Api - object-oriented interface
 * @export
 * @class PropallyV1Api
 * @extends {BaseAPI}
 */
export class PropallyV1Api extends BaseAPI {
    /**
     * 
     * @summary ヘルスチェックエンドポイント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public healthzGet(options?: any) {
        return PropallyV1ApiFp(this.configuration).healthzGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 査定提案未読数取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1AssessmentProposalUnreadCountGet(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1AssessmentProposalUnreadCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 査定提案承認or否承認実行
     * @param {number} assessmentProposalId 査定提案 ID
     * @param {ProposalApprovedRequest} proposalApprovedRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1AssessmentProposalsAssessmentProposalIdApprovedPatch(assessmentProposalId: number, proposalApprovedRequest: ProposalApprovedRequest, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1AssessmentProposalsAssessmentProposalIdApprovedPatch(assessmentProposalId, proposalApprovedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary エージェント・企業情報を含む査定提案詳細1件取得
     * @param {number} assessmentProposalId 査定提案 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1AssessmentProposalsAssessmentProposalIdGet(assessmentProposalId: number, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1AssessmentProposalsAssessmentProposalIdGet(assessmentProposalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 査定提案既読更新
     * @param {number} assessmentProposalId 査定提案 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1AssessmentProposalsAssessmentProposalIdReadPatch(assessmentProposalId: number, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1AssessmentProposalsAssessmentProposalIdReadPatch(assessmentProposalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 査定提案一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1AssessmentProposalsGet(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1AssessmentProposalsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メールアドレス確認
     * @param {InlineObject1} inlineObject1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1EmailConfirmationsPost(inlineObject1: InlineObject1, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1EmailConfirmationsPost(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary HowMa(外部API)物件詳細(部屋情報一覧)API
     * @param {number} buildingId (HowMa管理の)マンションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1HowmaBuildingsBuildingIdGet(buildingId: number, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1HowmaBuildingsBuildingIdGet(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary HowMa(外部API)物件検索API
     * @param {HowMaBuildingsSearchRequest} howMaBuildingsSearchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1HowmaBuildingsPost(howMaBuildingsSearchRequest: HowMaBuildingsSearchRequest, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1HowmaBuildingsPost(howMaBuildingsSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 投資提案未読数取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1InvestmentProposalUnreadCountGet(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1InvestmentProposalUnreadCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 投資提案一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1InvestmentProposalsGet(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1InvestmentProposalsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 投資提案承認or否承認実行
     * @param {number} investmentProposalId 投資提案 ID
     * @param {ProposalApprovedRequest} proposalApprovedRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1InvestmentProposalsInvestmentProposalIdApprovedPatch(investmentProposalId: number, proposalApprovedRequest: ProposalApprovedRequest, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1InvestmentProposalsInvestmentProposalIdApprovedPatch(investmentProposalId, proposalApprovedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 投資提案詳細1件取得
     * @param {number} investmentProposalId 投資提案 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1InvestmentProposalsInvestmentProposalIdGet(investmentProposalId: number, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1InvestmentProposalsInvestmentProposalIdGet(investmentProposalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 投資提案既読更新
     * @param {number} investmentProposalId 投資提案 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1InvestmentProposalsInvestmentProposalIdReadPatch(investmentProposalId: number, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1InvestmentProposalsInvestmentProposalIdReadPatch(investmentProposalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ログイン
     * @param {InlineObject} inlineObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1LoginPost(inlineObject: InlineObject, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1LoginPost(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ログアウト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1LogoutPost(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1LogoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 都道府県一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1MasterDataPrefecturesGet(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1MasterDataPrefecturesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 町丁目一覧
     * @param {string} prefectureName 都道府県名
     * @param {string} cityName 市区町村名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1MasterDataPrefecturesPrefectureNameCitiesCityNameTownsGet(prefectureName: string, cityName: string, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1MasterDataPrefecturesPrefectureNameCitiesCityNameTownsGet(prefectureName, cityName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 市区町村一覧
     * @param {string} prefectureName 都道府県名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1MasterDataPrefecturesPrefectureNameCitiesGet(prefectureName: string, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1MasterDataPrefecturesPrefectureNameCitiesGet(prefectureName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 利用目的マスタ取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1MasterDataPurposeOfUsesGet(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1MasterDataPurposeOfUsesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 鉄道一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1MasterDataRailwaysGet(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1MasterDataRailwaysGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 駅名一覧
     * @param {string} railwayName 鉄道名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1MasterDataRailwaysRailwayNameStationsGet(railwayName: string, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1MasterDataRailwaysRailwayNameStationsGet(railwayName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 居住タイプマスタ取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1MasterDataResidenceTypesGet(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1MasterDataResidenceTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary タグマスタ取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1MasterDataTagsGet(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1MasterDataTagsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary リセット後、新しいパスワードの登録
     * @param {InlineObject4} inlineObject4 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1NewPasswordsPost(inlineObject4: InlineObject4, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1NewPasswordsPost(inlineObject4, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 初回物件登録時エージェント通知
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1NotificationsToAgentForNewPropertyPost(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1NotificationsToAgentForNewPropertyPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザSTEP1登録時エージェント通知
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1NotificationsToAgentForNewUserPost(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1NotificationsToAgentForNewUserPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary パスワードリセット
     * @param {InlineObject3} inlineObject3 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PasswordResetsPost(inlineObject3: InlineObject3, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PasswordResetsPost(inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesGet(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件登録
     * @param {PropertyBuilder} propertyBuilder 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPost(propertyBuilder: PropertyBuilder, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPost(propertyBuilder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件削除
     * @param {number} propertyId 物件 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdDelete(propertyId: number, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdDelete(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件に紐づくドキュメントの削除
     * @param {number} propertyId 物件 ID
     * @param {DocumentType} documentType 
     * @param {number} fileId ファイル ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdDelete(propertyId: number, documentType: DocumentType, fileId: number, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdDelete(propertyId, documentType, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件に紐づくドキュメントのファイルバイナリ取得
     * @param {number} propertyId 物件 ID
     * @param {DocumentType} documentType 
     * @param {number} fileId ファイル ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdGet(propertyId: number, documentType: DocumentType, fileId: number, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdGet(propertyId, documentType, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ドキュメント更新
     * @param {number} propertyId 物件 ID
     * @param {DocumentType} documentType 
     * @param {number} fileId ファイル ID
     * @param {DocumentForm} documentForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdPatch(propertyId: number, documentType: DocumentType, fileId: number, documentForm: DocumentForm, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdDocumentsDocumentTypeFilesFileIdPatch(propertyId, documentType, fileId, documentForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件に紐づくドキュメントの一覧取得
     * @param {number} propertyId 物件 ID
     * @param {DocumentType} documentType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdDocumentsDocumentTypeFilesGet(propertyId: number, documentType: DocumentType, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdDocumentsDocumentTypeFilesGet(propertyId, documentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件に紐づくドキュメントの登録
     * @param {number} propertyId 物件 ID
     * @param {DocumentType} documentType 
     * @param {Base64EncodedImage} base64EncodedImage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdDocumentsDocumentTypeFilesPost(propertyId: number, documentType: DocumentType, base64EncodedImage: Base64EncodedImage, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdDocumentsDocumentTypeFilesPost(propertyId, documentType, base64EncodedImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件に紐づく画像の削除
     * @param {number} propertyId 物件 ID
     * @param {number} imageId 画像 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdImagesImageIdDelete(propertyId: number, imageId: number, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdImagesImageIdDelete(propertyId, imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件に紐づく画像ごとの URL 取得
     * @param {number} propertyId 物件 ID
     * @param {number} imageId 画像 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdImagesImageIdUrlGet(propertyId: number, imageId: number, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdImagesImageIdUrlGet(propertyId, imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件ごとの画像登録
     * @param {number} propertyId 物件 ID
     * @param {Base64EncodedImage} base64EncodedImage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdImagesPost(propertyId: number, base64EncodedImage: Base64EncodedImage, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdImagesPost(propertyId, base64EncodedImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件ごとの賃貸契約取得
     * @param {number} propertyId 物件 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdLeaseContractsGet(propertyId: number, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdLeaseContractsGet(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件ごとの賃貸契約削除
     * @param {number} propertyId 物件 ID
     * @param {number} leaseContractId 賃貸契約ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdLeaseContractsLeaseContractIdDelete(propertyId: number, leaseContractId: number, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdLeaseContractsLeaseContractIdDelete(propertyId, leaseContractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件ごとの賃貸契約更新
     * @param {number} propertyId 物件 ID
     * @param {number} leaseContractId 賃貸契約ID
     * @param {LeaseContract} leaseContract 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdLeaseContractsLeaseContractIdPatch(propertyId: number, leaseContractId: number, leaseContract: LeaseContract, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdLeaseContractsLeaseContractIdPatch(propertyId, leaseContractId, leaseContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件ごとの賃貸契約登録
     * @param {number} propertyId 物件 ID
     * @param {LeaseContract} leaseContract 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdLeaseContractsPost(propertyId: number, leaseContract: LeaseContract, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdLeaseContractsPost(propertyId, leaseContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 月毎の（ユーザ定義による）実績一覧
     * @param {number} propertyId 物件 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdMonthlyCashflowResultsGet(propertyId: number, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdMonthlyCashflowResultsGet(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 月毎の（ユーザ定義による）実績登録
     * @param {number} propertyId 物件 ID
     * @param {MonthlyCashflowResultUpdate} monthlyCashflowResultUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdMonthlyCashflowResultsPatch(propertyId: number, monthlyCashflowResultUpdate: MonthlyCashflowResultUpdate, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdMonthlyCashflowResultsPatch(propertyId, monthlyCashflowResultUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件に紐づく周辺情報(不動価格)取得
     * @param {number} propertyId 物件 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdNearbyPricesGet(propertyId: number, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdNearbyPricesGet(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件更新
     * @param {number} propertyId 物件 ID
     * @param {Property} property 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdPatch(propertyId: number, property: Property, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdPatch(propertyId, property, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件ごとのシミュレーション設定
     * @param {number} propertyId 物件 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdSimulationConfigsGet(propertyId: number, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdSimulationConfigsGet(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件ごとのシミュレーション設定登録
     * @param {number} propertyId 物件 ID
     * @param {SimulationConfig} simulationConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdSimulationConfigsPost(propertyId: number, simulationConfig: SimulationConfig, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdSimulationConfigsPost(propertyId, simulationConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件ごとのシミュレーション設定の削除
     * @param {number} propertyId 物件 ID
     * @param {number} simulationConfigId シミュレーション設定 ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdDelete(propertyId: number, simulationConfigId: number, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdDelete(propertyId, simulationConfigId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件ごとのシミュレーション設定の更新
     * @param {number} propertyId 物件 ID
     * @param {number} simulationConfigId シミュレーション設定 ID
     * @param {SimulationConfig} simulationConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdPatch(propertyId: number, simulationConfigId: number, simulationConfig: SimulationConfig, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdPatch(propertyId, simulationConfigId, simulationConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件ごとのシミュレーション設定における修繕積立金の金額変動の更新
     * @param {number} propertyId 物件 ID
     * @param {number} simulationConfigId シミュレーション設定 ID
     * @param {InlineObject5} inlineObject5 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdReserveFundPriceChangesPost(propertyId: number, simulationConfigId: number, inlineObject5: InlineObject5, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertiesPropertyIdSimulationConfigsSimulationConfigIdReserveFundPriceChangesPost(propertyId, simulationConfigId, inlineObject5, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件一時保存状態削除
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertyTemporaryDelete(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertyTemporaryDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件一時保存状態取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertyTemporaryGet(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertyTemporaryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 物件一時保存状態登録・更新
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1PropertyTemporaryPost(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1PropertyTemporaryPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary マッチング登録
     * @param {ProposalMatchingRequest} proposalMatchingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1ProposalMatchingsPost(proposalMatchingRequest: ProposalMatchingRequest, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1ProposalMatchingsPost(proposalMatchingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー登録
     * @param {UserRegistrations} userRegistrations 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1RegistrationsPost(userRegistrations: UserRegistrations, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1RegistrationsPost(userRegistrations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 電話番号認証コード送信
     * @param {InlineObject2} inlineObject2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1SendPhoneNumberAuthCodesPost(inlineObject2: InlineObject2, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1SendPhoneNumberAuthCodesPost(inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザ画面アクセス登録
     * @param {UserAccessScreenLog} userAccessScreenLog 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1UserAccessScreenLogsPost(userAccessScreenLog: UserAccessScreenLog, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1UserAccessScreenLogsPost(userAccessScreenLog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザデータ初回登録ステータス取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1UserDataFirstRegisteredStatusGet(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1UserDataFirstRegisteredStatusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザデータ初回登録ステータス更新
     * @param {UserDataFirstRegisteredStatus} userDataFirstRegisteredStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1UserDataFirstRegisteredStatusPatch(userDataFirstRegisteredStatus: UserDataFirstRegisteredStatus, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1UserDataFirstRegisteredStatusPatch(userDataFirstRegisteredStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーデバイストークン登録削除
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1UserDeviceTokenDelete(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1UserDeviceTokenDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーデバイストークン登録
     * @param {UserDeviceToken} userDeviceToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1UserDeviceTokenPost(userDeviceToken: UserDeviceToken, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1UserDeviceTokenPost(userDeviceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1UserGet(options?: any) {
        return PropallyV1ApiFp(this.configuration).v1UserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー情報更新
     * @param {UserUpdate} userUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropallyV1Api
     */
    public v1UserPatch(userUpdate: UserUpdate, options?: any) {
        return PropallyV1ApiFp(this.configuration).v1UserPatch(userUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


