import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import dayjs from "dayjs";
import * as React from "react";
import { useHistory } from "react-router";
import { useRecoilCallback, useRecoilValue, useRecoilValueLoadable } from "recoil";

import { LeaseContract } from "../../../../api/generated";
import { CustomButton } from "../../../../scripts/components/renewal_v1/button";
import { PropertyEditPageState } from "../../../../view_models/atoms";
import { MonthlyCashflowResultsOnToday } from "../../../../view_models/balancesheet_selectors";
import { SelectedProperty } from "../../../../view_models/property_selectors";
import { Loading } from "../../../loading";

import { AccordionDetailItemRow } from "./accordion_detail_item_row";

const useStyles = makeStyles((theme) => ({
  headerBox: {
    backgroundColor: "#E7EAF6", // 青い背景
    padding: "20px 24px",
    display: "flex",
    justifyContent: "space-between",
  },
  // アコーディオン
  expanded: {
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionSummary: {
    flexDirection: "column", // 垂直方向に並べる
    width: "100%",
    maxWidth: 640,
    margin: "0 auto",
  },
  accordionSummaryExpandIcon: {
    width: 24,
    height: 24,
    paddingBottom: 16,
  },
  accordionSummaryContent: {
    width: "100%",
    "&.Mui-expanded": {
      margin: "12px 0", // アコーディオンが開いた時のマージンを設定
    },
  },
  expandIcon: {
    alignSelf: "flex-end", // アイコンをボトムに配置
  },
  accordionSummaryRow: {
    maxWidth: 640,
    margin: "0 auto",
    padding: 0,
    height: 72,
    [theme.breakpoints.down("sm")]: {
      height: 56,
      padding: "0 24px",
    },
  },
  // 収支
  summaryRoot: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 48,
  },
  summaryItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    width: "100%",
    justifyContent: "space-between",
  },
  income: {
    color: theme.palette.info.main,
  },
  divider: {
    height: "100%",
    backgroundColor: theme.palette.divider,
    margin: "0 16px",
  },
  // Accordion Details
  detail: {
    width: "100%",
    maxWidth: 640,
    margin: "0 auto",
  },
  detailTextBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: 48,
  },
  background: {
    width: "100%",
    maxWidth: 640,
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0 24px",
    },
  },
}));

export enum ExpandItem {
  ManagementCost = "management_cost",
  LoanInfo = "loan_info",
  LeaseContract = "lease_contract",
  InsuranceInfo = "insurance_info",
}
type expandItem = ExpandItem | "";

export const ManagementDetailCashflow: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [expanded, setExpanded] = React.useState<expandItem>("");
  const [leaseContractThisMonth, setLeaseContractThisMonth] = React.useState<LeaseContract>(null);

  const property = useRecoilValue(SelectedProperty);
  const monthly_cashflow_results_loadable = useRecoilValueLoadable(MonthlyCashflowResultsOnToday);

  // useRecoilCallbackを使用して非同期の状態更新を行う
  const updatePropertyEditPageState = useRecoilCallback(({ snapshot, set }) => async (newState) => {
    set(PropertyEditPageState, newState);
    // 状態が更新されるのを待つ
    await snapshot.getPromise(PropertyEditPageState);
  });

  React.useEffect(() => {
    if (property.lease_contracts.length) {
      const leaseContract = property.lease_contracts.find(
        (leaseContract) =>
          (dayjs(leaseContract.starts_at).isBefore(dayjs(), "month") ||
            dayjs(leaseContract.starts_at).isSame(dayjs(), "month")) &&
          (dayjs(leaseContract.ends_at).isAfter(dayjs(), "month") ||
            dayjs(leaseContract.ends_at).isSame(dayjs(), "month"))
      );
      if (leaseContract) {
        setLeaseContractThisMonth(leaseContract);
      }
    }
  }, [property]);

  if (monthly_cashflow_results_loadable.state === "loading") {
    return <Loading />;
  }
  if (monthly_cashflow_results_loadable.state === "hasError") {
    throw monthly_cashflow_results_loadable.contents;
  }

  const monthly_cashflow_results = monthly_cashflow_results_loadable.contents;

  // 収入
  const income = Math.round(
    monthly_cashflow_results.result.rent_income + monthly_cashflow_results.result.management_fee_income
  );

  // 支出
  const expense = Math.round(
    monthly_cashflow_results.result.loan_principal +
      monthly_cashflow_results.result.loan_interest +
      monthly_cashflow_results.result.loan_prepayment +
      monthly_cashflow_results.result.building_management_cost +
      monthly_cashflow_results.result.repair_fund_cost +
      monthly_cashflow_results.result.management_cost +
      monthly_cashflow_results.result.misc_cost
  );

  // 収支
  const totalCashFlow = Math.round(income - expense);

  const cashflowMap = new Map<string, string>([
    ["家賃収入", `${Math.round(monthly_cashflow_results.result.rent_income).toLocaleString()}円`],
    ["管理費収入", `${Math.round(monthly_cashflow_results.result.management_fee_income).toLocaleString()}円`],
    [
      "ローン返済（利息含む）",
      `${Math.round(
        monthly_cashflow_results.result.loan_principal +
          monthly_cashflow_results.result.loan_interest +
          monthly_cashflow_results.result.loan_prepayment
      ).toLocaleString()}円`,
    ],
    ["建物管理費", `${Math.round(monthly_cashflow_results.result.building_management_cost).toLocaleString()}円`],
    ["修繕積立金", `${Math.round(monthly_cashflow_results.result.repair_fund_cost).toLocaleString()}円`],
    ["賃貸管理費", `${Math.round(monthly_cashflow_results.result.management_cost).toLocaleString()}円`],
    ["その他経費", `${Math.round(monthly_cashflow_results.result.misc_cost).toLocaleString()}円`],
  ]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // 賃貸借契約開始時における収入
  const typeOfIncomeBeginningContractMap = {
    KeyMoney: "礼金",
    SecurityDeposit: "更新料",
  };
  const typeOfIncomeBeginningContract = leaseContractThisMonth?.type_of_the_income_of_the_beginning_of_contract
    ? typeOfIncomeBeginningContractMap[leaseContractThisMonth?.type_of_the_income_of_the_beginning_of_contract]
    : "礼金";
  let leaseContractIncome = "-ヶ月分";
  if (leaseContractThisMonth?.type_of_the_income_of_the_beginning_of_contract === "KeyMoney") {
    leaseContractIncome = `${leaseContractThisMonth?.key_money.toLocaleString()}ヶ月分`;
  } else if (leaseContractThisMonth?.type_of_the_income_of_the_beginning_of_contract === "SecurityDeposit") {
    leaseContractIncome = `${leaseContractThisMonth?.security_deposit.toLocaleString()}ヶ月分`;
  }

  // 賃貸借契約開始時における支出
  const outsourcingFeeNew = leaseContractThisMonth?.outsourcing_fee_rate_of_new_lease_contract
    ? `${leaseContractThisMonth.outsourcing_fee_rate_of_new_lease_contract.toLocaleString()}ヶ月分`
    : "-";
  const outsourcingFeeUpdating = leaseContractThisMonth?.outsourcing_fee_rate_of_updating_lease_contract
    ? `${leaseContractThisMonth.outsourcing_fee_rate_of_updating_lease_contract.toLocaleString()}ヶ月分`
    : "-";

  // 保険情報
  const startMonth = monthly_cashflow_results.result?.month
    ? dayjs(monthly_cashflow_results.result?.month).format("YYYY/MM/DD")
    : "";

  return (
    <Box mb={8}>
      {/* 今月の収支 */}
      <Box className={classes.headerBox}>
        <Box>
          <Typography variant="button">今月の収支</Typography>
          <Typography variant="h6">(ローン返済含む)</Typography>
        </Box>

        <Typography variant="h1">{totalCashFlow.toLocaleString()}円</Typography>
      </Box>
      <Accordion classes={{ root: classes.expanded }} square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          className={classes.accordionSummary}
          classes={{ expandIcon: classes.accordionSummaryExpandIcon, content: classes.accordionSummaryContent }}
        >
          <Box className={classes.summaryRoot}>
            <Box className={classes.summaryItem}>
              <Typography variant="h5" color="textPrimary">
                収入
              </Typography>
              <Typography variant="body1" className={classes.income}>
                {income.toLocaleString()}円
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem className={classes.divider} />
            <Box className={classes.summaryItem}>
              <Typography variant="h5" color="textPrimary">
                支出
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {expense.toLocaleString()}円
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.detail}>
            {cashflowMap.size &&
              Array.from(cashflowMap).map(([key, value]) => {
                return (
                  <Box key={key} className={classes.detailTextBox}>
                    <Typography variant="h4" color="textSecondary">
                      {key}
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {value}
                    </Typography>
                  </Box>
                );
              })}
          </Box>
        </AccordionDetails>
      </Accordion>
      {/* 経費情報 */}
      <Accordion
        expanded={expanded === ExpandItem.ManagementCost}
        onChange={handleAccordionChange(ExpandItem.ManagementCost)}
        classes={{ root: classes.expanded }}
        square
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryRow}>
          <Typography variant="h4" color="textPrimary">
            経費情報
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.detail}>
          <Box className={classes.background}>
            {/* 経費情報 */}
            <AccordionDetailItemRow
              title="建物管理費"
              value={`${Math.round(monthly_cashflow_results.result.building_management_cost).toLocaleString()}円`}
            />
            <AccordionDetailItemRow
              title="修繕積立金"
              value={`${Math.round(monthly_cashflow_results.result.repair_fund_cost).toLocaleString()}円`}
            />
            <AccordionDetailItemRow
              title="賃貸管理費"
              value={`${Math.round(monthly_cashflow_results.result.management_cost).toLocaleString()}円`}
            />
            <AccordionDetailItemRow
              title="その他経費"
              value={`${Math.round(monthly_cashflow_results.result.misc_cost).toLocaleString()}円`}
            />

            <Box mb={2} justifyContent="center">
              <CustomButton
                customVariant="outlinedGray"
                isEdit
                onClick={async () => {
                  await updatePropertyEditPageState("management_cost");
                  history.push("/edit_property");
                }}
              >
                編集する
              </CustomButton>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* 借入情報 */}
      <Accordion
        expanded={expanded === ExpandItem.LoanInfo}
        onChange={handleAccordionChange(ExpandItem.LoanInfo)}
        classes={{ root: classes.expanded }}
        square
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryRow}>
          <Typography variant="h4" color="textPrimary">
            借入情報
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.detail}>
          <Box className={classes.background}>
            {/* 借入情報 */}
            <AccordionDetailItemRow
              title="借入金額"
              value={`${Math.round(property.property.loan_amount).toLocaleString()}円`}
            />
            <AccordionDetailItemRow
              title="借入利率 (年利)"
              value={`${monthly_cashflow_results.result.loan_interest_rate_percentage}%`}
            />
            <AccordionDetailItemRow
              title="初回返済年月"
              value={`${dayjs(property.property.payment_starts_at).format("YYYY年MM月")}`}
            />
            <AccordionDetailItemRow title="返済年数" value={`${property.property.payment_period}年`} />

            <Box mb={2} justifyContent="center">
              <CustomButton
                customVariant="outlinedGray"
                isEdit
                onClick={async () => {
                  await updatePropertyEditPageState("loan_info");
                  history.push("/edit_property");
                }}
              >
                編集する
              </CustomButton>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* 現在入居者の賃貸契約情報 */}
      <Accordion
        expanded={expanded === ExpandItem.LeaseContract}
        onChange={handleAccordionChange(ExpandItem.LeaseContract)}
        classes={{ root: classes.expanded }}
        square
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryRow}>
          <Typography variant="h4" color="textPrimary">
            現在入居者の賃貸契約情報
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.detail}>
          <Box className={classes.background}>
            {/* 現在入居者の賃貸契約情報 */}
            <AccordionDetailItemRow
              title="家賃収入"
              value={
                leaseContractThisMonth?.monthly_fee
                  ? `${Math.round(leaseContractThisMonth.monthly_fee).toLocaleString()}円`
                  : "-円"
              }
            />
            <AccordionDetailItemRow
              title="管理費収入"
              value={
                leaseContractThisMonth?.monthly_management_fee
                  ? `${Math.round(leaseContractThisMonth.monthly_management_fee).toLocaleString()}円`
                  : "-円"
              }
            />
            <AccordionDetailItemRow
              title="賃貸借契約の開始日"
              value={
                leaseContractThisMonth?.starts_at
                  ? `${dayjs(leaseContractThisMonth.starts_at).format("YYYY年MM月DD日")}`
                  : "-"
              }
            />
            <AccordionDetailItemRow
              title="賃貸借契約の終了日"
              value={
                leaseContractThisMonth?.ends_at
                  ? `${dayjs(leaseContractThisMonth.ends_at).format("YYYY年MM月DD日")}`
                  : "-"
              }
            />
            <AccordionDetailItemRow title={typeOfIncomeBeginningContract} value={leaseContractIncome} />
            <AccordionDetailItemRow title="新規賃貸契約手数料" value={outsourcingFeeNew} />
            <AccordionDetailItemRow title="賃貸契約更新時手数料" value={outsourcingFeeUpdating} />

            <Box mb={2} justifyContent="center">
              <CustomButton
                customVariant="outlinedGray"
                isEdit
                onClick={async () => {
                  await updatePropertyEditPageState("lease_contract");
                  history.push("/edit_property");
                }}
              >
                編集する
              </CustomButton>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* 保険情報 */}
      <Accordion
        expanded={expanded === ExpandItem.InsuranceInfo}
        onChange={handleAccordionChange(ExpandItem.InsuranceInfo)}
        classes={{ root: classes.expanded }}
        square
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryRow}>
          <Typography variant="h4" color="textPrimary">
            保険情報
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.detail}>
          <Box className={classes.background}>
            {/* 保険情報 */}
            <AccordionDetailItemRow
              title="火災保険料"
              value={`${Math.round(monthly_cashflow_results.result.fire_insurance_cost / 10000).toLocaleString()}万円`}
            />
            <AccordionDetailItemRow
              title="火災保険期間"
              value={
                startMonth === ""
                  ? `${startMonth} ~ ${dayjs(startMonth)
                      .add(monthly_cashflow_results.result.fire_insurance_holding_period)
                      .format("YYYY/MM/DD")}`
                  : "-"
              }
            />
            <AccordionDetailItemRow
              title="地震保険料"
              value={`${Math.round(
                monthly_cashflow_results.result.earthquake_insurance_cost / 10000
              ).toLocaleString()}万円`}
            />
            <AccordionDetailItemRow
              title="地震保険期間"
              value={
                startMonth === ""
                  ? `${startMonth} ~ ${dayjs(startMonth)
                      .add(monthly_cashflow_results.result.earthquake_insurance_holding_period)
                      .format("YYYY/MM/DD")}`
                  : "-"
              }
            />

            <Box mb={2} justifyContent="center">
              <CustomButton
                customVariant="outlinedGray"
                isEdit
                onClick={async () => {
                  await updatePropertyEditPageState("insurance_info");
                  history.push("/edit_property");
                }}
              >
                編集する
              </CustomButton>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
