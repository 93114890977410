import { Typography } from "@material-ui/core";
import * as React from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";

const COLORS = ["#103894", "#6192C4", "#C3CBE9", "#00745A", "#00A385", "#83D2C1", "#E1F4F0"];

interface props {
  // 収支
  totalBalance: string;
  // 家賃収入
  rentIncome: number;
  // 管理費収入
  managementFeeIncome: number;
  // 建物管理費
  buildingManagementFee: number;
  // 修繕積立金
  repairFundCost: number;
  // 賃貸管理費
  managementCost: number;
  // その他経費
  miscCost: number;
  // ローン返済
  loanTotal: number;
}
export const PrPieChart: React.FC<props> = ({
  totalBalance,
  rentIncome,
  managementFeeIncome,
  buildingManagementFee,
  repairFundCost,
  managementCost,
  miscCost,
  loanTotal,
}) => {
  const data = [
    { name: "家賃収入", value: rentIncome },
    { name: "管理費収入", value: managementFeeIncome },
    { name: "建物管理費", value: buildingManagementFee },
    { name: "修繕積立金", value: repairFundCost },
    { name: "賃貸管理費", value: managementCost },
    { name: "その他経費", value: miscCost },
    { name: "ローン返済", value: loanTotal },
  ];

  const getRadius = (balanceLength: number) => {
    const baseInnerRadius = 50;
    const baseOuterRadius = 100;
    const increment = 3; // 桁数ごとの半径増加量
    const adjustedInnerRadius = baseInnerRadius + balanceLength * increment;
    const adjustedOuterRadius = baseOuterRadius + balanceLength * increment;
    return { innerRadius: adjustedInnerRadius, outerRadius: adjustedOuterRadius };
  };
  const getHeight = (balanceLength: number): number => {
    const baseHeight = 300;
    const increment = 5; // 桁数ごとの高さ増加量
    return baseHeight + balanceLength * increment;
  };

  const { innerRadius, outerRadius } = getRadius(totalBalance.length);

  const renderCustomizedLabel = ({ cx, cy }) => {
    return (
      <g>
        <text x={cx} y={cy} dy={-8} textAnchor="middle" fill="black" fontSize="12" fontWeight="bold">
          今月の収支
        </text>
        <text x={cx} y={cy} dy={16} textAnchor="middle" fill="#103894" fontSize="18" fontWeight="bold">
          {totalBalance}円
        </text>
      </g>
    );
  };
  const renderColorfulLegendText = (value: string) => {
    return (
      <Typography variant="caption" color="textPrimary">
        {value}
      </Typography>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={getHeight(totalBalance.length)}>
      <PieChart>
        <Pie
          data={data}
          label={renderCustomizedLabel}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={90}
          endAngle={-270}
          dataKey="value"
          labelLine={false}
          legendType="circle"
          stroke="none" // 余白を取り除く
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend verticalAlign="bottom" align="center" layout="horizontal" formatter={renderColorfulLegendText} />
      </PieChart>
    </ResponsiveContainer>
  );
};
