import * as React from "react";

interface props {
  width?: string;
  height?: string;
  fill?: string;
}

const MoneyIcon: React.FC<props> = ({ width = "32", height = "20", fill = "#103894" }): React.JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.25 0.875V20H31.75V0.875H0.25ZM4.75 3.125H27.25C27.25 4.36812 28.2569 5.375 29.5 5.375V15.5C28.2569 15.5 27.25 16.5069 27.25 17.75H4.75C4.75 16.5069 3.74312 15.5 2.5 15.5V5.375C3.74312 5.375 4.75 4.36812 4.75 3.125ZM16 5.375C13.5126 5.375 11.5 7.643 11.5 10.4375C11.5 13.232 13.5126 15.5 16 15.5C18.4874 15.5 20.5 13.232 20.5 10.4375C20.5 7.643 18.4874 5.375 16 5.375ZM16 7.625C17.2397 7.625 18.25 8.88612 18.25 10.4375C18.25 11.9889 17.2397 13.25 16 13.25C14.7603 13.25 13.75 11.9889 13.75 10.4375C13.75 8.88612 14.7603 7.625 16 7.625ZM7.5625 8.75C6.631 8.75 5.875 9.506 5.875 10.4375C5.875 11.369 6.631 12.125 7.5625 12.125C8.494 12.125 9.25 11.369 9.25 10.4375C9.25 9.506 8.494 8.75 7.5625 8.75ZM24.4375 8.75C23.506 8.75 22.75 9.506 22.75 10.4375C22.75 11.369 23.506 12.125 24.4375 12.125C25.369 12.125 26.125 11.369 26.125 10.4375C26.125 9.506 25.369 8.75 24.4375 8.75Z"
      fill={fill}
    />
  </svg>
);
export default MoneyIcon;
