import { Box, FormGroup, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useRecoilState } from "recoil";

import { GetMasterTags } from "../../../api/get_master_data";
import { CustomButton } from "../../../scripts/components/renewal_v1/button";
import { CustomCheckbox } from "../../../scripts/components/renewal_v1/checkbox";
import { RegistrationTemplate } from "../../../scripts/components/renewal_v1/template/registration_template";
import { UserRegistrationsAccoutFormState } from "../../../view_models/atoms";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      borderBottom: "none",
    },
  },
  twoColumn: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      width: "calc(50% - 10px)", // Adjust the width and margin as needed
      margin: "5px",
    },
    flexDirection: "row",
  },
  errorMessage: {
    // color: theme.palette.error.main,
    color: "#DE351F",
    marginTop: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  },
}));

interface RegisterInterestPageProps {
  totalSteps: number;
  currentStep: number;
  onNext: () => void;
  onBack: () => void;
  validate: () => Promise<boolean>;
}

const RegisterInterestPage: React.FC<RegisterInterestPageProps> = ({
  totalSteps,
  currentStep,
  onNext,
  onBack,
  validate,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();
  const [masterTags, setMasterTags] = React.useState<Array<any>>([]);
  const [serverError, setSeverError] = React.useState<string>("");
  const [formData] = useRecoilState(UserRegistrationsAccoutFormState);

  const handleNext = async () => {
    const isValid = await validate();
    if (isValid) {
      onNext();
    }
  };

  React.useEffect(() => {
    let isMounted = true;
    (async () => {
      {
        const r = await GetMasterTags();
        if (isMounted) {
          if (!r.success) {
            return setSeverError(r.error);
          }
          setMasterTags(r.masterTags);
        }
      }

      return undefined;
    })();
    // formDataが存在する場合に初期値を設定
    control.setValue("interest_tag_ids", formData.interest_tag_ids);
    return () => {
      isMounted = false;
    };
  }, []);

  const handleCheckInterestTag = (checkedId) => {
    const { interest_tag_ids: selected_ids } = getValues();
    return selected_ids?.includes(checkedId)
      ? selected_ids?.filter((id) => id !== checkedId)
      : [...(selected_ids ?? []), checkedId];
  };

  return (
    <RegistrationTemplate
      title="興味のある項目を選んでください"
      canSelectMultiple
      totalSteps={totalSteps}
      currentStep={currentStep}
    >
      {serverError && (
        <Typography variant="caption" className={classes.errorMessage}>
          {serverError}
        </Typography>
      )}
      <Box mt={4}>
        <Controller
          name="interest_tag_ids"
          control={control}
          rules={{ validate: (v) => v?.length > 0 || "最低1つの項目を選択してください。" }}
          render={({ ...field }) => (
            <FormGroup className={classes.twoColumn}>
              {masterTags.map((masterTag) => (
                <CustomCheckbox
                  {...field}
                  key={masterTag.id}
                  label={masterTag.name}
                  error={!!errors.interest_tag_ids}
                  onChange={() => field.onChange(handleCheckInterestTag(masterTag.id))}
                  checked={field.value?.includes(masterTag.id)}
                />
              ))}
            </FormGroup>
          )}
        />
        {errors && (
          <Typography variant="caption" className={classes.errorMessage}>
            {errors.interest_tag_ids?.message}
          </Typography>
        )}
      </Box>
      <Box mt={3}>
        <CustomButton type="button" customVariant="filled" onClick={handleNext}>
          次へ
        </CustomButton>
        <Box display="flex" margin="32px auto" justifyContent="center">
          <Typography variant="button" className={classes.link} onClick={onBack}>
            戻る
          </Typography>
        </Box>
      </Box>
    </RegistrationTemplate>
  );
};

export default RegisterInterestPage;
