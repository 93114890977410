import { Chip, Theme, ThemeProvider, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import MailIcon from "@material-ui/icons/Mail";
import React, { useMemo } from "react";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";

import { useDevice } from "../../../../utilities/sp/use_device";
import { investmentUnreadCount, assessmentUnreadCount } from "../../../../view_models/proposal_unread_counts";
import { DropDownMenuItem } from "../dropdown_menu";
import Footer from "../footer";
import Header from "../header";
import { ManagementAppBar } from "../property/app_bar";
import theme from "../theme";

type StyleProps = {
  isDocumentView: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  background: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: theme.palette.text.primary,
  },
  message: {
    color: theme.palette.text.primary,
    marginTop: 4,
  },
  content: {
    flexGrow: 1,
    paddingTop: 0,
    paddingBottom: 32,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 96,
      paddingBottom: 60,
    },
    backgroundColor: (props) => (props.isDocumentView ? theme.palette.tertiary.main : ""),
  },
  chipWrapper: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(8, 0, 2),
  },
  chip: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0, 3),
    "&:hover, &:active": {
      backgroundColor: theme.palette.info.dark,
    },
    "&:focus": {
      backgroundColor: theme.palette.info.main,
    },
  },
  chipIcon: {
    color: theme.palette.common.white,
  },
}));

type props = {
  children: React.ReactNode;
  title: string;
  onBack?: () => void;
  onSave?: () => void;
  menuItems?: DropDownMenuItem[];
  isModalOpen?: boolean;
  isDocumentView?: boolean;
};

export const PropertyOperationTemplate: React.FC<props> = ({
  children,
  title,
  onBack,
  onSave,
  menuItems,
  isModalOpen = false,
  isDocumentView = false,
}) => {
  const { isMobile } = useDevice();
  const classes = useStyles({ isDocumentView });
  const history = useHistory();

  const investmentUnreadCountValue = useRecoilValue(investmentUnreadCount);
  const assessmentUnreadCountValue = useRecoilValue(assessmentUnreadCount);

  const infoChipVisible = useMemo(() => {
    return investmentUnreadCountValue?.unread_count > 0 || assessmentUnreadCountValue?.unread_count > 0;
  }, [investmentUnreadCountValue, assessmentUnreadCountValue]);

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.background}>
        {isMobile ? (
          <Header title={title} onBack={onBack} onSave={onSave} menuItems={menuItems} isModalOpen={isModalOpen} />
        ) : (
          <ManagementAppBar />
        )}
        <Box className={classes.content}>
          {infoChipVisible && (
            <Box className={classes.chipWrapper}>
              <Chip
                className={classes.chip}
                icon={<MailIcon className={classes.chipIcon} />}
                label="未読の新規オファーがあります"
                onClick={() => history.push("/offer")}
              />
            </Box>
          )}

          {children}
        </Box>

        {!isMobile && <Footer />}
      </Box>
    </ThemeProvider>
  );
};
