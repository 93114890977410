import { Box, makeStyles, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";
import { useHistory } from "react-router";
import { useRecoilValueLoadable, useRecoilState, useRecoilValue } from "recoil";

import { RegisteredStatusEnum } from "../../../api/generated";
import { ScreenNameEnum, UserAccessScreenLog } from "../../../api/generated/api";
import { PropallyApiFactory } from "../../../module/custom_api_factory";
import { CustomButton } from "../../../scripts/components/renewal_v1/button";
import AnalyticsIcon from "../../../scripts/components/renewal_v1/icon/analytics_icon";
import HandCoinIcon from "../../../scripts/components/renewal_v1/icon/hand_coin_icon";
import MoneyIcon from "../../../scripts/components/renewal_v1/icon/money_icon";
import MypageBuildingIcon from "../../../scripts/components/renewal_v1/icon/mypage_building_icon";
import { MypageTemplate } from "../../../scripts/components/renewal_v1/template/mypage_template";
import {
  AppEventTokenEnum,
  isFirstAccess,
  isNativeApp,
  sendEventToAppMeasurementTool,
} from "../../../utilities/capacitor_logic";
import { getYieldRate } from "../../../utilities/get_property_address_string";
import { usePreviousLocation } from "../../../utilities/renewal_v1/previousLocation";
import {
  SelectedStartingMonth,
  SelectedEndingMonth,
  useSelectOwnedProperties,
  UserDataFirstRegisteredStatusState,
  UserState,
} from "../../../view_models/atoms";
import { LoanInformationOnToday, ReducedMonthlyBalanceSheets } from "../../../view_models/balancesheet_selectors";
import { OwnedProperties } from "../../../view_models/property_selectors";
import { Loading } from "../../loading";

import { MyPageAccordion } from "./accordion";
import MyPageHeader from "./header";

const useStyles = makeStyles(() => ({
  box: {
    backgroundColor: "#E7EAF6", // 青い背景
    padding: "20px 24px",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 24,
  },
  twoColumnBox: {
    display: "flex",
    marginTop: 24,
    columnGap: 24,
  },
  item: {
    backgroundColor: "#E7EAF6", // 青い背景
    padding: "20px 24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  unit: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "130%",
    "@media (max-width:600px)": {
      fontSize: "14px", // SP用のスタイル(デフォルトのbreakpoint sm=600px)
    },
  },
}));

export const MyPage: React.FC = () => {
  const classes = useStyles();
  const loan_loadable = useRecoilValueLoadable(LoanInformationOnToday);
  const reduced_loadable = useRecoilValueLoadable(ReducedMonthlyBalanceSheets(null));

  const [selected_starting_month, setSelectedStartingYear] = useRecoilState(SelectedStartingMonth);
  const [selected_ending_month, setSelectedEndingYear] = useRecoilState(SelectedEndingMonth);
  const selectOwnedProperties = useSelectOwnedProperties();
  const properties_loadable = useRecoilValueLoadable(OwnedProperties);
  const userDataFirstRegisteredStatus = useRecoilValue(UserDataFirstRegisteredStatusState);
  const user = useRecoilValue(UserState);

  const history = useHistory();
  const previousLocation = usePreviousLocation();

  React.useEffect(() => {
    selectOwnedProperties();
    if (selected_ending_month.diff(selected_starting_month, "year") !== 45) {
      setSelectedStartingYear(dayjs().startOf("year"));
      setSelectedEndingYear(dayjs().add(45, "year").endOf("year"));
    }
    const fp = PropallyApiFactory();
    fp.v1UserAccessScreenLogsPost({ screen_name: ScreenNameEnum.Top } as UserAccessScreenLog, {
      withCredentials: true,
    });
  }, []);

  if (properties_loadable.state === "loading") {
    return <Loading />;
  }
  if (properties_loadable.state === "hasError") {
    throw properties_loadable.contents;
  }

  // ネイティブアプリの場合のみ 且つ 初回アンケート登録画面からの遷移の場合のみ最初にトラッキング許可画面を表示する
  if (isNativeApp && previousLocation === "/first_account") {
    // アプリのみプッシュ通知と計測ツール初期化処理を行うためにトラッキング許可画面に遷移
    history.push("/tracking");
  }

  // // TODO WANTFIX: we can rewrite the following state checking as for-loop or iterator.
  // // I don't have any idea to pass type check of Typescript with the iterative approach.
  if (loan_loadable.state === "hasError") {
    throw loan_loadable.contents;
  }
  if (loan_loadable.state === "loading") {
    return <Loading />;
  }
  if (reduced_loadable.state === "hasError") {
    throw reduced_loadable.contents;
  } else if (reduced_loadable.state === "loading") {
    return <Loading />;
  }

  const loan_information_on_today = loan_loadable.contents;

  const this_month_sheet = reduced_loadable.contents.get(dayjs().format("YYYY/MM"));

  // 資産合計
  const totalPriceOfProperty = properties_loadable.contents.reduce(
    (sum, property) => sum + property.property.total_price_of_property,
    0
  );
  // ローン残高
  const totalLoanBalance = Math.round(loan_information_on_today.total_loan_remaining).toLocaleString();
  // 表面利回り
  const thisMonthResult = this_month_sheet.result;
  const yieldRate = getYieldRate(
    thisMonthResult.rent_income + thisMonthResult.management_fee_income,
    totalPriceOfProperty
  );
  // 保有物件数
  const numberOfProperties = properties_loadable.contents.length;

  const handleCashFlowClick = () => {
    sendEventToAppMeasurementTool(
      isFirstAccess(user?.created_at)
        ? AppEventTokenEnum.CashflowFirstAccess
        : AppEventTokenEnum.CashflowSecondOnwardsAccess
    );
    history.push("/cashflow");
  };

  return (
    <MypageTemplate>
      {userDataFirstRegisteredStatus !== RegisteredStatusEnum.Property && <MyPageHeader />}
      <Box px={3} pb={10}>
        <MyPageAccordion thisMonthResult={this_month_sheet.result} />
        {/* <MyPageAccordionLoanBalance total={totalLoanBalance} map={loanBalanceMap} /> */}
        {/* 資産合計 */}
        <Box className={classes.box}>
          <Box display="flex" alignItems="center">
            <Box pr={1}>
              <MoneyIcon />
            </Box>
            <Typography variant="button">資産合計</Typography>
          </Box>
          <Typography variant="h1">
            {totalPriceOfProperty.toLocaleString()}
            <span className={classes.unit}>円</span>
          </Typography>
        </Box>

        {/* ローン合計 */}
        <Box className={classes.box}>
          <Box display="flex" alignItems="center">
            <Box pr={1}>
              <HandCoinIcon />
            </Box>
            <Typography variant="button">ローン合計</Typography>
          </Box>
          <Typography variant="h1">
            {totalLoanBalance.toLocaleString()}
            <span className={classes.unit}>円</span>
          </Typography>
        </Box>

        <Box className={classes.twoColumnBox}>
          {/* 表面利回り */}
          <Box className={classes.item}>
            <Box pb={1}>
              <AnalyticsIcon />
            </Box>
            <Typography variant="button">表面利回り</Typography>
            <Typography variant="h1">
              {yieldRate !== "NaN" ? yieldRate : "-"}
              <span className={classes.unit}>%</span>
            </Typography>
          </Box>

          {/* 物件保有数 */}
          <Box className={classes.item}>
            <Box pb={1}>
              <MypageBuildingIcon />
            </Box>
            <Typography variant="button">物件保有数</Typography>
            <Typography variant="h1">
              {numberOfProperties}
              <span className={classes.unit}>件</span>
            </Typography>
          </Box>
        </Box>

        <Box mt={5}>
          <CustomButton customVariant="outlinedPrimary" onClick={handleCashFlowClick}>
            年間の収支を確認する
          </CustomButton>
        </Box>
      </Box>
    </MypageTemplate>
  );
};
