import { Box, Avatar, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";

import NoImage from "../../../../../assets/image/NoImage.svg";

const useStyles = makeStyles((theme) => ({
  profileImage: {
    width: 113,
    height: 113,
  },
  companyImage: {
    height: 24,
  },
  badge: {
    backgroundColor: "#E25213",
    padding: "2px 8px",
    width: "fit-content",
    color: "#fff",
  },
  summaryWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "flex-start",
  },
}));

interface props {
  profileImagePath: string;
  companyImagePath: string;
  companyName: string;
  representativeName: string;
  isNew?: boolean;
}

export const AgentProfile: React.FC<props> = ({
  profileImagePath,
  companyImagePath,
  companyName,
  representativeName,
  isNew,
}) => {
  const classes = useStyles();
  return (
    <Box mt={1} display="flex" alignItems="center">
      <Avatar src={profileImagePath ? profileImagePath : NoImage} alt="profile" className={classes.profileImage} />
      <Box ml={2} className={classes.summaryWrapper}>
        {isNew && (
          <Box className={classes.badge}>
            <Typography variant="h5">NEW!</Typography>
          </Box>
        )}
        <img src={companyImagePath ? companyImagePath : NoImage} className={classes.companyImage} alt="company" />
        <Box>
          <Typography variant="h4">{companyName}</Typography>
          <Typography variant="body1">{representativeName}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
