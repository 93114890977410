import { Tabs, Tab, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useHistory } from "react-router";
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";

import { deleteProperty } from "../../../api/delete_property";
import { PropertyType } from "../../../api/generated";
import { PropallyApiFactory } from "../../../module/custom_api_factory";
import { DropDownMenuItem } from "../../../scripts/components/renewal_v1/dropdown_menu";
import { ChangePropertyImageModal } from "../../../scripts/components/renewal_v1/property/change_property_image_modal";
import { DeletePropertyModal } from "../../../scripts/components/renewal_v1/property/delete_property_modal";
import { UpdatePropertyTypeToOwnModal } from "../../../scripts/components/renewal_v1/property/update_property_type_modal";
import { ManagementDetailTemplate } from "../../../scripts/components/renewal_v1/template/management_detail_template";
import theme from "../../../scripts/components/renewal_v1/theme";
import { AppEventTokenEnum, isFirstAccess, sendEventToAppMeasurementTool } from "../../../utilities/capacitor_logic";
import { fileToBase64EncodedImage } from "../../../utilities/file_to_base64_encoded_image";
import {
  PropertyManagementListSnackbarState,
  SelectedPropertyId,
  SelectedPropertyIds,
  useRequestInitialLoading,
  UserState,
} from "../../../view_models/atoms";
import { MonthlyCashflowResultsOnToday } from "../../../view_models/balancesheet_selectors";
import { SelectedProperty } from "../../../view_models/property_selectors";
import { Loading } from "../../loading";

import { ManagementDetailCashflow } from "./management_detail_tabs/cashflow";
import { ManagementConsiderationDetailPropertyInfo } from "./management_detail_tabs/consideration_property_info";
import { ManagementDetailSimulation } from "./management_detail_tabs/simulation";

// TODO: 保有物件に統合できるようにしたい
const useStyles = makeStyles(() => {
  const PC_WIDTH = 800;
  const SP_WIDTH = 390;
  return {
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    tabContent: {
      padding: theme.spacing(2),
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: "3px",
    },
    tabs: {
      display: "flex",
      justifyContent: "center",
      borderBottom: `0.5px solid ${theme.palette.grey[300]}`,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(0),
      },
    },
    tab: {
      flexGrow: 1,
      textAlign: "center",
      borderBottom: "3px solid transparent",
      padding: 0,
      width: PC_WIDTH / 3,
      [theme.breakpoints.down("sm")]: {
        width: SP_WIDTH / 3,
      },
    },
    tabLabel: {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.text.primary,
    },
    tabSelected: {
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
  };
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};

export const ManagementConsiderationDetailPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  // 削除モーダルの表示状態
  const [openDeletePropertyModal, setOpenDeletePropertyModal] = React.useState(false);
  // 物件ステータス変更
  const [openPropertyTypeChangeModal, setOpenPropertyTypeChangeModal] = React.useState(false);
  const [openChangeImagePropertyModal, setOpenChangeImagePropertyModal] = React.useState(false);
  const setSelectedPropertyId = useSetRecoilState(SelectedPropertyId);
  const setSelectedPropertyIds = useSetRecoilState(SelectedPropertyIds);
  const requestInitialLoading = useRequestInitialLoading();
  // 物件一覧でのスナックバー表示
  const setManagementListSnackBarState = useSetRecoilState(PropertyManagementListSnackbarState);

  const properties_loadable = useRecoilValueLoadable(SelectedProperty);
  const cashflow_result_loadable = useRecoilValueLoadable(MonthlyCashflowResultsOnToday);

  const user = useRecoilValue(UserState);

  // 右上の設定ボタン用の処理
  const handleClickImageChange = React.useCallback(() => {
    setOpenChangeImagePropertyModal(true);
  }, [setOpenChangeImagePropertyModal]);

  // const handlePropertyTypeUpdate = React.useCallback(() => {
  //   setOpenPropertyTypeChangeModal(true);
  // }, [setOpenPropertyTypeChangeModal]);

  const handleClickDeleteProperty = React.useCallback(() => {
    setOpenDeletePropertyModal(true);
  }, [setOpenDeletePropertyModal]);

  if (properties_loadable.state === "loading") {
    return <Loading />;
  }
  if (properties_loadable.state === "hasError") {
    throw properties_loadable.contents;
  }
  const property = properties_loadable.contents;
  if (cashflow_result_loadable.state === "loading") {
    return <Loading />;
  }
  if (cashflow_result_loadable.state === "hasError") {
    throw cashflow_result_loadable.contents;
  }
  const cashflow_result = cashflow_result_loadable.contents;

  // Check if property is undefined
  if (!property) {
    return <Loading />;
  }

  // 物件削除モーダルで削除ボタンを押したときの処理
  const handleDeleteThisProperty = async () => {
    const result = await deleteProperty(property);
    if (result.success) {
      setSelectedPropertyIds([]);
      setSelectedPropertyId(null);

      requestInitialLoading();
      // 物件一覧ページでのスナックバー表示用
      setManagementListSnackBarState({ isOpen: true, message: "物件を削除しました", status: "success" });
      history.push("/management");
    } else {
      // 物件一覧ページでのスナックバー表示用
      setManagementListSnackBarState({ isOpen: true, message: "物件を削除できませんでした", status: "error" });
      history.push("/management");
    }
  };

  // 検討中物件を保有物件に移動させる
  const handlePropertyTypeChange = async () => {
    const fp = PropallyApiFactory();
    try {
      const data = await fp.v1PropertiesPropertyIdPatch(
        property.id,
        { property_type: PropertyType.NUMBER_0 },
        {
          withCredentials: true,
        }
      );
      if (data.status === 200) {
        setManagementListSnackBarState({
          message: "保有物件に移動しました",
          status: "success",
          isOpen: true,
        });
        requestInitialLoading();
        history.push("/management");
      }
    } catch (e) {
      console.error(e);
      setManagementListSnackBarState({
        message: "保有物件に移動できませんでした",
        status: "error",
        isOpen: true,
      });
    }
  };

  // 物件の画像の登録
  const handleUpdateImageThisProperty = async (imageFile: File) => {
    const base64_encoded_image = await fileToBase64EncodedImage(imageFile);
    const fp = PropallyApiFactory();
    if (!base64_encoded_image) {
      setOpenChangeImagePropertyModal(false);
      return;
    }
    const result = await fp.v1PropertiesPropertyIdImagesPost(property.id, base64_encoded_image, {
      withCredentials: true,
    });
    if (result.status === 201) {
      setOpenChangeImagePropertyModal(false);
      // 画像の登録に成功したら、物件情報を再取得する
      requestInitialLoading();
    } else {
      setOpenChangeImagePropertyModal(false);
    }
  };

  const handleChange = (event, newValue) => {
    console.log("newValue: ", newValue);
    if (newValue === 0) {
      sendEventToAppMeasurementTool(
        isFirstAccess(user?.created_at)
          ? AppEventTokenEnum.PropertyTabBalanceOfPaymentsFirstAccess
          : AppEventTokenEnum.PropertyTabBalanceOfPaymentsSecondOnwardsAccess
      );
    } else if (newValue === 1) {
      sendEventToAppMeasurementTool(
        isFirstAccess(user?.created_at)
          ? AppEventTokenEnum.PropertyTabSimulationFirstAccess
          : AppEventTokenEnum.PropertyTabSimulationSecondOnwardsAccess
      );
    } else if (newValue === 2) {
      sendEventToAppMeasurementTool(
        isFirstAccess(user?.created_at)
          ? AppEventTokenEnum.PropertyTabInformationFirstAccess
          : AppEventTokenEnum.PropertyTabInformationSecondOnwardsAccess
      );
    }
    setValue(newValue);
  };

  // ドロップダウンメニューの項目
  const menuItems: DropDownMenuItem[] = [
    { label: "画像を変更する", onClick: handleClickImageChange, color: "textPrimary" },
    // 2024/08/02: 一時的に非表示とする
    // { label: "保有物件に移動する", onClick: handlePropertyTypeUpdate, color: "textPrimary" },
    { label: "物件を削除する", onClick: handleClickDeleteProperty, color: "error" },
  ];

  return (
    <ManagementDetailTemplate
      propertyType="検討中"
      propertyHolder={property}
      monthlyBalanceSheet={cashflow_result}
      dropDownMenuItems={menuItems}
    >
      <div className={classes.root}>
        <Box className={classes.tabs}>
          <Tabs
            value={value}
            onChange={handleChange}
            classes={{ indicator: classes.indicator }}
            aria-label="simple tabs example"
            textColor="primary"
          >
            <Tab
              label="運用状況"
              classes={{
                textColorPrimary: classes.tabLabel,
                selected: classes.tabSelected,
              }}
              className={classes.tab}
            />
            <Tab
              label="シミュレーション"
              classes={{ textColorPrimary: classes.tabLabel, selected: classes.tabSelected }}
              className={classes.tab}
            />
            <Tab
              label="物件情報"
              classes={{ textColorPrimary: classes.tabLabel, selected: classes.tabSelected }}
              className={classes.tab}
            />
          </Tabs>
        </Box>
        {/* 運用状況タブ */}
        <TabPanel value={value} index={0}>
          <ManagementDetailCashflow />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ManagementDetailSimulation />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ManagementConsiderationDetailPropertyInfo />
        </TabPanel>
        {/* 削除確認モーダル */}
        {openDeletePropertyModal && (
          <DeletePropertyModal
            onClose={() => setOpenDeletePropertyModal(false)}
            onClickCancel={() => setOpenDeletePropertyModal(false)}
            onClickDelete={handleDeleteThisProperty}
          />
        )}
        {/* 検討中物件 -> 保有物件へのステータス変更モーダル */}
        {openPropertyTypeChangeModal && (
          <UpdatePropertyTypeToOwnModal
            onClose={() => setOpenPropertyTypeChangeModal(false)}
            onClickCancel={() => setOpenPropertyTypeChangeModal(false)}
            onClickTypeChange={handlePropertyTypeChange}
          />
        )}
        {openChangeImagePropertyModal && (
          <ChangePropertyImageModal
            onClose={() => setOpenChangeImagePropertyModal(false)}
            onClickCancel={() => setOpenChangeImagePropertyModal(false)}
            onClickChangeImage={handleUpdateImageThisProperty}
          />
        )}
      </div>
    </ManagementDetailTemplate>
  );
};
