import * as React from "react";

interface props {
  width?: string;
  height?: string;
  fill?: string;
}

const MypageBuildingIcon: React.FC<props> = ({ width = "37", height = "36", fill = "#103894" }): React.JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.9997 3H13.9997C12.3452 3 10.9997 4.3455 10.9997 6V14.379L3.93918 21.4395C3.72947 21.6493 3.58666 21.9165 3.52881 22.2075C3.47095 22.4984 3.50066 22.7999 3.61416 23.074C3.72767 23.348 3.91988 23.5823 4.16649 23.7471C4.41311 23.9119 4.70305 23.9999 4.99968 24V31.5C4.99968 31.8978 5.15772 32.2794 5.43902 32.5607C5.72032 32.842 6.10186 33 6.49968 33H30.4997C30.8975 33 31.279 32.842 31.5603 32.5607C31.8416 32.2794 31.9997 31.8978 31.9997 31.5V6C31.9997 4.3455 30.6542 3 28.9997 3ZM16.9997 30H7.99968V21.621L12.4997 17.121L16.9997 21.621V30ZM28.9997 30H19.9997V24C20.2966 24.0007 20.5871 23.9131 20.8342 23.7484C21.0812 23.5837 21.2738 23.3493 21.3874 23.0749C21.5011 22.8006 21.5306 22.4986 21.4724 22.2075C21.4141 21.9163 21.2706 21.649 21.0602 21.4395L13.9997 14.379V6H28.9997V30Z"
      fill={fill}
    />
    <path
      d="M17 9H20V12H17V9ZM23 9H26V12H23V9ZM23 15.0465H26V18H23V15.0465ZM23 21H26V24H23V21ZM11 22.5H14V25.5H11V22.5Z"
      fill={fill}
    />
  </svg>
);
export default MypageBuildingIcon;
