import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import * as React from "react";
import { useRecoilValue } from "recoil";

import { useDevice } from "../../../../utilities/sp/use_device";
import { investmentUnreadCount, assessmentUnreadCount } from "../../../../view_models/proposal_unread_counts";
import { NavigationPath } from "../enums";
import Footer from "../footer";
import NavigationBar from "../navigation";
import theme from "../theme";

import { MainTemplate } from "./main_template";

const useStyles = makeStyles(() => ({
  background: {
    backgroundColor: theme.palette.grey[50],
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    paddingTop: 0,
    paddingBottom: 32,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 44,
      paddingBottom: 60,
    },
  },
}));

type Props = {
  children: React.ReactNode;
};

export const MypageTemplate: React.FC<Props> = ({ children }) => {
  const { isMobile } = useDevice();
  const classes = useStyles();

  return (
    <MainTemplate>
      <Box className={classes.background}>
        <Box className={classes.content}>{children}</Box>
        {isMobile ? <NavigationBar path={NavigationPath.HOME} /> : <Footer />}
      </Box>
    </MainTemplate>
  );
};
